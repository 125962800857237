import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { UploadActions } from './upload.actions';
import { Upload } from './upload.model';

export const uploadsFeatureKey = 'uploads';

export interface UploadState extends EntityState<Upload> {
    loading: boolean;
}

export const adapter: EntityAdapter<Upload> = createEntityAdapter<Upload>();

export const initialState: UploadState = adapter.getInitialState({
    loading: false,
});

export const uploadReducer = createReducer(
    initialState,
    on(UploadActions.addUpload, (state, action) => adapter.addOne(action.upload, state)),
    on(UploadActions.upsertUpload, (state, action) => adapter.upsertOne(action.upload, state)),
    on(UploadActions.addUploads, (state, action) => adapter.addMany(action.uploads, state)),
    on(UploadActions.loadAllUploads, state => ({ ...state, loading: true })),
    on(
        UploadActions.upsertUploads,
        UploadActions.loadUploadsByCollectionIdSuccess,
        UploadActions.loadAllUploadsSuccess,
        (state, action) => adapter.upsertMany(action.uploads, { ...state, loading: false }),
    ),
    on(UploadActions.updateUpload, (state, action) => adapter.updateOne(action.upload, state)),
    on(UploadActions.updateUploads, (state, action) => adapter.updateMany(action.uploads, state)),
    on(UploadActions.deleteUpload, (state, action) => adapter.removeOne(action.id, state)),
    on(UploadActions.deleteUploads, (state, action) => adapter.removeMany(action.ids, state)),
    on(UploadActions.clearUploads, state => adapter.removeAll(state)),
);

export const uploadsFeature = createFeature({
    name: uploadsFeatureKey,
    reducer: uploadReducer,
    extraSelectors: ({ selectUploadsState }) => ({
        ...adapter.getSelectors(selectUploadsState),
    }),
});
