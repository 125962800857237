import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.UserState>(
    fromUser.userFeatureKey,
);

export const selectUserId = createSelector(selectUserState, state => state.id);
export const selectUserError = createSelector(selectUserState, state => state.error)
export const selectUserLoading = createSelector(selectUserState, state => state.loading)
