var T = {
  asc: (r, n) => r > n,
  desc: (r, n) => r < n
};
function m(r, n) {
  let [e, ...o] = n;
  if (!s(e)) {
    let t = u(...o);
    return r(e, t);
  }
  let a = u(e, ...o);
  return t => r(t, a);
}
function R(r, [n, e, ...o]) {
  let a, t;
  return s(e) ? (a = n, t = [e, ...o]) : (a = e, t = [n, ...o]), m((...i) => r(...i, a), t);
}
function u(r, n, ...e) {
  let o = typeof r == "function" ? r : r[0],
    a = typeof r == "function" ? "asc" : r[1],
    {
      [a]: t
    } = T,
    i = n === void 0 ? void 0 : u(n, ...e);
  return (y, c) => {
    let l = o(y),
      p = o(c);
    return t(l, p) ? 1 : t(p, l) ? -1 : i?.(y, c) ?? 0;
  };
}
function s(r) {
  if (d(r)) return !0;
  if (typeof r != "object" || !Array.isArray(r)) return !1;
  let [n, e, ...o] = r;
  return d(n) && typeof e == "string" && e in T && o.length === 0;
}
var d = r => typeof r == "function" && r.length === 1;
export { m as a, R as b };