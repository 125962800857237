import { createActionGroup, emptyProps, props } from '@ngrx/store';


export const AuthActions = createActionGroup({
    source: 'Auth/API',
    events: {
        'Login': props<{ email: string, password: string }>(),
        'Login Success': props<{accessToken: string, refreshToken: string, email: string}>(),
        'Login Failure': props<{err: any}>(),

        'Signup': props<{ firstname: string, lastname: string, email: string }>(),
        'Signup Success': emptyProps(),
        'Signup Failure': props<{err: any}>(),

        'Forgot Password': props<{email: string}>(),
        'Forgot Password Success': emptyProps(),
        'Forgot Password Failure': props<{error: any}>(),

        'Accept Invitation': props<{token: string, password: string}>(),
        'Accept Invitation Success': emptyProps(),
        'Accept Invitation Failure': props<{error: any}>(),

        'Logout': emptyProps(),
        'Logout Success': emptyProps(),

        'Get New Access Token': emptyProps(),
    },
});
