import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProjectActions } from '../../+store/project.actions';
import { ModalBodyComponent } from '../../../shared/modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../shared/modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../shared/modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';

@Component({
    selector: 'app-rename-project',
    template: `
        <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
            <app-modal>
                <app-modal-title>
                    <h3>Update Project Name: {{ currentName }}</h3>
                </app-modal-title>
                <app-modal-body>
                    <label for="project-name" class="form-label">Project Name</label>
                    <input class="form-input px-2" type="text" id="project-name" formControlName="name" />
                </app-modal-body>
                <app-modal-footer>
                    <button type="submit" class="btn-light" [disabled]="!projectForm.valid">Submit</button>
                </app-modal-footer>
            </app-modal>
        </form>
    `,
    styleUrls: ['./rename-project-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ReactiveFormsModule, ModalComponent, ModalTitleComponent, ModalBodyComponent, ModalFooterComponent],
})
export class RenameProjectModalComponent {
    private modalContainer = inject(ModalContentContainerComponent);
    private store = inject(Store);

    @Input() id!: string;
    @Input() currentName!: string;

    projectForm = new FormGroup({
        name: new FormControl('', { nonNullable: true }),
    });

    close() {
        this.modalContainer.onDismiss();
    }

    onSubmit() {
        this.store.dispatch(
            ProjectActions.updateProject({ project: { id: this.id, name: this.projectForm.getRawValue().name } }),
        );
        this.modalContainer.onDismiss();
    }
}
