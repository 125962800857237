import { DatePipe, NgOptimizedImage, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, WritableSignal } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgMathPipesModule } from 'ngx-pipes';
import { KnowledgeSource } from '../../+store/knowledge-source.model';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { PopoverDirective } from '../../../shared/popover/directives/popover.directive';
import { TableComponent } from '../../../shared/table/components/table/table.component';
import { CellDefDirective } from '../../../shared/table/directives/cell-def/cell-def.directive';
import { ColumnDefDirective } from '../../../shared/table/directives/column-def/column-def.directive';
import { EmptyFallbackDirective } from '../../../shared/table/directives/table-empty-fallback/empty-fallback.directive';
import { localDateTransformer } from '../../../shared/table/transformers/local-date.transformer';
import { ColumnDef } from '../../../shared/table/types/column-definition.type';

@Component({
    selector: 'app-knowledge-source-table',
    imports: [
        NgMathPipesModule,
        FaIconComponent,
        ButtonComponent,
        NgOptimizedImage,
        PopoverDirective,
        TableComponent,
        ColumnDefDirective,
        CellDefDirective,
        EmptyFallbackDirective,
    ],
    template: `
        <app-table [columnDefs]="columnDefs" [data]="data()">
            <ng-container appColumnDef="actionBar">
                <td class="text-right" *appCellDef="let row">
                    <app-button color="transparent" size="sm" [square]="true" [appPopover]="tpl" [offsetY]="0">
                        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
                    </app-button>
                    <ng-template #tpl>
                        <div class="bg-white py-2 flex flex-col items-start">
                            <button
                                (click)="download.emit(row.reference)"
                                class="hover:bg-stone-50 w-full py-2 pl-6 pr-20 text-sm text-stone-700 whitespace-nowrap flex">
                                <fa-icon [fixedWidth]="true" [icon]="['fas', 'file-download']"></fa-icon>
                                <span class="ml-3">Download</span>
                            </button>

                            <button
                                (click)="delete.emit(row.id)"
                                class="hover:bg-stone-50 w-full py-2 pl-6 pr-20 text-sm text-red-500 whitespace-nowrap flex">
                                <fa-icon [fixedWidth]="true" [icon]="['far', 'trash-can']"></fa-icon>
                                <span class="ml-3">Delete</span>
                            </button>
                        </div>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container *emptyFallback>
                <div class="mt-16 flex flex-col items-center">
                    <img ngSrc="/images/empty-nest.png" width="200" height="200" alt="empty nest" />
                    <div class="text-stone-700 font-bold text-lg">No sources yet</div>
                    <div class="text-stone-400 text-center mt-1">
                        Add some knowledge by clicking the button <br />or drag & drop some files here
                    </div>
                    <app-button (click)="addSource.emit()" class="mt-3" color="primary" [outline]="true"
                        >Add source</app-button
                    >
                </div>
            </ng-container>
        </app-table>
    `,
    styleUrl: './knowledge-source-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeSourceTableComponent {
    // columnDefs = viewChildren(CdkColumnDef);
    // columnDefNames = computed(() => this.columnDefs().map(def => def.name));

    columnDefs: ColumnDef<KnowledgeSource>[] = [
        {
            field: 'name',
            headerAlign: 'left',
        },
        {
            field: 'createdAt',
            cellAlign: 'right',
            headerAlign: 'right',
            valueTransformer: localDateTransformer('updatedAt'),
        },
        {
            field: 'updatedAt',
            cellAlign: 'right',
            headerAlign: 'right',
            valueTransformer: localDateTransformer('updatedAt'),
        },
        {
            name: 'actionBar',
            headerClasses: 'w-0',
            headerLabel: '',
        },
    ];

    addSource = output();
    delete = output<string>();
    download = output<string>();
    isLoading = input.required<boolean>();
    knowledgeSources = input<KnowledgeSource[]>([]);
    uploadingFiles = input<WritableSignal<{ file: File; progress: number }>[]>([]);
    data = computed(() => [...this.knowledgeSources()]);

    isUploadingRow(index: number, row: any) {
        return typeof row === 'function' && row().progress !== undefined;
    }
}
