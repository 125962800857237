import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentService } from '../document.service';
import { DocumentActions } from './document.actions';


@Injectable()
export class DocumentEffects {
    private actions$ = inject(Actions);
    private documentService = inject(DocumentService);

    loadDocumentsById$ = createEffect(() => this.actions$.pipe(
        ofType(DocumentActions.loadDocumentsByChatId),
        switchMap(action => this.documentService.loadDocumentsByChat(action.chatId).pipe(
            map(documents => DocumentActions.loadDocumentsByChatIdSuccess({documents})),
        )),
    ));
}
