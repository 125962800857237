import { ChatModel } from '../chat-model.enum';

export interface ChatMessage<T = any> {
    arguments: T;
    createdAt: Date;
    embeddingSearchObjects: EmbeddingSearchObject[];
    id: string;
    text: string;
    tokenUsage: {
        completionTokens: number;
        promptTokens: number;
        totalTokens: number;
    };
    user: {
        id: string;
        firstName: string;
        lastName: string;
    };
    type: 'human' | 'ai';
    knowledgeSources: any[];
}

export enum ChatStatus {
    OK = 'OK',
    PROCESSING_FILES = 'PROCESSING_FILES',
    CREATE_EMBEDDINGS = 'CREATE_EMBEDDINGS',
    GENERATE_QUERIES = 'GENERATE_QUERIES',
    RETRIEVING_KNOWLEDGE_CONTEXT = 'RETRIEVING_KNOWLEDGE_CONTEXT',
    GENERATE_ANSWER = 'GENERATE_ANSWER',
    COMPRESS_KNOWLEDGE = 'COMPRESS_KNOWLEDGE',
}

export interface Chat {
    id: string;
    status: keyof typeof ChatStatus;
    private: boolean;
    projectId: string;
    name: string;
    messages: ChatMessage[];
    createdAt: string;
    updatedAt: string;
    model: ChatModel;
    settings: {
        temperature: number;
    };
    tokenUsage: {
        completionTokens: number;
        promptTokens: number;
        totalTokens: number;
    };
    documents: string[];
    modelName: string;
    temperature: number;
    knowledgeSources: any[];
}

export interface EmbeddingSearchObject {
    score: number;
    embeddingId: string;
    uploadId: string;
}
