import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { Document } from './document.model';
import { DocumentActions } from './document.actions';

export const documentsFeatureKey = 'documents';

export interface DocumentState extends EntityState<Document> {
    // additional entities state properties
}

export const adapter: EntityAdapter<Document> = createEntityAdapter<Document>();

export const initialState: DocumentState = adapter.getInitialState({
    // additional entity state properties
});

export const documentReducer = createReducer(
    initialState,
    on(DocumentActions.loadDocumentsByChatIdSuccess,
        (state, {documents}) => adapter.addMany(documents, state),
    ),
);

export const documentsFeature = createFeature({
    name: documentsFeatureKey,
    reducer: documentReducer,
    extraSelectors: ({selectDocumentsState}) => ({
        ...adapter.getSelectors(selectDocumentsState),
    }),
});
