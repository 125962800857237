import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TippyDirective } from '@ngneat/helipopper';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chat } from '../../../chat/+store/chat.model';
import { filterNullish } from 'src/app/shared/utilities/filter-nullish.operator';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { PopoverDirective } from 'src/app/shared/popover/directives/popover.directive';
import { ChatActions } from '../../+store/chat.actions';
import { Store } from '@ngrx/store';
import { ConfirmedClickDirective } from 'src/app/shared/directives/confirmed-click/confirmed-click.directive';
import { Actions } from '@ngrx/effects';

@Component({
    selector: 'app-chat-list-item',
    template: `
        <a
            class="text-sm px-3 py-2 rounded-lg truncate flex items-center hover:bg-stone-200/75 group"
            routerLinkActive="bg-stone-200/75"
            #rla="routerLinkActive"
            [routerLink]="['/chat', chat.id]">
            @if (chat.private) {
                <fa-icon
                    class="mr-1 text-stone-500 hover:text-stone-600"
                    tp="Only visible for you"
                    tpPlacement="bottom-start"
                    [class.text-stone-600]="rla.isActive"
                    [icon]="['fal', 'eye-slash']"></fa-icon>
            }
            {{ chat.name }}
            <app-button
                class="ml-auto opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                color="transparent"
                size="sm"
                [square]="true"
                [appPopover]="tpl"
                [offsetY]="0"
                (click)="$event.preventDefault(); $event.stopPropagation()">
                <fa-icon [icon]="['far', 'ellipsis-h']" size="lg" [fixedWidth]="true"></fa-icon>
            </app-button>
            <ng-template #tpl>
                <div class="bg-white py-2 flex flex-col items-start">
                    <button
                        (confirmedClick)="deleteChat(chat.id)"
                        dialogType="ConfirmedDelete"
                        class="hover:bg-stone-50 w-full py-2 pl-6 pr-20 text-sm text-red-500 whitespace-nowrap flex">
                        <fa-icon [fixedWidth]="true" [icon]="['far', 'trash-can']"></fa-icon>
                        <span class="ml-3">Delete</span>
                    </button>
                </div>
            </ng-template>
        </a>
    `,
    styleUrls: ['./chat-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        RouterLink,
        FaIconComponent,
        TippyDirective,
        RouterLinkActive,
        PopoverDirective,
        ButtonComponent,
        ButtonComponent,
        ConfirmedClickDirective,
    ],
})
export class ChatListItemComponent implements OnInit {
    private route = inject(ActivatedRoute);
    private store = inject(Store);
    private actions = inject(Actions);
    private router = inject(Router);

    @Input('chat') chat!: Chat;

    isActive$!: Observable<boolean>;

    ngOnInit() {
        this.isActive$ = this.route.paramMap.pipe(
            map(params => params.get('chatId')),
            filterNullish(),
            map(chatId => chatId === this.chat.id),
            startWith(false),
        );
    }

    deleteChat(id: string) {
        this.store.dispatch(ChatActions.deleteChat({ id }));

        this.router.navigate(['/chat']);
    }
}
