import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectInvoicesOfSubscription } from '../../+store/payment.selectors';
import { AppState } from '../../../reducers';
import { ModalBodyComponent } from '../../../shared/modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../shared/modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../shared/modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { StripeInvoiceItemComponent } from '../../components/stripe-invoice-item/stripe-invoice-item.component';
import { StripeInvoice } from '../../interfaces/stripe-invoice.interface';
import { StripeService } from '../../stripe.service';

@Component({
    selector: 'app-list-invoices-modal',
    template: `
        <app-modal>
            <app-modal-title>
                <h3>Invoices of {{ productName }}</h3>
            </app-modal-title>
            <app-modal-body class="px-3">
                @for (invoice of invoices$ | async; track invoice) {
                    <app-stripe-invoice-item [invoice]="invoice"></app-stripe-invoice-item>
                }
            </app-modal-body>
            <app-modal-footer>
                <div class="flex justify-between">
                    <button (click)="modalContainer.onDismiss()" type="button" class="btn-light">Close</button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrls: ['./list-invoices-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalComponent,
        ModalTitleComponent,
        ModalBodyComponent,
        StripeInvoiceItemComponent,
        ModalFooterComponent,
        AsyncPipe,
    ],
})
export class ListInvoicesModalComponent implements OnInit {
    modalContainer = inject(ModalContentContainerComponent);
    private store = inject<Store<AppState>>(Store);
    private stripeService = inject(StripeService);

    @Input() productName!: string;
    @Input() subscriptionId!: string;
    invoices$!: Observable<StripeInvoice[]>;

    ngOnInit() {
        this.invoices$ = this.store.select(selectInvoicesOfSubscription(this.subscriptionId)).pipe(filterNullish());
    }
}
