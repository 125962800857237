import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from './user.model';

export const UserActions = createActionGroup({
    source: 'User',
    events: {
        'Load User': emptyProps(),
        'Load User Success': props<{ user: User }>(),
        'Load User Error': props<{ err: any }>(),

        'Update Password': props<{oldPassword: string, newPassword: string, newPasswordRepeat: string}>(),
        'Update Password Success': emptyProps(),
        'Update Password Failure': props<{error: any}>(),

        'Delete User': props<{ id: string}>(),
        'Delete User Success': emptyProps(),
    }
});
