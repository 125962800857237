import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { environment } from '../../../../environments/environment';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { PopoverDirective } from '../../../shared/popover/directives/popover.directive';
import { ChatModel } from '../../chat-model.enum';

@Component({
    selector: 'app-chat-model-switcher',
    template: `
        <app-button color="beige" [appPopover]="tpl">
            <span class="whitespace-nowrap">
                {{ getModel(value()) }}
                <fa-icon class="ml-2" [icon]="['fal', 'chevron-down']"></fa-icon>
            </span>
        </app-button>

        <ng-template #tpl>
            <div class="bg-white shadow rounded-lg px-2 py-2 space-y-1 max-w-[500px]">
                @for (model of availableModels; track model.model) {
                    <div
                        class="flex items-center hover:bg-slate-50 px-3 py-2 rounded cursor-pointer group"
                        (click)="change(model.model)">
                        <div>
                            <div class="flex items-center font-semibold">
                                {{ model.label }}
                                <div class="ml-3 space-x-3">
                                    <div class="rounded-full bg-neutral-100 text-xs py-1 px-2">
                                        {{ model.manufacturer }}
                                    </div>
                                </div>
                            </div>
                            <div class="text-neutral-500 text-sm">
                                {{ model.description }}
                            </div>
                        </div>
                        <div
                            class="ml-5 text-neutral-200 group-hover:opacity-100 opacity-0"
                            [ngClass]="{ '!text-green-500 !opacity-100': value() === model.model }">
                            <fa-icon [icon]="['fas', 'circle-check']"></fa-icon>
                        </div>
                    </div>
                }
            </div>
        </ng-template>
    `,
    styleUrls: ['./chat-model-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChatModelSwitcherComponent),
            multi: true,
        },
    ],
    imports: [ButtonComponent, PopoverDirective, FaIconComponent, NgClass],
})
export class ChatModelSwitcherComponent implements ControlValueAccessor {
    private cdr = inject(ChangeDetectorRef);

    changeFn!: (p: string) => void;
    ChatModel = ChatModel;
    value = signal<ChatModel>(ChatModel['GPT-4o']);

    availableModels = environment.availableModels;

    change(model: ChatModel) {
        this.value.set(model);
        this.update();
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {}

    update() {
        if (this.changeFn) {
            this.changeFn(this.value());
        }
    }

    writeValue(value: ChatModel): void {
        if (value) {
            this.value.set(value);
        } else {
            this.value.set(ChatModel['GPT-4o']);
        }

        this.cdr.markForCheck();
    }

    getModel(model: ChatModel) {
        return Object.keys(ChatModel).find(key => ChatModel[key as keyof typeof ChatModel] === model);
    }
}
