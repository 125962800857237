import { TextFieldModule } from '@angular/cdk/text-field';

import { ChangeDetectionStrategy, Component, inject, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { TemplateActions } from '../../../template/store/template.actions';
import { PerfectScrollbarDirective } from '../../directives/perfect-scrollbar/perfect-scrollbar.directive';
import { ModalBodyComponent } from '../../modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../modal/modal-title/modal-title.component';
import { ModalComponent } from '../../modal/modal/modal.component';

@Component({
    selector: 'app-template-create-modal',
    template: `
        <app-modal>
            <app-modal-title>
                @if (!id) {
                    <h3>Save a template prompt</h3>
                }
                @if (id) {
                    <h3>Update your template prompt</h3>
                }
            </app-modal-title>
            <app-modal-body class="max-h-80">
                @if (!id) {
                    <p class="text-white">
                        You have the possibility to save your prompt as a template. <br />
                        This way you can easily reuse this template later. You are also able to update the prompt before
                        reusing it. <br />
                        Currently, the templates will be available in each of your projects.
                    </p>
                }
                <div [formGroup]="form" class="mt-4">
                    <div>
                        <label for="name" class="form-label text-blue-800"> Name </label>
                        <input class="form-input px-2" type="text" id="name" formControlName="name" />
                        @if (form.get('name')?.errors?.['required'] && form.get('name')?.touched) {
                            <div class="text-red-500 text-xs italic mt-3">Please enter a name for the prompt.</div>
                        }
                    </div>
                    <div class="my-3">
                        <label for="prompt" class="form-label text-blue-800"> Prompt </label>
                        <div scrollbar>
                            <textarea
                                class="w-full overflow-hidden form-input px-2"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="3"
                                cdkAutosizeMaxRows="15"
                                (keyup)="scrollbar.update()"
                                type="text"
                                id="prompt"
                                formControlName="prompt">
                            </textarea>
                        </div>

                        @if (form.get('prompt')?.errors?.['required'] && form.get('prompt')?.touched) {
                            <div class="text-red-500 text-xs italic mt-3">Please enter a prompt.</div>
                        }
                    </div>
                </div>
            </app-modal-body>
            <app-modal-footer>
                <div class="flex justify-between">
                    <button (click)="modalContainer.onDismiss()" type="button" class="btn-light">Close</button>

                    <button (click)="save()" type="button" class="btn-success">Save</button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrls: ['./template-create-update-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ModalComponent,
        ModalTitleComponent,
        ModalBodyComponent,
        ReactiveFormsModule,
        PerfectScrollbarDirective,
        TextFieldModule,
        ModalFooterComponent,
    ],
})
export class TemplateCreateUpdateModalComponent {
    modalContainer = inject(ModalContentContainerComponent);
    private store = inject<Store<AppState>>(Store);

    @ViewChild(PerfectScrollbarDirective, { static: true }) scrollbar!: PerfectScrollbarDirective;
    @Input() prompt!: string;
    @Input() name!: string;
    @Input() id!: string;
    form!: FormGroup;

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl('', Validators.required),
            prompt: new FormControl('', Validators.required),
        });

        this.form.patchValue({
            prompt: this.prompt,
            name: this.name,
        });
    }

    async save() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        if (!this.id) {
            this.store.dispatch(
                TemplateActions.addTemplate({
                    name: this.form.value.name,
                    prompt: this.form.value.prompt,
                }),
            );
        } else {
            this.store.dispatch(
                TemplateActions.updateTemplate({
                    name: this.form.value.name,
                    prompt: this.form.value.prompt,
                    id: this.id,
                }),
            );
        }
        this.modalContainer.onDismiss();
    }
}
