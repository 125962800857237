import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, ToRelativeUnit } from 'luxon';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterNullish } from '../utilities/filter-nullish.operator';

@Pipe({
    name: 'dateFromNow',
    pure: true,
    standalone: true,
})
export class DateFromNowPipe implements PipeTransform {
    transform(value: string | Date | undefined, unit: ToRelativeUnit = 'days', threshold = 0): Observable<string> {
        if (value) {
            const dateValue = DateTime.fromISO(value.toString());
            const obs = timer(0, 1000).pipe(
                map(() => {
                    if (threshold && DateTime.now().diff(dateValue, unit).days > threshold) {
                        return dateValue.toLocaleString()!;
                    }
                    return dateValue.toRelativeCalendar({ locale: 'en', unit })!;
                }),
                filterNullish(),
            );
            return obs;
        }
        return of('test');
    }
}
