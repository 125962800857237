import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { EmbeddingActions } from './embedding.actions';
import { Embedding } from './embedding.model';

export const embeddingFeatureKey = 'embeddings';

export interface EmbeddingState extends EntityState<Embedding> {
    // additional entities state properties
}

export const adapter: EntityAdapter<Embedding> = createEntityAdapter<Embedding>();

export const initialState: EmbeddingState = adapter.getInitialState({
    // additional entity state properties
});

export const embeddingReducer = createReducer(
    initialState,
    on(EmbeddingActions.loadEmbeddingsSuccess,
        (state, {embeddings}) => adapter.upsertMany(embeddings, state),
    ),
);

export const embeddingsFeature = createFeature({
    name: embeddingFeatureKey,
    reducer: embeddingReducer,
    extraSelectors: ({selectEmbeddingsState}) => ({
        ...adapter.getSelectors(selectEmbeddingsState),
    }),
});
