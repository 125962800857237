import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalService } from '../../../shared/modal/modal.service';
import {
    selectUploadCollectionAll,
    selectUploadCollectionLoading,
} from '../../../upload/+store/folder/upload-collection.selectors';
import { CreateCollectionModalComponent } from '../../modals/create-collection-modal/create-collection-modal.component';

@Component({
    selector: 'app-upload-collection-list',
    template: `
        @for (uploadCollection of uploadCollections(); track uploadCollection) {
            <a
                class="collection-item"
                [routerLink]="['/knowledge-base', uploadCollection.id]"
                routerLinkActive="active">
                {{ uploadCollection.name }}
            </a>
        }
        @if (uploadCollections().length === 0 && !(uploadCollectionLoading$ | async)) {
            <div class="text-center text-slate-600 font-semibold text-lg tracking-wide px-5 mt-5">
                Add your first collection now and start uploading your documents.
                <div class="mt-6">
                    <app-button (click)="openModal()">Create Collection</app-button>
                </div>
            </div>
        }
    `,
    styleUrl: './upload-collection-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLinkActive, RouterLink, ButtonComponent, AsyncPipe],
})
export class UploadCollectionListComponent {
    store = inject(Store);
    modalService = inject(ModalService);

    uploadCollections = this.store.selectSignal(selectUploadCollectionAll);
    uploadCollectionLoading$ = this.store.select(selectUploadCollectionLoading);

    openModal() {
        this.modalService.open(CreateCollectionModalComponent);
    }
}
