import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Store } from '@ngrx/store';
import Dropzone from 'dropzone';
import { environment } from '../../../../environments/environment';
import { selectAccessToken } from '../../../auth/+store/auth.selectors';
import { UploadActions } from '../../../upload/+store/upload/upload.actions';
import { Upload } from '../../../upload/+store/upload/upload.model';

@Component({
    selector: 'app-drag-drop-upload',
    template: `
        <form
            class="dropzone w-full h-[200px] flex justify-center items-center bg-blue-300/10 shadow-lg shadow-gray-200/80 rounded-md text-slate-400"
            #dropzone>
            <input type="hidden" name="uploadCollectionId" [value]="uploadCollectionId" />
        </form>
    `,
    styleUrls: ['./drag-drop-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule],
})
export class DragDropUploadComponent implements OnInit {
    private store = inject(Store);
    private cdr = inject(ChangeDetectorRef);
    private toast = inject(HotToastService);

    route = inject(ActivatedRoute);
    destroyRef = inject(DestroyRef);

    @Input() uploadCollectionId!: string;
    @ViewChild('dropzone', { static: true }) dropzoneRef!: ElementRef;
    accessToken!: string | null;
    uploadingFiles = new Map<string, Dropzone.DropzoneFile>();

    @Output() uploading = new EventEmitter<Map<string, Dropzone.DropzoneFile>>();
    @Output() finished = new EventEmitter<boolean>();

    async ngOnInit() {
        this.store
            .select(selectAccessToken)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(accessToken => {
                this.accessToken = accessToken;
                this.cdr.markForCheck();
            });

        const dropzone = new Dropzone(this.dropzoneRef.nativeElement, {
            url: `${environment.apiUrl}/upload`,
            addedfile: file => {
                this.uploadingFiles.set(file.upload!.uuid, file);
                this.updateOutput();
                this.cdr.markForCheck();
            },
            uploadprogress: (file, progress, bytesSent) => {
                this.uploadingFiles.set(file.upload!.uuid, file);
                this.updateOutput();
                this.cdr.markForCheck();
            },
        });

        dropzone.on('sending', async (file: Dropzone.DropzoneFile, xhr: XMLHttpRequest, formdata: FormData) => {
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.accessToken);
        });

        dropzone.on('error', (file, err: any) => {
            this.toast.error(err.message[0]);
            dropzone.removeFile(file);
            this.uploadingFiles.delete(file.upload!.uuid);
            this.updateOutput();
            this.cdr.markForCheck();
        });

        dropzone.on('success', async (file, upload: Upload) => {
            this.store.dispatch(UploadActions.loadUploadsByCollectionIdSuccess({ uploads: [upload] }));
            dropzone.removeFile(file);
            this.uploadingFiles.delete(file.upload!.uuid);
            this.updateOutput();
            this.finished.emit(true);
            this.cdr.markForCheck();
        });
    }

    updateOutput() {
        this.uploading.emit(this.uploadingFiles);
    }
}
