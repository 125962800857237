import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, from, mergeMap, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkflowBuilderService } from '../../workflow-builder.service';
import { WorkflowService } from '../../workflow.service';
import { WorkflowActions } from './workflow.actions';

@Injectable()
export class WorkflowEffects {
    actions$ = inject(Actions);
    workflowService = inject(WorkflowService);
    workflowBuilderservice = inject(WorkflowBuilderService);
    router = inject(Router);

    loadWorkflows$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.loadWorkflows),
            exhaustMap(() =>
                this.workflowService.loadAll().pipe(
                    map(workflows => WorkflowActions.loadWorkflowsSuccess({workflows})),
                    catchError(error => of(WorkflowActions.loadWorkflowsFailure({error}))),
                ),
            ),
        ),
    );

    loadWorkflowById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.loadWorkflowById),
            mergeMap(action =>
                this.workflowService.loadById(action.id).pipe(
                    map(workflow => WorkflowActions.loadWorkflowByIdSuccess({workflow})),
                    catchError(error => of(WorkflowActions.loadWorkflowByIdFailure({error}))),
                ),
            ),
        ),
    );

    // generateWorkflow$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(WorkflowActions.generateWorkflow),
    //         exhaustMap(action =>
    //             this.workflowBuilderservice.generateWorkflow(action.dto).pipe(
    //                 tap(workflow => this.router.navigate(['/', 'workflow', 'edit', workflow.id])),
    //                 map(workflow => WorkflowActions.loadWorkflowByIdSuccess({workflow})),
    //                 catchError(error => of(WorkflowActions.generateWorkflowFailure({error}))),
    //             ),
    //         ),
    //     ),
    // );

    createEmptyWorkflow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.createEmptyWorkflow),
            exhaustMap(action =>
                this.workflowBuilderservice.createEmptyWorkflow().pipe(
                    mergeMap(workflow => from([
                        WorkflowActions.loadWorkflowByIdSuccess({workflow}),
                        WorkflowActions.createEmptyWorkflowSuccess({workflow}),
                    ])),
                    catchError(error => of(WorkflowActions.createEmptyWorkflowFailure({error}))),
                )),
        ),
    );

    redirectAfterWorkflowCreation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.createEmptyWorkflowSuccess),
            tap(({workflow}) => this.router.navigate(['/', 'workflow', workflow.id, 'edit'])),
        ), {dispatch: false},
    );

    updateWorkflowDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.updateWorkflowDraft),
            exhaustMap(action =>
                this.workflowBuilderservice.updateWorkflowDraft(action.id, action.dto).pipe(
                    map(workflow => WorkflowActions.updateWorkflowDraftSuccess({workflow})),
                    catchError(error => of(WorkflowActions.updateWorkflowDraftFailure({error}))),
                ),
            ),
        ),
    );

    addWorkflowDraftInput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.addWorkflowDraftInput),
            exhaustMap(action =>
                this.workflowBuilderservice.addWorkflowDraftInput(action.id, action.dto).pipe(
                    map(workflow => WorkflowActions.addWorkflowDraftInputSuccess({workflow})),
                    catchError(error => of(WorkflowActions.addWorkflowDraftInputFailure({error}))),
                ),
            ),
        ),
    );

    updateWorkflowDraftInput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.updateWorkflowDraftInput),
            exhaustMap(action =>
                this.workflowBuilderservice.updateWorkflowDraftInput(action.id, action.dto).pipe(
                    map(workflow => WorkflowActions.updateWorkflowDraftInputSuccess({workflow})),
                    catchError(error => of(WorkflowActions.updateWorkflowDraftInputFailure({error}))),
                ),
            ),
        ),
    );

    updateWorkflowDraftInputOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.updateWorkflowDraftInputOrder),
            exhaustMap(action =>
                this.workflowBuilderservice.updateWorkflowDraftInputOrder(action.id, action.dto).pipe(
                    map(workflow => WorkflowActions.updateWorkflowDraftInputOrderSuccess({workflow})),
                    catchError(error => of(WorkflowActions.updateWorkflowDraftInputOrderFailure({error}))),
                ),
            ),
        ),
    );

    removeWorkflowDraftInput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.removeWorkflowDraftInput),
            exhaustMap(action =>
                this.workflowBuilderservice.removeWorkflowDraftInput(action.id, action.dto).pipe(
                    map(workflow => WorkflowActions.removeWorkflowDraftInputSuccess({workflow})),
                    catchError(error => of(WorkflowActions.removeWorkflowDraftInputFailure({error}))),
                ),
            ),
        ),
    );

    removeWorkflowDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.removeWorkflowDraft),
            exhaustMap(action =>
                this.workflowBuilderservice.removeWorkflowDraft(action.id).pipe(
                    map(workflow => WorkflowActions.removeWorkflowDraftSuccess({workflow})),
                    catchError(error => of(WorkflowActions.removeWorkflowDraftFailure({error}))),
                ),
            ),
        ),
    );

    publishWorkflowDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.publishWorkflowDraft),
            exhaustMap(action =>
                this.workflowBuilderservice.publishWorkflowDraft(action.id).pipe(
                    map(workflow => WorkflowActions.publishWorkflowDraftSuccess({workflow})),
                    catchError(error => of(WorkflowActions.publishWorkflowDraftFailure({error}))),
                ),
            ),
        ),
    );

    toggleWorkflowFavorite = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.toggleWorkflowFavorite),
            exhaustMap(action =>
                this.workflowBuilderservice.toggleWorkflowFavorite(action.id).pipe(
                    map(workflow => WorkflowActions.toggleWorkflowFavoriteSuccess({workflow})),
                    catchError(error => of(WorkflowActions.toggleWorkflowFavoriteFailure({error}))),
                ),
            ),
        ),
    );

    deleteWorkflow = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkflowActions.deleteWorkflow),
            exhaustMap(action =>
                this.workflowBuilderservice.deleteWorkflow(action.id).pipe(
                    map(workflow => WorkflowActions.deleteWorkflowSuccess({workflow})),
                    catchError(error => of(WorkflowActions.deleteWorkflowFailure({error}))),
                ),
            ),
        ),
    );
}
