import { inject, NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
    faComments,
    faEye,
    faFilePdf,
    faFiles,
    faFolderOpen as faFolderOpenDuo,
    faFolders,
    faHome,
    faPaperPlane,
    faPaperPlaneTop,
    faPencil,
    faRectangleHistory,
    faRightFromBracket,
    faSpinnerThird,
    faStars,
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faArrowProgress,
    faBooks as faBooksLight,
    faChevronDown,
    faChevronRight,
    faCommentLines,
    faEyeSlash,
    faGear,
    faGraduationCap,
    faHeart,
    faPersonToPortal,
    faUserSecret,
    faNotes,
    faCalendarLines as faCalendarLinesLight,
} from '@fortawesome/pro-light-svg-icons';
import {
    faArrowRightFromBracket,
    faArrowRightLongToLine,
    faBooks,
    faCirclePlay,
    faEllipsis,
    faEllipsisH,
    faEyeSlash as faEyeSlashRegular,
    faFeather,
    faFolder,
    faFolderOpen,
    faPaperclipVertical,
    faPenToSquare,
    faTrashCan,
    faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import {
    faArrowProgress as faArrowProgressSolid,
    faArrowsRotate,
    faBooks as faBooksSolid,
    faBullhorn,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCircleCheck,
    faCirclePlus,
    faClipboard,
    faCloudArrowUp,
    faCommentArrowUpRight,
    faCommentLines as faCommentLinesSolid,
    faFileDownload,
    faGear as faGearSolid,
    faGears,
    faGraduationCap as faGraduationCapSolid,
    faHeart as faHeartSolid,
    faPlus,
    faSpinnerScale,
    faSquareDashedCirclePlus,
    faTimes,
    faTrash,
    faNotes as faNotesSolid,
    faCalendarLines,
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
    exports: [],
    imports: [],
})
export class SharedModule {
    private iconLibrary = inject(FaIconLibrary);

    constructor() {
        const iconLibrary = this.iconLibrary;

        iconLibrary.addIcons(
            faArrowProgress,
            faArrowProgressSolid,
            faArrowRightFromBracket,
            faArrowRightLongToLine,
            faArrowsRotate,
            faBooks,
            faBooksLight,
            faBooksSolid,
            faBullhorn,
            faCaretDown,
            faCaretRight,
            faCaretUp,
            faCheck,
            faChevronDown,
            faChevronRight,
            faCircleCheck,
            faCirclePlay,
            faCirclePlus,
            faClipboard,
            faCloudArrowUp,
            faCommentArrowUpRight,
            faCommentLines,
            faCommentLinesSolid,
            faComments,
            faEllipsis,
            faEllipsisH,
            faEye,
            faEyeSlash,
            faEyeSlashRegular,
            faFeather,
            faFileDownload,
            faFilePdf,
            faFiles,
            faFolder,
            faFolderOpen,
            faFolderOpenDuo,
            faFolders,
            faGear,
            faGears,
            faGearSolid,
            faGlobe,
            faGraduationCap,
            faGraduationCapSolid,
            faHeart,
            faHeartSolid,
            faHome,
            faLinkedin,
            faMagnifyingGlass,
            faNotes,
            faNotesSolid,
            faPaperclipVertical,
            faPaperPlane,
            faPaperPlaneTop,
            faPencil,
            faPenToSquare,
            faPersonToPortal,
            faPlus,
            faRectangleHistory,
            faRightFromBracket,
            faSpinnerScale,
            faSpinnerThird,
            faSquareDashedCirclePlus,
            faStars,
            faTimes,
            faTrash,
            faTrashCan,
            faUserSecret,
            faCalendarLines,
            faCalendarLinesLight,
        );
    }
}
