import { Directive, ElementRef, inject, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
    selector: '[scrollbar]',
    standalone: true,
})
export class PerfectScrollbarDirective implements OnInit {
    private elRef = inject<ElementRef<HTMLElement>>(ElementRef);

    perfectScrollbar!: PerfectScrollbar;

    ngOnInit() {
        this.elRef.nativeElement.classList.add('relative');
        this.perfectScrollbar = new PerfectScrollbar(this.elRef.nativeElement);
    }

    update() {
        this.perfectScrollbar.update();
    }

}
