import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Upload } from './+store/upload/upload.model';

@Injectable({
    providedIn: 'root',
})
export class UploadService {
    private http = inject(HttpClient);


    loadByCollectionId(uploadCollectionId: string) {
        return this.http.get<Upload[]>(`${environment.apiUrl}/upload/${uploadCollectionId}`);
    }

    deleteUpload(id: string) {
        return this.http.delete<Upload>(`${environment.apiUrl}/upload/${id}`);
    }

    loadAll() {
        return this.http.get<Upload[]>(`${environment.apiUrl}/upload`);
    }
}
