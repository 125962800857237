import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { modalFadeInOutAnimation } from '../animations/modal-fade-in-out.animation';

@Component({
    selector: 'app-modal-backdrop',
    template: ` <div class="fixed inset-0 bg-stone-950/50 z-[1000]"></div> `,
    styleUrls: ['./modal-backdrop.component.scss'],
    animations: [modalFadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ModalBackdropComponent implements OnInit {
    @HostBinding('@fadeInOut') fadeInOutBinding: any;

    constructor() {}

    ngOnInit(): void {}
}
