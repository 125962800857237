import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Document } from './+store/document.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    private http = inject(HttpClient);

    loadDocumentsByChat(chatId: string): Observable<Document[]> {
        return this.http.get<Document[]>(`${environment.apiUrl}/documents`, { params: { chatId } });
    }

    download(uploadId: string) {
        this.http
            .get<{ url: string; fileName: string }>(`${environment.apiUrl}/upload/download/${uploadId}`)
            .subscribe(response => {
                const anchor = document.createElement('a');
                anchor.href = response.url;
                anchor.download = response.fileName;
                anchor.target = '_blank';
                anchor.click();
            });
    }
}
