import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Template } from './template.interface';


export const TemplateActions = createActionGroup({
    source: 'Template/API',
    events: {
        'Load Templates': emptyProps(),
        'Load Templates Success': props<{ templates: Template[] }>(),
        'Load Templates Failure': props<{ error: any }>(),

        'Add Template': props<{ name: string, prompt: string }>(),
        'Update Template': props<{ id: string, name: string, prompt: string }>(),

        'Delete Template': props<{ id: string }>(),
        'Delete Template Success': props<{ id: string }>(),

        'Set Current Template Id': props<{ id: string }>(),

        'Clear Templates': emptyProps(),
    },
});
