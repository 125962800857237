import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    inject,
    OnInit,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { NgDatePipesModule } from 'ngx-pipes';
import { debounceTime } from 'rxjs';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { ChatActions } from '../../+store/chat.actions';
import { selectChatLoading, selectChatsGroupedByDate } from '../../+store/chat.selectors';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { PerfectScrollbarDirective } from '../../../shared/directives/perfect-scrollbar/perfect-scrollbar.directive';
import { ScrollEndDirective } from '../../../shared/directives/scroll-end/scroll-end.directive';
import { DateFromNowPipe } from '../../../shared/pipes/date-from-now.pipe';
import { WorkflowActions } from '../../../workflow/+store/workflow/workflow.actions';
import { selectWorkflowAll } from '../../../workflow/+store/workflow/workflow.selectors';
import { ChatListItemComponent } from '../../components/chat-list-item/chat-list-item.component';

@Component({
    selector: 'app-chat-index',
    template: `
        <app-main-wrapper>
            <div class="w-full flex h-full text-stone-700">
                <aside class="w-[300px] flex flex-col shrink-0  border-r border-stone-100">
                    <div class="relative">
                        <div class="flex px-6 pt-5 pb-4">
                            <div class="font-bold text-lg text-stone-700">Chats</div>
                            <div class="ml-auto flex">
                                <app-button (click)="openSearch()" color="transparent" [square]="true" size="sm">
                                    @if (!this.showSearch()) {
                                        <fa-icon
                                            class="text-stone-700"
                                            size="lg"
                                            [icon]="['far', 'magnifying-glass']"></fa-icon>
                                    }
                                </app-button>
                            </div>
                        </div>

                        @if (this.showSearch()) {
                            <div class="flex px-6 pt-5 pb-4 absolute inset-0 bg-white" [@expandShrink]="showSearch()">
                                <form class="grow" [formGroup]="searchForm">
                                    <input
                                        class="text-stone-700 w-full py-1 placeholder:text-stone-400 focus:outline-none"
                                        placeholder="Search chats"
                                        formControlName="searchText"
                                        #searchInput />
                                </form>
                                <div class="ml-auto flex grow-0">
                                    <app-button
                                        (click)="showSearch.set(false)"
                                        color="transparent"
                                        [square]="true"
                                        size="sm">
                                        <fa-icon class="text-stone-700" size="lg" [icon]="['fas', 'times']"></fa-icon>
                                    </app-button>
                                </div>
                            </div>
                        }
                    </div>

                    <div class="px-6 mb-3">
                        <app-button [link]="['chat', 'create']" color="primary" [outline]="true" [fullWidth]="true">
                            <fa-icon [icon]="['far', 'pen-to-square']" class="pr-1"></fa-icon>
                            New Chat
                        </app-button>
                    </div>

                    <div class="overflow-auto max-h-full flex flex-col space-y-2" (appScrollEnd)="loadMore()" scrollbar>
                        @for (groupedChat of groupedChats(); track groupedChat[0]) {
                            @let date = groupedChat[0];
                            @let chats = groupedChat[1];
                            <div>
                                <div class="px-6 py-2 text-xs text-stone-800 font-semibold tracking-wide capitalize">
                                    {{ date | dateFromNow: 'days' : 1 | async }}
                                </div>

                                <div class="flex flex-col space-y-0.5 px-3">
                                    @for (chat of chats; track chat.id) {
                                        <app-chat-list-item [chat]="chat"></app-chat-list-item>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    @if (this.isLoading()) {
                        <div class="flex justify-center py-3">
                            <fa-icon
                                class="text-stone-700"
                                size="xl"
                                [icon]="['fad', 'spinner-third']"
                                animation="spin"></fa-icon>
                        </div>
                    }
                </aside>
                <div class="w-full">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </app-main-wrapper>
    `,
    styleUrls: ['./chat-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MainWrapperComponent,
        ReactiveFormsModule,
        RouterOutlet,
        ButtonComponent,
        NgDatePipesModule,
        DateFromNowPipe,
        AsyncPipe,
        FaIconComponent,
        PerfectScrollbarDirective,
        ScrollEndDirective,
        ChatListItemComponent,
    ],
    animations: [
        trigger('expandShrink', [
            transition(':enter', [style({ width: '0%' }), animate('0.1s ease-out', style({ width: '100%' }))]),
            transition(':leave', [animate('0.1s ease-in', style({ width: '0%' }))]),
        ]),
    ],
})
export class ChatIndexComponent implements OnInit {
    store = inject(Store);
    fb = inject(FormBuilder);

    form!: FormGroup;
    searchForm = this.fb.nonNullable.group({
        searchText: [''],
    });
    searchFormChanges$ = this.searchForm.get('searchText')!.valueChanges.pipe(takeUntilDestroyed(), debounceTime(300));
    showSearch = signal(false);
    searchInput = viewChild<ElementRef<HTMLInputElement>>('searchInput');

    workflows = this.store.selectSignal(selectWorkflowAll);

    chats = this.store.selectSignal(selectChatsGroupedByDate());
    isLoading = this.store.selectSignal(selectChatLoading);
    groupedChats = computed(() => Object.entries(this.chats()));

    ngOnInit() {
        this.store.dispatch(WorkflowActions.loadWorkflows());
        this.store.dispatch(ChatActions.loadChats({}));

        this.form = this.fb.nonNullable.group({
            model: ['gpt-4o', Validators.required],
            private: [false],
            settings: [{ temperature: 0.8 }],
        });

        this.searchFormChanges$.subscribe(value => {
            this.store.dispatch(ChatActions.clearChats());
            this.store.dispatch(ChatActions.loadChats({ searchText: value }));
        });
    }

    createChat(message: { text: string; knowledgeBase: boolean; uploadedFiles: File[] }) {
        const formValue = this.form.getRawValue();
        this.store.dispatch(
            ChatActions.createChat({
                text: message.text,
                model: formValue.model,
                settings: formValue.settings,
                private: formValue.private,
                knowledgeBase: message.knowledgeBase,
                uploadedFiles: message.uploadedFiles,
            }),
        );
    }

    loadMore() {
        this.store.dispatch(ChatActions.loadMoreChats({ searchText: this.searchForm.get('searchText')!.value }));
    }

    openSearch() {
        this.showSearch.set(true);
        this.searchForm.get('searchText')!.setValue('');
        setTimeout(() => {
            this.searchInput()?.nativeElement.focus();
        }, 50);
    }

    closeSearch() {
        this.showSearch.set(false);
    }
}
