import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { CustomFormData } from '../shared/utilities/custom-form-data.helper';
import { Update } from '../shared/utilities/update.type';
import { Chat } from './+store/chat.model';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    private socket = inject(Socket);
    private http = inject(HttpClient);

    createChat(options: { private: Chat['private']; model: Chat['model']; settings: Chat['settings'] }) {
        return this.http.post<Chat>(`${environment.apiUrl}/chat`, options);
    }

    sendChatMessage(payload: { text: string; chatId: string; knowledgeBase: boolean; uploadedFiles?: File[] }) {
        const formData = new CustomFormData({
            text: payload.text,
            tools: {
                knowledgeBase: {
                    enabled: payload.knowledgeBase,
                },
            },
        });

        payload.uploadedFiles?.forEach(uploadedFile => formData.append('files', uploadedFile));
        return this.http.post(`${environment.apiUrl}/chat/${payload.chatId}/message`, formData);
    }

    loadById(id: string) {
        return this.http.get<Chat>(`${environment.apiUrl}/chat/${id}`);
    }

    loadByProjectId(projectId: string) {
        return this.http.get<Chat[]>(`${environment.apiUrl}/chat/project/${projectId}`);
    }

    updateChat(value: Update<Chat>) {
        return this.http.patch<Chat>(`${environment.apiUrl}/chat/${value.id}`, value);
    }

    load(cursor: string = '', searchText: string = '') {
        const limit = 20;
        return this.http.get<{ chats: Chat[]; chatCount: number; lastCursor: string }>(`${environment.apiUrl}/chat`, {
            params: {
                cursor,
                limit,
                searchText,
            },
        });
    }

    subscribe(id: string) {
        this.socket.emit('subscribe', `chat/${id}`);
    }

    unsubscribe(id: string) {
        this.socket.emit('unsubscribe', `chat/${id}`);
    }

    enhancePrompt(prompt: string) {
        return this.http.post<{ text: string }>(`${environment.apiUrl}/enhance-prompt`, {
            prompt: prompt,
        });
    }

    deleteChat(id: string) {
        return this.http.delete(`${environment.apiUrl}/chat/${id}`);
    }
}
