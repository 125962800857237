import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ApplicationRef,
    ChangeDetectorRef,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    inject,
    Injector,
    Input,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { PopoverWrapperComponent } from '../components/popover-wrapper/popover-wrapper.component';

@Directive({
    selector: '[appPopover]',
    standalone: true,
    exportAs: 'appPopover',
})
export class PopoverDirective implements OnInit {
    elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
    private overlay = inject(Overlay);
    private applicationRef = inject(ApplicationRef);
    private renderer = inject(Renderer2);
    private cdr = inject(ChangeDetectorRef);
    private vcr = inject(ViewContainerRef);
    private injector = inject(Injector);

    @Input() appPopover!: TemplateRef<any>;

    componentRef?: ComponentRef<PopoverWrapperComponent>;

    @HostListener('click')
    onClick() {
        if (!this.componentRef) {
            const positionStrategy = this.overlay
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetY: 20,
                    },
                ]);

            const scrollStrategy = this.overlay.scrollStrategies.reposition({ autoClose: true, scrollThrottle: 50 });
            const overlay = this.overlay.create({
                positionStrategy,
                scrollStrategy,
            });

            const popoverPortal = new ComponentPortal(PopoverWrapperComponent, this.vcr, this.injector, undefined, [
                this.vcr.createEmbeddedView(this.appPopover, undefined, { injector: this.injector }).rootNodes,
            ]);

            this.componentRef = overlay.attach(popoverPortal);

            this.componentRef.instance.close.subscribe(() => this.close());
        }
    }

    close() {
        this.componentRef?.destroy();
        this.componentRef = undefined;
    }

    ngOnInit() {}
}
