import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { KnowledgeSourceEffects } from './+store/knowledge-source.effects';

@NgModule({
    declarations: [],
    imports: [CommonModule, EffectsModule.forFeature([KnowledgeSourceEffects])],
})
export class KnowledgeBaseModule {}
