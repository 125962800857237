import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import hljs from 'highlight.js';
import { Marked } from 'marked';
import { markedHighlight } from 'marked-highlight';
import { Chat } from '../../+store/chat.model';
import { UserAvatarComponent } from '../../../shared/components/user-avatar/user-avatar.component';

@Component({
    selector: 'app-chat-message',
    template: `
        <div class="w-full py-7 flex justify-center" [ngClass]="{ 'bg-slate-100/50': isAI }">
            <div class="flex justify-center max-w-[1200px] w-full px-5">
                <div class="pt-1 shrink-0 w-[45px]">
                    @if (isHuman) {
                        <app-user-avatar [user]="message.user"></app-user-avatar>
                    }
                    @if (isAI) {
                        <img
                            ngSrc="/assets/logo/avatar.png"
                            class="aspect-square rounded"
                            priority="true"
                            width="45"
                            height="45"
                            alt="" />
                    }
                </div>
                <div
                    class="ml-6 grow self-center overflow-auto"
                    [class.whitespace-pre-wrap]="isHuman"
                    [innerHTML]="html"></div>
            </div>
        </div>
    `,
    styleUrls: ['./chat-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, UserAvatarComponent, NgOptimizedImage],
})
export class ChatMessageComponent {
    @Input() message!: Chat['messages'][0];
    @Input() chatId!: string | any;

    get isAI() {
        return this.message.type === 'ai';
    }

    get isHuman() {
        return this.message.type === 'human';
    }

    get html() {
        if (this.isHuman) {
            return this.escape(this.message.text);
        }

        const marked = new Marked(
            {
                walkTokens: token => {
                    // if (token.type === 'codespan') {
                    //     token.text = hljs.highlightAuto(token.raw.replace(/^`|`$|$/g, ""), ['plaintext', 'html', 'markdown', 'python', 'javascript', 'typescript', 'php', 'mongodb', 'sql', 'java']).value
                    // }
                },
                renderer: {
                    codespan(text: string) {
                        text = text.replace(/\n$/, '').replace(/^`|`$|$/g, '');
                        return `<code class="hljs language-html text-sm rounded whitespace-normal">${text}</code>`;
                    },
                },
            },
            markedHighlight({
                langPrefix: 'hljs language-',
                highlight(code, lang) {
                    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
                    return hljs.highlight(code, { language, ignoreIllegals: true }).value;
                },
            }),
        );
        return marked.parse(this.message.text);
    }

    escape(s: string) {
        const lookup: { [k: string]: string } = {
            '&': '&amp;',
            '"': '&quot;',
            "'": '&apos;',
            '<': '&lt;',
            '>': '&gt;',
        };
        return s.replace(/[&"'<>]/g, c => lookup[c]);
    }
}
