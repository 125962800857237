import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, inject, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faBookOpen, faFolder } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { TippyDirective } from '@ngneat/helipopper';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Chat } from 'src/app/chat/+store/chat.model';
import { AuthActions } from '../../../auth/+store/auth.actions';
import { selectAuthEmail } from '../../../auth/+store/auth.selectors';
import { ChatActions } from '../../../chat/+store/chat.actions';
import { selectRecentChats } from '../../../chat/+store/chat.selectors';
import { AppState } from '../../../reducers';
import { PerfectScrollbarDirective } from '../../directives/perfect-scrollbar/perfect-scrollbar.directive';
import { ButtonComponent } from '../button/button.component';
import { NavigationCategoryHeaderComponent } from '../navigation-category-header/navigation-category-header.component';

@Component({
    selector: 'app-nav',
    template: `
        <aside
            class="relative bg-gradient-to-b from-slate-50 to-slate-200/80  border-slate-200 shadow-xl shadow-gray-300/90 w-[300px] h-full flex flex-col shrink-0 p-5 overflow-auto"
            scrollbar>
            <div class="flex justify-center">
                <a [routerLink]="['/']">
                    <img src="assets/logo/logo.svg" width="150" alt="" />
                </a>
            </div>
            <app-button
                color="primary"
                [fullWidth]="true"
                [outline]="true"
                rounded="rounded-full"
                class="mt-8"
                [link]="['/']">
                <div class="font-bold tracking-wider text-sm">New Chat</div>
            </app-button>

            <app-navigation-category-header>General</app-navigation-category-header>

            <div class="text-sm">
                <a
                    [routerLink]="['/prompt-library']"
                    routerLinkActive="bg-blue-100"
                    class="px-3 py-2 rounded-md hover:bg-blue-100 block">
                    <fa-icon class="mr-1" [icon]="faBookOpen" [fixedWidth]="true" size="sm"></fa-icon>
                    Prompt Library
                </a>
                <a
                    [routerLink]="['/knowledge-base']"
                    routerLinkActive="bg-blue-100"
                    class="px-3 py-2 rounded-md hover:bg-blue-100 cursor-pointer block mt-1">
                    <fa-icon class="mr-1" [icon]="faFolder" [fixedWidth]="true" size="sm"></fa-icon>
                    Knowledge Base
                </a>
                <a
                    [routerLink]="['/brand-voice']"
                    routerLinkActive="bg-blue-100"
                    class="px-3 py-2 rounded-md hover:bg-blue-100 cursor-pointer block mt-1">
                    <fa-icon class="mr-1" [icon]="['fal', 'heart']" [fixedWidth]="true" size="sm"></fa-icon>
                    Brand Voice
                </a>
                <a
                    [routerLink]="['/workflow/library']"
                    routerLinkActive="bg-blue-100"
                    class="px-3 py-2 rounded-md hover:bg-blue-100 cursor-pointer block mt-1">
                    <fa-icon class="mr-1" [icon]="['fal', 'arrow-progress']" [fixedWidth]="true" size="sm"></fa-icon>
                    Workflows
                </a>
            </div>

            <app-navigation-category-header>History</app-navigation-category-header>

            @if (fakeMode) {
                <div class="text-sm">
                    @for (title of fakeChats; track title) {
                        <a
                            class="py-1 px-3 block rounded-md hover:bg-slate-100 cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden">
                            {{ title }}
                        </a>
                    }
                </div>
            }

            @if (!fakeMode) {
                <div class="text-sm">
                    @for (chat of recentChats$ | async; track chat.id) {
                        <a
                            [routerLink]="['/chat', chat.id]"
                            class="py-1 px-3 block rounded-md hover:bg-slate-100 cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden"
                            [tp]="chat.name"
                            [tpDelay]="500">
                            {{ chat.name }}
                        </a>
                    }
                </div>
            }

            @if ((recentChats$ | async)?.length === 0) {
                <div class="text-sm text-slate-400 text-center px-10 mt-3">
                    You don't have any chats yet. Try to start one.
                </div>
            }

            <div class="mt-auto text-sm">
                <app-navigation-category-header>Settings</app-navigation-category-header>
                <a [routerLink]="['/settings']" class="px-3 py-2 block rounded-md hover:bg-slate-100 cursor-pointer">
                    <fa-icon class="mr-1" [icon]="faCog" [fixedWidth]="true" size="sm"></fa-icon>
                    Settings
                </a>
                <a [routerLink]="['/settings', 'members']" class="px-3 py-2 rounded-md hover:bg-slate-100 block">
                    <fa-icon class="mr-1" [icon]="faUsers" [fixedWidth]="true" size="sm"></fa-icon>
                    Members
                </a>
                <button
                    type="button"
                    (click)="logout()"
                    class="px-3 py-2 rounded-md hover:bg-slate-100 text-left w-full">
                    <fa-icon
                        class="mr-1"
                        [icon]="['fad', 'right-from-bracket']"
                        [fixedWidth]="true"
                        size="sm"></fa-icon>
                    Logout
                </button>
            </div>
        </aside>
    `,
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        PerfectScrollbarDirective,
        RouterLink,
        ButtonComponent,
        NavigationCategoryHeaderComponent,
        TippyDirective,
        FaIconComponent,
        RouterLinkActive,
        AsyncPipe,
    ],
})
export class NavigationComponent implements OnInit {
    private store = inject<Store<AppState>>(Store);
    private cdr = inject(ChangeDetectorRef);

    faBookOpen = faBookOpen;
    faCog = faCog;
    faFolder = faFolder;
    faUsers = faUsers;

    email$: Observable<string | null> = of(null);
    recentChats$!: Observable<Chat[]>;
    fakeChats = [
        'Maximizing Social Media Engagement Strategies',
        'Elevating Sales Outcomes through Data',
        'Boosting Email Marketing ROI',
        'Cutting-edge Customer Segmentation Techniques',
        'Revolutionizing Product Launch Strategies',
        'In-depth Market Research and Insights',
        'Effective Lead Generation and Conversion',
        'Improving Brand Visibility through Data Analysis',
        'Developing Content Marketing Strategies',
        'Leveraging Data for Competitive Analysis',
    ];
    fakeMode = false;

    @HostListener('window:keydown.shift.control.meta.j') activateFakeMode() {
        this.fakeMode = !this.fakeMode;
        this.cdr.markForCheck();
    }

    ngOnInit() {
        this.email$ = this.store.select(selectAuthEmail);
        this.store.dispatch(ChatActions.loadAllChats());
        this.recentChats$ = this.store.select(selectRecentChats());
    }

    logout() {
        this.store.dispatch(AuthActions.logout());
    }
}
