import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { KnowledgeSource } from './+store/knowledge-source.model';

@Injectable({
    providedIn: 'root',
})
export class KnowledgeBaseService {
    http = inject(HttpClient);

    loadKnowledgeSources() {
        return this.http.get<KnowledgeSource[]>(`${environment.apiUrl}/knowledge-base`);
    }

    deleteKnowledgeSource(id: string) {
        return this.http.delete(`${environment.apiUrl}/knowledge-base/${id}`);
    }

    addWebsite(url: string) {
        return this.http.post<KnowledgeSource>(`${environment.apiUrl}/knowledge-base/website`, { url });
    }
}
