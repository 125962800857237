import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTenant from './tenant.reducer';
import { adapter } from './tenant.reducer';

export const selectTenantState =
    createFeatureSelector<fromTenant.TenantState>(
        fromTenant.tenantsFeatureKey,
    );

export const {
    selectAll: selectAllTenants,
} = adapter.getSelectors(selectTenantState);

export const tenantLoading = () => createSelector(selectTenantState,
    state => state.loading);

export const selectUserTenant = createSelector(selectAllTenants,
    tenants => tenants[0]);
