import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { WorkflowOutputPreview } from './+store/workflow-outputs/workflow-output.model';
import { WorkflowAddDraftInputDto } from './+store/workflow/dto/workflow-add-draft-input.dto';
import { WorkflowGenerateDto } from './+store/workflow/dto/workflow-generate.dto';
import { WorkflowRemoveDraftInputDto } from './+store/workflow/dto/workflow-remove-draft-input.dto';
import { WorkflowSetDraftDto } from './+store/workflow/dto/workflow-set-draft.dto';
import { WorkflowUpdateDraftInputOrderDto } from './+store/workflow/dto/workflow-update-draft-input-order.dto';
import { WorkflowUpdateDraftInputDto } from './+store/workflow/dto/workflow-update-draft-input.dto';
import { WorkflowUpdateDraftDto } from './+store/workflow/dto/workflow-update-draft.dto';
import { Workflow, WorkflowBase } from './+store/workflow/workflow.model';

@Injectable({
    providedIn: 'root',
})
export class WorkflowBuilderService {
    http = inject(HttpClient);

    constructor() {}

    generateWorkflow(dto: WorkflowGenerateDto) {
        return this.http.post<Workflow>(`${environment.apiUrl}/workflow/generate`, dto);
    }

    createEmptyWorkflow() {
        return this.http.post<Workflow>(`${environment.apiUrl}/workflow/create`, {});
    }

    setWorkflowDraft(id: string, dto: WorkflowSetDraftDto) {
        return this.http.post<Workflow>(`${environment.apiUrl}/workflow/draft/${id}`, dto);
    }

    updateWorkflowDraft(id: string, dto: WorkflowUpdateDraftDto) {
        return this.http.patch<Workflow>(`${environment.apiUrl}/workflow/draft/${id}`, dto);
    }

    addWorkflowDraftInput(id: string, dto: WorkflowAddDraftInputDto) {
        return this.http.post<Workflow>(`${environment.apiUrl}/workflow/draft/${id}/input`, dto);
    }

    updateWorkflowDraftInput(id: string, dto: WorkflowUpdateDraftInputDto) {
        return this.http.patch<Workflow>(`${environment.apiUrl}/workflow/draft/${id}/input`, dto);
    }

    updateWorkflowDraftInputOrder(id: string, dto: WorkflowUpdateDraftInputOrderDto) {
        return this.http.patch<Workflow>(`${environment.apiUrl}/workflow/draft/${id}/input-order`, dto);
    }

    removeWorkflowDraftInput(id: string, dto: WorkflowRemoveDraftInputDto) {
        return this.http.delete<Workflow>(`${environment.apiUrl}/workflow/draft/${id}/input/${dto.inputId}`);
    }

    removeWorkflowDraft(id: string) {
        return this.http.delete<Workflow>(`${environment.apiUrl}/workflow/draft/${id}`);
    }

    publishWorkflowDraft(id: string) {
        return this.http.get<Workflow>(`${environment.apiUrl}/workflow/publish/${id}`);
    }

    toggleWorkflowFavorite(id: string) {
        return this.http.get<Workflow>(`${environment.apiUrl}/workflow/toggle-favorite/${id}`);
    }

    generatePreviewOutput(payload: { data: any[]; workflowId: string }) {
        return this.http.post<WorkflowOutputPreview>(`${environment.apiUrl}/workflow/preview/${payload.workflowId}`, {
            data: payload.data,
        });
    }

    deleteWorkflow(id: string) {
        return this.http.delete<Workflow>(`${environment.apiUrl}/workflow/${id}`);
    }
}
