import { ChatModel } from '../app/chat/chat-model.enum';

export const environment = {
    apiUrl: '/api',
    defaultApi: 'https://app.owlatwork.com/api',

    stripePublishKey: 'pk_live_51HFxUsLejMtS2pDBJE44rSOaEuCB1qmZgbHIWotxalV3AZVzWLxUQOLaZV9yL5zJQBfAhzaqTok39YA4dLpzsDOR006AXJcrVc',
    stripeDefaultPrice: 'price_1OHnxLLejMtS2pDB2R4DKKLX',

    availableModels: [
        {
            model: ChatModel['GPT-4'],
            label: 'GPT-4',
            manufacturer: 'Open AI',
            description: 'A state-of-the-art model for advanced natural language understanding and generation.',
        },
        {
            model: ChatModel['GPT-4o'],
            label: 'GPT-4o',
            manufacturer: 'Open AI',
            description: 'An optimized version of GPT-4 focused on efficiency for real-time applications.',
        },
        {
            model: ChatModel['Claude 3 Opus'],
            label: 'Claude 3 Opus',
            manufacturer: 'Anthrophic',
            description: 'Specializes in providing safe, ethical dialogue with reliable and human-aligned interactions across various contexts.',
        },
        {
            model: ChatModel['Gemini 1.5 Pro'],
            label: 'Gemini 1.5 Pro',
            manufacturer: 'Google',
            description: 'Combines machine learning with multimodal capabilities for versatile problem-solving.',
        },
    ],
};
