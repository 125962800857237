import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { MemberActions } from './member.actions';
import { Member } from './member.model';

export const membersFeatureKey = 'members';

export interface MemberState extends EntityState<Member> {
    loading: boolean;
}

export const adapter: EntityAdapter<Member> = createEntityAdapter<Member>();

export const initialState: MemberState = adapter.getInitialState({
    loading: false,
});

export const memberReducer = createReducer(
    initialState,
    on(MemberActions.loadMembers,
        (state, action) => ({ ...state, loading: true }),
    ),
    on(MemberActions.loadMembersSuccess,
        (state, action) => adapter.upsertMany(action.members, { ...state, loading: false }),
    ),
    on(MemberActions.inviteMemberSuccess,
        (state, action) => adapter.upsertOne(action.member, { ...state, loading: false })),
    on(MemberActions.clearMembers,
        state => adapter.removeAll(state),
    ),
);

export const membersFeature = createFeature({
    name: membersFeatureKey,
    reducer: memberReducer,
    extraSelectors: ({ selectMembersState }) => ({
        ...adapter.getSelectors(selectMembersState),
    }),
});


