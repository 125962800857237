import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { WorkflowOutputActions } from './workflow-output.actions';
import { WorkflowOutput } from './workflow-output.model';

export const workflowOutputsFeatureKey = 'workflowOutputs';

export interface WorkflowOutputState extends EntityState<WorkflowOutput> {
    loading: boolean;
}

export const adapter: EntityAdapter<WorkflowOutput> = createEntityAdapter<WorkflowOutput>();

export const initialState: WorkflowOutputState = adapter.getInitialState({
    loading: false,
});

export const workflowOutputReducer = createReducer(
    initialState,
    on(WorkflowOutputActions.generateOutput,
        state => adapter.map(entity => ({ ...entity, new: false }), {
            ...state,
            loading: true,
        }),
    ),
    on(WorkflowOutputActions.generateOutputSuccess,
        (state, action) => adapter.upsertMany(action.workflowOutputs, {
            ...state,
            loading: false,
        }),
    ),
    on(WorkflowOutputActions.generateOutputFailure, state => ({ ...state, loading: false })),
    on(WorkflowOutputActions.loadOutputsByWorkflowIdSuccess,
        (state, action) => adapter.addMany(action.workflowOutputs, state)),
);

export const workflowOutputsFeature = createFeature({
    name: workflowOutputsFeatureKey,
    reducer: workflowOutputReducer,
    extraSelectors: ({ selectWorkflowOutputsState }) => ({
        ...adapter.getSelectors(selectWorkflowOutputsState),
    }),
});
