import { HTTP_INTERCEPTORS, HttpErrorResponse, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { popperVariation, provideTippyConfig, TippyDirective, tooltipVariation } from '@ngneat/helipopper';
import { provideTippyLoader } from '@ngneat/helipopper/config';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { BasicAuthInterceptor } from './app/auth/auth.interceptor';
import { AuthModule } from './app/auth/auth.module';
import { ChatEffects } from './app/chat/+store/chat.effects';
import { DocumentModule } from './app/document/document.module';
import { EmbeddingModule } from './app/embedding/embedding.module';
import { KnowledgeBaseModule } from './app/knowledge-base/knowledge-base.module';
import { MemberModule } from './app/member/member.module';
import { PaymentModule } from './app/payment/payment.module';
import { ProjectModule } from './app/project/project.module';
import { PromptLibraryEffect } from './app/prompt-library/+store/prompt-library.effect';
import { metaReducers, reducers } from './app/reducers';
import { SettingModule } from './app/setting/setting.module';
import { SocketService } from './app/shared/services/socket/socket.service';
import { SharedModule } from './app/shared/shared.module';
import { TemplateModule } from './app/template/template.module';
import { TenantModule } from './app/tenant/tenant.module';
import { UploadModule } from './app/upload/upload.module';
import { UserEffects } from './app/user/+store/user.effects';
import { WorkflowOutputEffects } from './app/workflow/+store/workflow-outputs/workflow-output.effects';
import { WorkflowEffects } from './app/workflow/+store/workflow/workflow.effects';

import { environment } from './environments/environment';

fetch(`${environment.apiUrl}`, { method: 'POST' })
    .then(response => {
        if (!response.ok || (response.status >= 400 && response.status < 600)) {
            console.log('Switch to default api as fallback');
            environment.apiUrl = environment.defaultApi;
        }
    })
    .catch((err: HttpErrorResponse) => {
        console.log('Switch to default api as fallback');
        environment.apiUrl = environment.defaultApi;
    })
    .finally(() => {
        bootstrapApplication(AppComponent, {
            providers: [
                importProvidersFrom(
                    BrowserModule,
                    ReactiveFormsModule,
                    SharedModule,
                    FontAwesomeModule,
                    TippyDirective,
                    StoreModule.forRoot(reducers, { metaReducers }),
                    EffectsModule.forRoot([
                        UserEffects,
                        WorkflowEffects,
                        WorkflowOutputEffects,
                        PromptLibraryEffect,
                        ChatEffects,
                    ]),
                    StoreRouterConnectingModule.forRoot(),
                    StoreDevtoolsModule.instrument({
                        maxAge: 100,
                        logOnly: !isDevMode(),
                        connectInZone: true,
                    }),
                    SocketIoModule,
                    ProjectModule,
                    UploadModule,
                    AuthModule,
                    EmbeddingModule,
                    TemplateModule,
                    DocumentModule,
                    SettingModule,
                    TenantModule,
                    PaymentModule,
                    MemberModule,
                    KnowledgeBaseModule,
                    AppRoutingModule,
                    MonacoEditorModule.forRoot({
                        requireConfig: { preferScriptTags: true },
                    }),
                ),
                provideTippyLoader(() => import('tippy.js')),
                provideTippyConfig({
                    defaultVariation: 'tooltip',
                    variations: {
                        tooltip: {
                            ...tooltipVariation,
                            arrow: true,
                            offset: [0, 10],
                        },
                        popper: {
                            ...popperVariation,
                            theme: 'neptune',
                        },
                    },
                }),
                { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
                { provide: Socket, useClass: SocketService },
                provideHttpClient(withInterceptorsFromDi()),
                provideHotToastConfig({
                    position: 'top-center',
                    duration: 4000,
                    autoClose: true,
                    theme: 'toast',
                }),
                provideAnimations(),
            ],
        }).catch(err => console.error(err));
    });
