import { createSelector } from '@ngrx/store';
import { membersFeature } from './member.reducer';

export const {
    selectIds: selectMemberIds,
    selectEntities: selectMemberEntities,
    selectAll: selectMemberAll,
    selectTotal: selectMemberTotal,
} = membersFeature;

export const selectMemberById = (id: string) => createSelector(selectMemberEntities, members => members[id]);
