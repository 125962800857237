import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    inject,
    input,
    signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 } from 'uuid';

@Component({
    selector: 'app-toggle-switch',
    template: `
        <label
            [for]="name"
            class="relative inline-flex cursor-pointer select-none items-center"
            [class.disabled]="disabled()">
            <input
                type="checkbox"
                [name]="name"
                [id]="name"
                class="sr-only peer"
                [disabled]="disabled()"
                [checked]="value()"
                (input)="onChange($event)" />
            <div
                [class]="this.class"
                class="group flex toggle-switch-container items-center rounded-full bg-[#CCCCCE] dark:bg-dark-2 p-1 duration-200 peer-checked:bg-sky-600">
                <span class="toggle-switch-handle rounded-full bg-white duration-200"></span>
            </div>
        </label>
    `,
    styleUrls: ['./toggle-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleSwitchComponent),
            multi: true,
        },
    ],
    standalone: true,
})
export class ToggleSwitchComponent implements ControlValueAccessor {
    size = input<'sm' | 'md' | 'lg'>('lg');

    name = v4();
    changeFn?: (v: boolean) => void;
    disabled = signal(false);
    touchedFn?: (v: boolean) => void;
    value = signal(false);
    cdr = inject(ChangeDetectorRef);

    get class() {
        return `toggle-switch-${this.size()}`;
    }

    onChange($event: Event) {
        const target: HTMLInputElement = $event.target as HTMLInputElement;
        if (this.changeFn) {
            this.changeFn(target.checked);
        }

        this.value.set(target.checked);
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;

        // Todo Ich bin mir nicht sicher, ob wir das brauchen. Aber es führte zu Problemen und es scheint auch ohne zu funktionieren
        // if (this.changeFn) {
        // this.changeFn(this.value());
        // }
    }

    registerOnTouched(fn: any): void {
        this.touchedFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled.set(isDisabled);
    }

    writeValue(value: boolean): void {
        this.value.set(value);
    }
}
