import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import {error} from "@angular/compiler-cli/src/transformers/util";

export const authFeatureKey = 'auth';

export interface AccessTokenDecoded {
    email: string;
    iat: number;
    exp: number;
}

export interface AuthState {
    accessToken: string | null,
    exp: Date | null,
    error: any,
    isLoading: boolean,
    email: string |null;
}


export const initialState: AuthState = {
    accessToken: null,
    exp: null,
    error: null,
    isLoading: false,
    email: null,
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.login,
        (state, action) => ({...state, isLoading: true, error: null}),
    ),
    on(AuthActions.loginSuccess,
        (state, {accessToken, email}) => ({...state, isLoading: false, accessToken, email}),
    ),
    on(AuthActions.loginFailure,
        (state, {err}) => ({...state, isLoading: false, error: err }),
    ),
    on(AuthActions.logoutSuccess,
        (state) => initialState,
    ),
    on(AuthActions.signup, state => ({...state, isLoading: true, error: null})),
    on(AuthActions.signupSuccess, state => ({...initialState})),
    on(AuthActions.signupFailure, (state, {err: error}) => ({...state, isLoading: false, error})),

);
