import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { ProjectActions } from './project.actions';
import { Project } from './project.model';

export const projectsFeatureKey = 'projects';

export interface ProjectState extends EntityState<Project> {
    // additional entities state properties
}

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export const initialState: ProjectState = adapter.getInitialState({
    // additional entity state properties
});

export const projectReducer = createReducer(
    initialState,
    on(ProjectActions.addProjectSuccess,
        (state, action) => adapter.addOne(action.project, state),
    ),
    on(ProjectActions.loadProjectsSuccess,
        (state, action) => adapter.setAll(action.projects, state),
    ),
    on(ProjectActions.clearProjects,
        state => adapter.removeAll(state),
    ),
    on(ProjectActions.updateProjectSuccess,
        (state, action) => adapter.updateOne({id: action.project.id, changes: action.project}, state),
    ),
    on(ProjectActions.deleteProjectSuccess,
        (state, action) => adapter.removeOne(action.id, state),
    ),
);

export const projectsFeature = createFeature({
    name: projectsFeatureKey,
    reducer: projectReducer,
    extraSelectors: ({selectProjectsState}) => ({
        ...adapter.getSelectors(selectProjectsState),
    }),
});
