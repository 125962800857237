import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { PromptLibraryActions } from './prompt-library.actions';
import { PromptLibrary } from './prompt-library.model';

export const promptLibrariesFeatureKey = 'promptLibraries';

export interface PromptLibraryState extends EntityState<PromptLibrary> {
    loading: boolean;
}

export const adapter: EntityAdapter<PromptLibrary> = createEntityAdapter<PromptLibrary>();

export const initialState: PromptLibraryState = adapter.getInitialState({
    loading: false,
});

export const promptLibraryReducer = createReducer(
    initialState,
    on(
        PromptLibraryActions.loadPromptLibrary,
        PromptLibraryActions.createPromptTemplate,
        PromptLibraryActions.updatePromptTemplate,
        PromptLibraryActions.deletePromptTemplate,
        state => ({
            ...state,
            loading: true,
        }),
    ),

    on(PromptLibraryActions.loadPromptLibrarySuccess, (state, action) =>
        adapter.setAll(action.promptTemplates, { ...state, loading: false }),
    ),

    on(
        PromptLibraryActions.createPromptTemplateSuccess,
        PromptLibraryActions.updatePromptTemplateSuccess,
        (state, action) => adapter.upsertOne(action.promptTemplate, { ...state, loading: false }),
    ),

    on(PromptLibraryActions.deletePromptTemplateSuccess, (state, action) =>
        adapter.removeOne(action.id, { ...state, loading: false }),
    ),

    on(PromptLibraryActions.clearPromptLibrarys, state => adapter.removeAll(state)),
);

export const promptLibrariesFeature = createFeature({
    name: promptLibrariesFeatureKey,
    reducer: promptLibraryReducer,
    extraSelectors: ({ selectPromptLibrariesState }) => ({
        ...adapter.getSelectors(selectPromptLibrariesState),
    }),
});
