import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TippyDirective } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { ProjectEffects } from './+store/project.effects';
import { CreateProjectModalComponent } from './components/create-project-modal/create-project-modal.component';
import { RenameProjectModalComponent } from './components/rename-project-modal/rename-project-modal.component';
import { ProjectIndexComponent } from './project-index/project-index.component';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        EffectsModule.forFeature([ProjectEffects]),
        FontAwesomeModule,
        SharedModule,
        TippyDirective,
        ReactiveFormsModule,
        RouterOutlet,
        ProjectIndexComponent,
        CreateProjectModalComponent,
        RenameProjectModalComponent,
    ],
    exports: [],
})
export class ProjectModule {
}
