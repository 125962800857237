import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Upload } from './upload.model';

export const UploadActions = createActionGroup({
    source: 'Upload/API',
    events: {
        'Load All Uploads': emptyProps(),
        'Load All Uploads Success': props<{uploads: Upload[]}>(),
        'Load All Uploads Failure': props<{error: any}>(),

        'Load Uploads By Collection Id': props<{uploadCollectionId: string}>(),
        'Load Uploads By Collection Id Success': props<{uploads: Upload[]}>(),
        'Load Uploads By Collection Id Failure': props<{error: any}>(),

        'Add Upload': props<{upload: Upload}>(),
        'Upsert Upload': props<{upload: Upload}>(),

        'Add Uploads': props<{uploads: Upload[]}>(),
        'Upsert Uploads': props<{uploads: Upload[]}>(),

        'Update Upload': props<{upload: Update<Upload>}>(),
        'Update Uploads': props<{uploads: Update<Upload>[]}>(),

        'Delete Upload': props<{id: string}>(),
        'Delete Upload Success': props<{ id: string }>(),
        'Delete Upload Failure': props<{error: any}>(),
        'Delete Uploads': props<{ids: string[]}>(),

        'Clear Uploads': emptyProps(),
    },
});
