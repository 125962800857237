import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { PaymentActions } from '../../+store/payment.actions';
import { AppState } from '../../../reducers';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalBodyComponent } from '../../../shared/modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../shared/modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../shared/modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';
import { StripeService } from '../../stripe.service';

@UntilDestroy()
@Component({
    selector: 'app-delete-subscription-modal',
    template: `
        <app-modal>
            <app-modal-title>
                <h3 class="font-semibold">End subscription</h3>
            </app-modal-title>
            <app-modal-body>
                Do you really want to end your subscription? You will have access to our service until
                {{ nextBillingDate | date: 'shortDate' }}.
            </app-modal-body>
            <app-modal-footer>
                <div class="flex justify-end">
                    <app-button class="mr-3" (click)="modalContainer.onDismiss()" type="button" color="transparent">
                        Cancel
                    </app-button>
                    <app-button (click)="delete()" type="button" color="red"> Delete </app-button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrls: ['./delete-subscription-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ModalComponent, ModalTitleComponent, ModalBodyComponent, ModalFooterComponent, ButtonComponent, DatePipe],
})
export class DeleteSubscriptionModalComponent {
    modalContainer = inject(ModalContentContainerComponent);
    private store = inject<Store<AppState>>(Store);
    private stripeService = inject(StripeService);

    @Input() nextBillingDate!: number;
    @Input() id!: string;

    async delete() {
        this.store.dispatch(PaymentActions.cancelSubscription({ subscriptionId: this.id }));
        this.modalContainer.onDismiss();
    }
}
