import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthWrapperComponent } from '../../components/auth-wrapper/auth-wrapper.component';

@Component({
    selector: 'app-signup-success',
    template: `
      <app-auth-wrapper>
          <div class="w-full">
              <div class="flex mb-8">
                  <img src="assets/logo/logo.svg" width="150" alt="Logo">
              </div>

              <h2 class="text-lg font-semibold text-slate-800 mb-4">Registration successful, <br>check your mail!</h2>

              <div class="flex">
                  <p>Your registration was successful, please open your emails and confirm your e-mail address.</p>
              </div>
              <div class="mt-4 text-sm">
                  <p>
                      After confirming your e-mail address, you can go to the
                      <a [routerLink]="['/auth/login']" class="font-semibold underline cursor-pointer">Login.</a>
                  </p>
              </div>
          </div>
      </app-auth-wrapper>
  `,
    styleUrl: './signup-success.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AuthWrapperComponent, RouterLink],
})
export class SignupSuccessComponent {

}
