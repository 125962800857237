import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnowledgeBaseService } from '../knowledge-base.service';
import { KnowledgeSourceActions } from './knowledge-source.actions';

@Injectable()
export class KnowledgeSourceEffects {
    actions$ = inject(Actions);
    knowledgeBaseService = inject(KnowledgeBaseService);
    toast = inject(HotToastService);

    loadKnowledgeSources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KnowledgeSourceActions.loadKnowledgeSources),
            switchMap(() =>
                this.knowledgeBaseService
                    .loadKnowledgeSources()
                    .pipe(
                        map(knowledgeSources =>
                            KnowledgeSourceActions.loadKnowledgeSourcesSuccess({ knowledgeSources }),
                        ),
                    ),
            ),
        ),
    );

    deleteKnowledgeSource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KnowledgeSourceActions.deleteKnowledgeSource),
            switchMap(action =>
                this.knowledgeBaseService.deleteKnowledgeSource(action.id).pipe(
                    tap(() => this.toast.success('Knowledge Source successfully deleted.')),
                    map(() => KnowledgeSourceActions.deleteKnowledgeSourceSuccess({ id: action.id })),
                    catchError((error: HttpErrorResponse) => {
                        this.toast.error(`Knowledge Source could not be deleted. Error code: ${error.status}.`);
                        return of(KnowledgeSourceActions.deleteKnowledgeSourceFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    addWebsite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KnowledgeSourceActions.addWebsite),
            mergeMap(action =>
                this.knowledgeBaseService.addWebsite(action.url).pipe(
                    map(knowledgeSource => KnowledgeSourceActions.addWebsiteSuccess({ knowledgeSource })),
                    catchError((error: HttpErrorResponse) => {
                        this.toast.error(`Website could not be added. Error code: ${error.status}.`);
                        return of(KnowledgeSourceActions.addWebsiteFailure({ error }));
                    }),
                ),
            ),
        ),
    );
}
