import { Directive, EventEmitter, HostListener, inject, Input, Output, Type } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, Subscription } from 'rxjs';
import {
    ConfirmedCancelDialogComponent,
} from '../../components/confirmed-click/confirmed-cancel-dialog/confirmed-cancel-dialog.component';
import {
    ConfirmedDeleteDialogComponent,
} from '../../components/confirmed-click/confirmed-delete-dialog/confirmed-delete-dialog.component';
import { ModalService } from '../../modal/modal.service';
import { ConfirmedClickComponent } from './confirmed-click-component';

class AppEventEmitter<T> extends EventEmitter {
    originalFn?: (value: any) => void

    override subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription {
        this.originalFn = next;
        return super.subscribe(next, error, complete);
    }
}

@UntilDestroy()
@Directive({
    selector: '[confirmedClick]',
    standalone: true,
})
export class ConfirmedClickDirective {
    private modalService = inject(ModalService);

    @Output() confirmedClick = new AppEventEmitter<any>();

    @Input() dialogType: 'ConfirmedDelete' | 'ConfirmedCancel' = 'ConfirmedDelete';
    @Input() dialogTitle?: string;
    @Input() dialogBody?: string;
    @Input() dialogCancelBtn?: string;
    @Input() dialogConfirmBtn?: string;
    @Input() dialogParams: any;
    @Input() backdrop: boolean = true;
    @Input() centered: boolean = true;

    @HostListener('click')
    onClick() {
        let type!: Type<ConfirmedClickComponent>;
        switch (this.dialogType) {
            case 'ConfirmedDelete':
                type = ConfirmedDeleteDialogComponent;
                break;
            case 'ConfirmedCancel':
                type = ConfirmedCancelDialogComponent;
                break;
            default:
                type = ConfirmedDeleteDialogComponent;
                break;
            // case 'ConfirmedSaveWithLabel':
            //   type = ConfirmedSaveWithLabelDialogComponent;
            //   break;
        }


        const dialogRef = this.modalService.open(type, {
            backdrop: this.backdrop,
            centered: this.centered,
        });

        if (this.dialogTitle) {
            dialogRef.contentInstance.title = this.dialogTitle;
        }

        if (this.dialogBody) {
            dialogRef.contentInstance.body = this.dialogBody;
        }

        if (this.dialogCancelBtn) {
            dialogRef.contentInstance.cancelBtn = this.dialogCancelBtn;
        }

        if (this.dialogConfirmBtn) {
            dialogRef.contentInstance.confirmBtn = this.dialogConfirmBtn;
        }

        if (this.dialogParams) {
            dialogRef.contentInstance.params = this.dialogParams;
        }

        if (this.backdrop) {
            dialogRef.contentInstance.backdrop = this.backdrop;
        }

        dialogRef.onClose
            .pipe(filter(result => !!result))
            .subscribe(result => this.confirmedClick.originalFn ? this.confirmedClick.originalFn(result) : null);
    }

}
