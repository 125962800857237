import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { InviteMemberDto } from '../interfaces/invite-member.interface';

import { Member } from './member.model';

export const MemberActions = createActionGroup({
    source: 'Member/API',
    events: {
        'Load Members': emptyProps(),
        'Load Members Success': props<{members: Member[]}>(),
        'Load Members Failure': props<{error: any}>(),

        'Invite Member': props<{member: InviteMemberDto}>(),
        'Invite Member Success': props<{member: Member}>(),
        'Invite Member Failure': props<{error: any}>(),

        'Clear Members': emptyProps(),
    },
});
