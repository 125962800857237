import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Tenant } from '../tenant.interface';


export const TenantActions = createActionGroup({
    source: 'Tenants/API',
    events: {
        'Load User Tenants': emptyProps,
        'Load User Tenants Success': props<{tenants: Tenant[]}>(),
    },
});
