import { TextFieldModule } from '@angular/cdk/text-field';
import {
    ChangeDetectionStrategy,
    Component,
    effect,
    EventEmitter,
    forwardRef,
    inject,
    input,
    Output,
    signal,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    NonNullableFormBuilder,
    ReactiveFormsModule,
} from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TippyDirective } from '@ngneat/helipopper';
import { NgMathPipesModule } from 'ngx-pipes';
import { finalize } from 'rxjs';
import { PromptLibrary } from '../../../prompt-library/+store/prompt-library.model';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalService } from '../../../shared/modal/modal.service';
import { ChatService } from '../../chat.service';
import { ChatInputFileUploadComponent } from '../chat-input-file-upload/chat-input-file-upload.component';

@Component({
    selector: 'app-chat-input-field',
    template: `
        <div class="flex flex-wrap space-x-3">
            @for (file of form.controls.uploadedFiles.value; track $index) {
                <div class="border border-neutral-200 rounded-lg p-2 flex items-center mb-3">
                    <div class="text-red-900">
                        <fa-icon size="xl" [icon]="['fad', 'file-pdf']"></fa-icon>
                    </div>
                    <div class="ml-3">
                        <div class="font-medium text-sm max-w-prose truncate">{{ file.name }}</div>
                        <div class="text-xs">{{ file.size | bytes: 2 }}</div>
                    </div>
                    <div class="ml-3">
                        <app-button
                            color="transparent"
                            size="xs"
                            (click)="form.controls.uploadedFiles.removeAt($index)">
                            <fa-icon [icon]="['fas', 'times']"></fa-icon>
                        </app-button>
                    </div>
                </div>
            }
        </div>
        <div>
            <div class="bg-stone-100 rounded-xl">
                <form
                    class="flex flex-col rounded-md w-full relative pl-3 pb-3"
                    (ngSubmit)="submit()"
                    [formGroup]="form">
                    <div class="flex w-full">
                        <textarea
                            class="textarea placeholder:text-stone-500 bg-stone-100 text-stone-900 border-slate-200 w-full py-3 outline-none pr-[90px]"
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="15"
                            placeholder="How can I help you?"
                            formControlName="text"
                            (keyup)="onKeyup($event)"
                            (keydown)="onKeydown($event)"></textarea>

                        <div class="flex translate-y-[10px] pr-2">
                            <app-chat-input-file-upload formArrayName="uploadedFiles"></app-chat-input-file-upload>

                            <app-button
                                color="transparent"
                                size="sm"
                                tp="Improve Prompt"
                                type="button"
                                (click)="enhancePrompt()">
                                <fa-icon
                                    [animation]="loadingEnhancedPrompt() ? 'beat-fade' : undefined"
                                    [class.text-yellow-500]="loadingEnhancedPrompt()"
                                    class="text-slate-400 group-hover/button:text-yellow-500"
                                    [icon]="['fad', 'stars']"></fa-icon>
                            </app-button>

                            <app-button color="transparent" size="sm" type="submit">
                                <fa-icon class="text-blue-700" [icon]="['fad', 'paper-plane-top']"></fa-icon>
                            </app-button>
                        </div>
                    </div>

                    <div>
                        <app-button
                            (click)="toggleKnowledgeBase()"
                            size="sm"
                            color="blue"
                            [outline]="!form.getRawValue().knowledgeBase">
                            <fa-icon
                                [icon]="[form.getRawValue().knowledgeBase ? 'fas' : 'fal', 'graduation-cap']"
                                size="xs"
                                class="mr-1"></fa-icon>
                            Use Knowledge Base
                        </app-button>
                    </div>
                </form>
            </div>
        </div>
    `,
    styleUrls: ['./chat-input-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ReactiveFormsModule,
        TextFieldModule,
        ButtonComponent,
        TippyDirective,
        FaIconComponent,
        ChatInputFileUploadComponent,
        NgMathPipesModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ChatInputFieldComponent),
        },
    ],
})
export class ChatInputFieldComponent implements ControlValueAccessor {
    modalService = inject(ModalService);
    fb = inject(NonNullableFormBuilder);
    chatService = inject(ChatService);

    initialText = input<PromptLibrary>();

    changeFn?: (v: any) => void;
    touchFn?: () => void;
    disabled = signal(false);

    @Output('onSubmit') $submit = new EventEmitter<{
        text: string;
        knowledgeBase: boolean;
        uploadedFiles: File[];
    }>();

    form = this.fb.group({
        text: [''],
        knowledgeBase: [false],
        uploadedFiles: this.fb.array<FormControl<File>>([]),
    });
    loadingEnhancedPrompt = signal(false);

    constructor() {
        effect(() => {
            if (this.initialText() && !this.form.getRawValue().text) {
                this.form.patchValue({ text: this.initialText()?.prompt });
            }
        });
    }

    submit() {
        if (this.form.valid) {
            this.$submit.emit(this.form.getRawValue());
            this.form.controls.uploadedFiles.clear();
            this.form.reset();
        }
    }

    enhancePrompt() {
        const values = this.form.getRawValue();

        if (values.text) {
            this.loadingEnhancedPrompt.set(true);
            this.chatService
                .enhancePrompt(values.text)
                .pipe(finalize(() => this.loadingEnhancedPrompt.set(false)))
                .subscribe(response => {
                    this.form.patchValue(response, { emitEvent: false });
                });
        }
    }

    onKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter' && !event.shiftKey && !event.altKey) {
            event.preventDefault();
            this.submit();
        }
    }

    onKeyup(event: KeyboardEvent) {
        if (this.changeFn) {
            const value = this.form.getRawValue();
            this.changeFn(value.text);
        }
    }

    writeValue(text: string): void {
        this.form.patchValue({ text });
    }

    registerOnChange(fn: (v: any) => void): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled.set(isDisabled);
    }

    toggleKnowledgeBase() {
        this.form.patchValue({ knowledgeBase: !this.form.getRawValue().knowledgeBase });
    }
}
