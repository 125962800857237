import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ConfirmedClickComponent } from '../../../directives/confirmed-click/confirmed-click-component';
import {
    ModalContentContainerComponent,
} from '../../../modal/modal-content-container/modal-content-container.component';
import { ButtonComponent } from '../../button/button.component';

@Component({
    selector: 'app-confirmed-delete-dialog',
    template: `
        <div class="modal">
            <div class="modal-title p-4 flex">
                <h3 class="font-bold">{{ title }}</h3>
                <button type="button" class="ml-auto" (click)="modal.close.emit(false)">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>
                </button>
            </div>
            <div class="modal-body p-4">
                {{ body }}
            </div>
            <div class="modal-footer p-4">
                <div class="flex justify-end">
                    <app-button class="mr-2" (click)="modal.close.emit(false)" type="button" color="transparent">
                        {{ cancelBtn }}
                    </app-button>
                    <app-button color="red" (click)="modal.close.emit(true)" type="button">
                        {{ confirmBtn }}
                    </app-button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./confirmed-delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FaIconComponent, ButtonComponent],
})
export class ConfirmedDeleteDialogComponent implements OnInit, ConfirmedClickComponent {
    modal = inject(ModalContentContainerComponent);

    @Input() title = 'Are you sure?';
    @Input() body = 'Deleting removes this entry permanently and cannot be undone.';
    @Input() cancelBtn = 'Cancel';
    @Input() confirmBtn = 'Delete';
    @Input() params: any;
    @Input() backdrop = true;

    ngOnInit(): void {
    }

}
