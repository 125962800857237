import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';
import { groupBy } from 'remeda';
import { Chat } from './chat.model';
import { chatsFeature, chatsFeatureKey, ChatState } from './chat.reducer';

export const selectChatState = createFeatureSelector<ChatState>(chatsFeatureKey);

export const {
    selectIds: selectChatIds,
    selectEntities: selectChatEntities,
    selectAll: selectAllChats,
    selectTotal: selectTotalChats,
    selectLoading: selectChatLoading,
} = chatsFeature;

export const selectChatById = (id: string) => createSelector(selectChatEntities, entities => entities[id]);

export const selectChatByProjectId = (projectId: string) =>
    createSelector(selectAllChats, chats => chats.filter(chat => chat.projectId === projectId));

export const selectChatsSortedByCreation = () =>
    createSelector(selectAllChats, chats =>
        chats.sort((a, b) => {
            const t1 = DateTime.fromISO(a.createdAt);
            const t2 = DateTime.fromISO(b.createdAt);
            return t1 < t2 ? 1 : t1 > t2 ? -1 : 0;
        }),
    );

export const selectChatsGroupedByDate = () =>
    createSelector(selectChatsSortedByCreation(), chats => {
        return groupBy<Chat>(chats, chat => DateTime.fromISO(chat.createdAt).startOf('day').toISO() || 'unknown');
    });

export const selectRecentPrivateChats = createSelector(selectChatsSortedByCreation(), chats =>
    chats.filter(chat => chat.private),
);
export const selectRecentPublicChats = createSelector(selectChatsSortedByCreation(), chats =>
    chats.filter(chat => !chat.private),
);
// export const selectTotalPrivateChats = createSelector(selectChatState, state => state.privateChatCount);
// export const selectTotalPublicChats = createSelector(selectChatState, state => state.publicChatCount);
// export const selectLastPrivateChatCursor = createSelector(selectRecentPrivateChats, chats =>
//     chats.length > 0 ? chats[chats.length - 1].updatedAt : '',
// );
// export const selectLastPublicChatCursor = createSelector(selectRecentPublicChats, chats =>
//     chats.length > 0 ? chats[chats.length - 1].updatedAt : '',
// );
// export const selectPublicChats = () =>
//     createSelector(selectChatsSortedByCreation(), chats => chats.filter(chat => !chat.private));
// export const selectPrivateChats = () =>
//     createSelector(selectChatsSortedByCreation(), chats => chats.filter(chat => chat.private));
