import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { KnowledgeSourceActions } from '../../+store/knowledge-source.actions';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalBodyComponent } from '../../../shared/modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../shared/modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../shared/modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';

@Component({
    selector: 'app-add-website-modal',
    imports: [
        ModalComponent,
        ModalTitleComponent,
        ModalBodyComponent,
        ReactiveFormsModule,
        ButtonComponent,
        ModalFooterComponent,
        FaIconComponent,
    ],
    template: `
        <form [formGroup]="form" (ngSubmit)="submit()">
            <app-modal size="sm">
                <app-modal-title [closeButton]="false">Add web page</app-modal-title>
                <app-modal-body>
                    <input
                        class="form-input"
                        type="text"
                        formControlName="url"
                        placeholder="https://en.wikipedia.org/wiki/owl" />
                    @if (form.controls.url.dirty && form.controls.url.errors?.['pattern']) {
                        <div class="form-error">Please enter a valid url starting with https://</div>
                    }
                </app-modal-body>
                <app-modal-footer>
                    <div class="flex space-x-2">
                        <app-button color="transparent" type="button" (click)="close()">Cancel</app-button>
                        <app-button type="submit" [disabled]="isSaving()">
                            @if (isSaving()) {
                                <fa-icon [icon]="['fas', 'spinner-scale']" animation="spin"></fa-icon>
                            } @else {
                                Save
                            }
                        </app-button>
                    </div>
                </app-modal-footer>
            </app-modal>
        </form>
    `,
    styleUrl: './add-website-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddWebsiteModalComponent {
    fb = inject(FormBuilder);
    store = inject(Store);
    actions = inject(Actions);
    destroyRef = inject(DestroyRef);
    modal = inject(ModalContentContainerComponent);
    isSaving = signal(false);

    form = this.fb.nonNullable.group({
        url: [
            '',
            [
                Validators.required,
                Validators.pattern(
                    /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                ),
            ],
        ],
    });

    submit() {
        if (this.form.valid) {
            this.isSaving.set(true);
            this.store.dispatch(KnowledgeSourceActions.addWebsite({ url: this.form.getRawValue().url }));

            this.actions
                .pipe(
                    ofType(KnowledgeSourceActions.addWebsiteSuccess, KnowledgeSourceActions.addWebsiteFailure),
                    take(1),
                    takeUntilDestroyed(this.destroyRef),
                )
                .subscribe(action => {
                    this.isSaving.set(false);

                    if (action.type === KnowledgeSourceActions.addWebsiteSuccess.type) {
                        this.modal.onClose();
                    }
                });
        }
    }

    close() {
        this.modal.onDismiss();
    }
}
