import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { ForgotPasswordComponent } from './auth/pages/forgot-password/forgot-password.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { PasswordResetComponent } from './auth/pages/password-reset/password-reset.component';
import { SignupSuccessComponent } from './auth/pages/signup-success/signup-success.component';
import { SignupComponent } from './auth/pages/signup/signup.component';
import { ChatCreateComponent } from './chat/pages/chat-create/chat-create.component';
import { ChatDetailComponent } from './chat/pages/chat-detail/chat-detail.component';
import { ChatIndexComponent } from './chat/pages/chat-index/chat-index.component';
import { KnowledgeSourceIndexComponent } from './knowledge-base/pages/knowledge-source-index/knowledge-source-index.component';
import { SettingIndexComponent } from './setting/setting-index/setting-index.component';
import { SettingMembersComponent } from './setting/setting-members/setting-members.component';
import { SettingPaymentComponent } from './setting/setting-payment/setting-payment.component';
import { SettingProfileComponent } from './setting/setting-profile/setting-profile.component';
import { UserProfileComponent } from './user/components/user-profile/user-profile.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/chat',
            },
            {
                path: 'chat',
                component: ChatIndexComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/chat/create',
                    },
                    {
                        path: 'create',
                        component: ChatCreateComponent,
                    },
                    {
                        path: ':id',
                        component: ChatDetailComponent,
                    },
                ],
            },
            {
                path: 'settings',
                component: SettingIndexComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/settings/profile',
                    },
                    {
                        path: 'payment',
                        component: SettingPaymentComponent,
                    },
                    {
                        path: 'profile',
                        component: SettingProfileComponent,
                    },
                    {
                        path: 'members',
                        component: SettingMembersComponent,
                    },
                ],
            },
            {
                path: 'knowledge-base',
                component: KnowledgeSourceIndexComponent,
            },
            {
                path: 'profile',
                component: UserProfileComponent,
            },
            {
                path: 'workflow',
                loadChildren: () => import('./workflow/workflow.routes').then(r => r.workflowRoutes),
            },
            {
                path: 'brand-voice',
                loadChildren: () =>
                    import('./brand-voice/brand-voice-routing.module').then(m => m.BrandVoiceRoutingModule),
            },
            {
                path: 'prompt-library',
                loadChildren: () => import('./prompt-library/prompt-library.routes').then(r => r.promptLibraryRoutes),
            },
        ],
    },
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'accept-invitation/:token',
                component: PasswordResetComponent,
            },
        ],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'signup',
        component: SignupComponent,
    },
    {
        path: 'signup-success',
        component: SignupSuccessComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'complete-signup/:token',
        component: PasswordResetComponent,
    },
    {
        path: 'reset-password/:token',
        component: PasswordResetComponent,
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            bindToComponentInputs: true,
            paramsInheritanceStrategy: 'always',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
