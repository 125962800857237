import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AuthActions } from '../../+store/auth.actions';
import { selectAuthError, selectAuthLoading } from '../../+store/auth.selectors';
import { AppState } from '../../../reducers';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { AuthWrapperComponent } from '../../components/auth-wrapper/auth-wrapper.component';

@Component({
    selector: 'app-signup',
    template: `
        <app-auth-wrapper>
            <div class="w-full">
                <div class="flex mb-8">
                    <img src="/logo/logo.svg" width="150" alt="Logo" />
                </div>

                <h2 class="text-lg font-semibold text-slate-800 mb-4">Create an Account</h2>

                <form [formGroup]="signupForm" (ngSubmit)="signup()">
                    <div class="grid grid-cols-2 gap-6">
                        <div class="form-group mb-2">
                            <label class="block text-slate-600 mb-1" for="username"> First name </label>
                            <input
                                class="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="firstname"
                                type="text"
                                placeholder="First name"
                                formControlName="firstname" />
                        </div>
                        <div class="form-group mb-2">
                            <label class="block text-slate-600 mb-1" for="username"> Last name </label>
                            <input
                                class="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="lastname"
                                type="text"
                                placeholder="Last name"
                                formControlName="lastname" />
                        </div>
                    </div>
                    <div class="form-group mb-2">
                        <label class="block text-slate-600 mb-1" for="username"> E-Mail </label>
                        <input
                            class="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="text"
                            placeholder="E-Mail Address"
                            formControlName="email" />
                    </div>

                    @if (!!(error$ | async)) {
                        <div class="text-red-500 text-xs italic mt-3 mb-6">
                            {{ (error$ | async).error.message }}
                        </div>
                    }

                    <p class="mt-6 text-sm">
                        By clicking <span class="font-medium">Sign Up</span>, you are agreeing to our
                        <a
                            href="https://owlatwork.com/terms-and-conditions"
                            class="font-semibold underline cursor-pointer"
                            target="_blank"
                            >Terms and Conditions</a
                        >
                        and acknowledge that you have read our
                        <a
                            href="https://owlatwork.com/privacy-policy"
                            class="font-semibold underline cursor-pointer"
                            target="_blank"
                            >Privacy Policy</a
                        >.
                    </p>

                    <div class="flex justify-center mt-6">
                        <app-button
                            id="submit"
                            type="submit"
                            [color]="'primary'"
                            [fullWidth]="true"
                            rounded="rounded-lg">
                            <div class="py-1">Sign Up</div>
                        </app-button>
                    </div>
                    <div class="mt-4 text-sm">
                        <p>
                            You already have an account?
                            <a [routerLink]="['/auth/login']" class="font-semibold underline cursor-pointer"
                                >Go to Login</a
                            >
                        </p>
                    </div>
                </form>
            </div>
        </app-auth-wrapper>
    `,
    styleUrls: ['./signup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AuthWrapperComponent, ReactiveFormsModule, ButtonComponent, RouterLink, AsyncPipe],
})
export class SignupComponent {
    private store = inject<Store<AppState>>(Store);
    private router = inject(Router);

    signupForm = new FormGroup({
        email: new FormControl('', [Validators.email]),
        firstname: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required]),
    });
    loading$: Observable<boolean> = of(false);
    error$: Observable<any> = of(null);

    ngOnInit() {
        this.loading$ = this.store.select(selectAuthLoading);
        this.error$ = this.store.select(selectAuthError);
    }

    async signup() {
        if (!this.signupForm.valid) {
            return;
        }

        this.store.dispatch(
            AuthActions.signup({
                firstname: this.signupForm.value.firstname as string,
                lastname: this.signupForm.value.lastname as string,
                email: this.signupForm.value.email as string,
            }),
        );
    }
}
