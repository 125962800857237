import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UploadCollection } from './+store/folder/upload-collection.model';

@Injectable({
    providedIn: 'root',
})
export class UploadCollectionService {
    http = inject(HttpClient);

    createUploadCollection(name: string) {
        return this.http.post<UploadCollection>(`${environment.apiUrl}/upload-collection`, { name });
    }

    loadUploadCollections() {
        return this.http.get<UploadCollection[]>(`${environment.apiUrl}/upload-collection`);
    }

    updateUploadCollection(payload: {id: string, name: string}) {
        return this.http.patch<UploadCollection>(`${environment.apiUrl}/upload-collection/${payload.id}`, {
            id: payload.id,
            name: payload.name,
        })
    }

    deleteUpload(id: string) {
        return this.http.delete<UploadCollection>(`${environment.apiUrl}/upload-collection/${id}`);
    }
}
