import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-chat-message-header',
    imports: [FaIconComponent],
    template: `
        <div class="mb-2 flex">
            <fa-icon [icon]="['far', 'feather']" size="lg"></fa-icon>
            <div class="ml-3 text-stone-700 text-sm animate-pulse">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrl: './chat-message-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageHeaderComponent {}
