import { createActionGroup, props } from '@ngrx/store';
import { Embedding } from './embedding.model';

export const EmbeddingActions = createActionGroup({
    source: 'Embedding',
    events: {
        'Load Embeddings For Message': props<{ids: string[]}>(),
        'Load Embeddings Success': props<{ embeddings: Embedding[] }>(),
        'Load Embeddings Error': props<{ err: any }>(),
    }
});
