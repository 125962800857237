import { createSelector } from '@ngrx/store';
import { uploadCollectionsFeature } from './upload-collection.reducer';

export const {
    selectIds: selectUploadCollectionIds,
    selectEntities: selectUploadCollectionEntities,
    selectAll: selectUploadCollectionAll,
    selectTotal: selectUploadCollectionTotal,
    selectLoading: selectUploadCollectionLoading,
} = uploadCollectionsFeature;

export const selectUploadCollectionById = (id: string) => createSelector(selectUploadCollectionEntities, entities => entities[id]);
