import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MainWrapperComponent } from '../../shared/components/main-wrapper/main-wrapper.component';

@Component({
    selector: 'app-setting-index',
    template: `
        <app-main-wrapper>
            <div class="w-full">
                <div class="w-full border-b px-12 mt-12 border-collapse">
                    <a [routerLink]="['/settings/profile']" routerLinkActive="active" class="tab-item">Profile</a>
                    <a [routerLink]="['/settings/payment']" routerLinkActive="active" class="tab-item">Payment</a>
                    <a [routerLink]="['/settings/members']" routerLinkActive="active" class="tab-item">Members</a>
                </div>

                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </app-main-wrapper>
    `,
    styleUrls: ['./setting-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MainWrapperComponent, RouterLinkActive, RouterLink, RouterOutlet],
})
export class SettingIndexComponent {}
