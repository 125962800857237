import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadCollectionService } from '../../upload-collection.service';
import { UploadCollectionActions } from './upload-collection.actions';

@Injectable()
export class UploadCollectionEffects {
    private uploadCollectionService = inject(UploadCollectionService);
    private toast = inject(HotToastService);

    actions$ = inject(Actions);
    folderService = inject(UploadCollectionService);
    router = inject(Router);

    createUploadCollection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadCollectionActions.createUploadCollection),
            exhaustMap(action =>
                this.folderService.createUploadCollection(action.name).pipe(
                    map(uploadCollection =>
                        UploadCollectionActions.createUploadCollectionSuccess({ uploadCollection }),
                    ),
                    catchError(error => of(UploadCollectionActions.createUploadCollectionFailure({ error }))),
                ),
            ),
        ),
    );

    loadFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadCollectionActions.loadUploadCollections),
            switchMap(action =>
                this.folderService
                    .loadUploadCollections()
                    .pipe(
                        map(uploadCollections =>
                            UploadCollectionActions.loadUploadCollectionsSuccess({ uploadCollections }),
                        ),
                    ),
            ),
        ),
    );

    updateUploadCollection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadCollectionActions.updateUploadCollection),
            exhaustMap(action =>
                this.uploadCollectionService.updateUploadCollection({ id: action.id, name: action.name }).pipe(
                    tap(() => {
                        this.toast.success('Collection successfully changed.');
                    }),
                    map(uploadCollection =>
                        UploadCollectionActions.updateUploadCollectionSuccess({ uploadCollection }),
                    ),
                    catchError(error => {
                        this.toast.error(error.error.message);
                        return of(UploadCollectionActions.updateUploadCollectionFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    deleteUploadCollection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadCollectionActions.deleteUploadCollection),
            switchMap(action =>
                this.uploadCollectionService.deleteUpload(action.id).pipe(
                    map(uploadCollection => UploadCollectionActions.deleteUploadCollectionSuccess({ id: action.id })),
                    tap(() => this.router.navigate(['/knowledge-base'])),
                    catchError(error => of(UploadCollectionActions.deleteUploadCollectionFailure({ error }))),
                ),
            ),
        ),
    );
}
