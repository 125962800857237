import { inject, Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { MemberService } from '../member.service';
import { MemberActions } from './member.actions';


@Injectable()
export class MemberEffects {
    private actions$ = inject(Actions);
    private memberService = inject(MemberService);
    private toast = inject(HotToastService);

    loadAll$ = createEffect(() => this.actions$.pipe(
        ofType(MemberActions.loadMembers),
        exhaustMap(() => this.memberService.loadAll().pipe(
            map(members => MemberActions.loadMembersSuccess({ members })),
        )),
    ));

    inviteMember$ = createEffect(() => this.actions$.pipe(
        ofType(MemberActions.inviteMember),
        exhaustMap(action => this.memberService.inviteMember(action.member).pipe(
            map(member => MemberActions.inviteMemberSuccess({ member })),
            tap(() => this.toast.success('Team member successfully invited.')),
            catchError(error => {
                this.toast.error('Team member could not be invited. Please try again later or contact customer support.');
                return of(MemberActions.inviteMemberFailure({ error }));
            }),
        )),
    ));
}
