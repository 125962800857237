import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
    fromAuth.authFeatureKey,
);


export const selectAccessToken = createSelector(selectAuthState, state => state.accessToken)
export const selectAuthLoading = createSelector(selectAuthState, state => state.isLoading)
export const selectAuthError = createSelector(selectAuthState, state => state?.error)
export const selectAuthEmail = createSelector(selectAuthState, state => state?.email)
