import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { Observable, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatActions } from '../../+store/chat.actions';
import { Chat } from '../../+store/chat.model';
import { selectChatById } from '../../+store/chat.selectors';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { PerfectScrollbarDirective } from '../../../shared/directives/perfect-scrollbar/perfect-scrollbar.directive';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { ChatService } from '../../chat.service';
import { ChatInputFieldComponent } from '../../components/chat-input-field/chat-input-field.component';
import { ChatMessageComponent } from '../../components/chat-message/chat-message.component';
import { ChatTopBarComponent } from '../../components/chat-top-bar/chat-top-bar.component';

@Component({
    selector: 'app-chat-detail',
    template: `
        <app-main-wrapper>
            <form [formGroup]="form" class="flex flex-col  h-full w-full">
                <app-chat-top-bar class="max-w-[904px]"></app-chat-top-bar>

                <div class="w-full grow overflow-auto mx-12 max-w-[904px]" #scrollArea scrollbar>
                    @for (message of (chat$ | async)?.messages; track message) {
                        @switch (message.type) {
                            @default {
                                <app-chat-message [message]="message"></app-chat-message>
                            }
                        }
                    }

                    @switch ((chat$ | async)?.status) {
                        @case ('PROCESSING_FILES') {
                            <div class="py-2 text-center">
                                <fa-icon
                                    class="text-stone-700"
                                    size="xl"
                                    [icon]="['fad', 'spinner-third']"
                                    animation="spin"></fa-icon>
                                <span class="ml-2">Files getting analyzed...</span>
                            </div>
                        }
                    }
                </div>

                <div class="mt-auto mb-5 pt-5 w-full max-w-[1000px] px-12">
                    <app-chat-input-field (onSubmit)="submit($event)"></app-chat-input-field>
                </div>
            </form>
        </app-main-wrapper>
    `,
    styleUrls: ['./chat-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MainWrapperComponent,
        ReactiveFormsModule,
        ChatMessageComponent,
        ChatInputFieldComponent,
        AsyncPipe,
        ChatTopBarComponent,
        FaIconComponent,
        PerfectScrollbarDirective,
    ],
})
export class ChatDetailComponent implements OnDestroy {
    private store = inject(Store);
    private route = inject(ActivatedRoute);
    private chatService = inject(ChatService);
    private fb = inject(FormBuilder);

    @ViewChild('scrollArea', { static: true }) scrollArea!: ElementRef<HTMLDivElement>;

    chat$!: Observable<Chat>;
    chatId?: string;
    form!: FormGroup;

    ngOnDestroy() {
        if (this.chatId) {
            this.chatService.unsubscribe(this.chatId);
        }
    }

    constructor() {
        this.form = this.fb.nonNullable.group({
            model: ['gpt-4o', Validators.required],
            private: [false],
            settings: [{ temperature: 0.8, documentSearch: false }],
        });

        this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(values => {
            this.store.dispatch(ChatActions.updateChat({ chat: { id: this.chatId, ...values } }));
        });

        const route$ = this.route.paramMap.pipe(
            takeUntilDestroyed(),
            map(params => params.get('id')),
            filterNullish(),
        );

        route$.subscribe(id => {
            this.chatId = id;
            this.chatService.subscribe(id);
            this.store.dispatch(ChatActions.loadChatById({ id }));
        });

        this.chat$ = route$.pipe(
            switchMap(id => this.store.select(selectChatById(id))),
            filterNullish(),
            shareReplay(1),
        );
        this.chat$.pipe(takeUntilDestroyed()).subscribe(chat => {
            this.scrollArea.nativeElement.scrollTo({
                top: this.scrollArea.nativeElement.scrollHeight,
                behavior: 'smooth',
            });
            this.form.patchValue(chat, { emitEvent: false });
        });
    }

    submit(message: { text: string; knowledgeBase: boolean; uploadedFiles: File[] }): void {
        if (this.chatId) {
            this.store.dispatch(
                ChatActions.sendChatMessage({
                    text: message.text,
                    chatId: this.chatId,
                    knowledgeBase: message.knowledgeBase,
                    uploadedFiles: message.uploadedFiles,
                }),
            );
        }
    }
}
