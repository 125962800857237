import { createSelector } from '@ngrx/store';
import { uploadsFeature } from './upload.reducer';

export const {
    selectIds: selectUploadIds,
    selectEntities: selectUploadEntities,
    selectAll: selectAllUploads,
    selectTotal: selectTotalUploads,
} = uploadsFeature;

export const selectUploadById = (id: string) =>
    createSelector(selectAllUploads, uploads => uploads.find(upload => upload.id === id));
export const selectUploadByCollectionId = (id: string) =>
    createSelector(selectAllUploads, uploads => uploads.filter(upload => upload.uploadCollectionId === id));
