import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPencil } from '@fortawesome/pro-duotone-svg-icons';
import { faPenField, faStarCircle, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { WorkflowActions } from '../../+store/workflow/workflow.actions';
import { Workflow } from '../../+store/workflow/workflow.model';
import { FavoriteCheckerComponent } from '../../../shared/components/favorite-checker/favorite-checker.component';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-workflow-tile',
    template: `
        @if (workflow(); as workflow) {
          <div
            class="bg-white group/workflow-tile hover:bg-blue-50 shadow-md rounded-lg shadow-neutral-200 p-6 w-full flex relative isolate">
            <!--                @if (favoritable()) {-->
<!--                    <app-favorite-checker class="absolute z-20 top-0 left-0 -translate-x-1/2 -translate-y-1/2" [ngClass]="{-->
<!--                       'hidden group-hover/workflow-tile:inline-block': !workflow.favorite-->
<!--                    }" [selected]="workflow.favorite" (toggle)="toggleFavorite()"></app-favorite-checker>-->
<!--                }-->
                <a [routerLink]="['/workflow', workflow.id]" fragment="outputs" class="absolute inset-0 z-10">

                </a>
                <div class="flex flex-col w-full">
                    <div class="flex justify-between max-w-full">
                        <div
                            class="font-semibold text-lg text-slate-700 overflow-ellipsis overflow-hidden">{{ workflow.label }}
              </div>
              @if (isEditable()) {
                <div class="flex items-center gap-2 ml-2 z-20 -mt-4 -mr-4">
                  <a [routerLink]="['/workflow/edit', workflow.id]"
                    [ngClass]="{'group-hover/workflow-tile:visible invisible bg-white': !workflow.draft, 'bg-orange-100': workflow.draft}"
                    class="invisible rounded-md hover:bg-sky-600 hover:text-white shadow-md w-10 h-10 inline-flex items-center justify-center flex-shrink-0">
                    <fa-icon [icon]="workflow.draft ? faPenField : faPencil"></fa-icon>
                  </a>
                  <button (click)="deleteWorkflow($event)"
                    type="button"
                    class="group-hover/workflow-tile:visible invisible rounded-md bg-white hover:bg-orange-500 hover:text-white shadow-md w-10 h-10 inline-flex items-center justify-center flex-shrink-0">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              }
            </div>
            <div class="text-sm text-slate-500 mt-2 mb-5">{{ workflow.description }}</div>
            <div class="flex mt-auto items-end w-full">
              <div class="flex flex-wrap">
                @for (category of workflow.categories; track category) {
                  <div class="bg-sky-100 rounded-full text-xs py-2 px-4 whitespace-nowrap mr-3 mt-3"
                    >
                    {{ category }}
                  </div>
                }
              </div>
              @if (workflow.image) {
                <img class="ml-auto" [ngSrc]="workflow.image" [width]="50" [height]="50"
                  [alt]="workflow.label">
              }
            </div>
          </div>
        </div>
        }
        `,
    styleUrl: './workflow-tile.component.scss',
    standalone: true,
    imports: [
        NgOptimizedImage,
        RouterLink,
        FaIconComponent,
        FavoriteCheckerComponent,
        NgClass,
        SharedModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents'
    }
})
export class WorkflowTileComponent {
    store = inject(Store);

    workflow = input.required<Workflow>();
    editable = input<boolean>(false);

    isEditable = computed(() => {
        const workflow = this.workflow();
        return (workflow.userId || workflow.tenantId) && this.editable();
    })

    toggleFavorite(){
        this.store.dispatch(WorkflowActions.toggleWorkflowFavorite({id: this.workflow().id}));
    }

    deleteWorkflow(e: Event) {
        e.stopPropagation();
        this.store.dispatch(WorkflowActions.deleteWorkflow({id: this.workflow().id}));
    }

    protected readonly faPencil = faPencil;
    protected readonly faStarCircle = faStarCircle;
    protected readonly faTrash = faTrash;
    protected readonly faPenField = faPenField;
}
