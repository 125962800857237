import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalBodyComponent } from '../../../shared/modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../shared/modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../shared/modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';
import { UploadCollectionActions } from '../../../upload/+store/folder/upload-collection.actions';

@Component({
    selector: 'app-create-collection-modal',
    template: `
        <form [formGroup]="form" (ngSubmit)="submit()">
            <app-modal size="sm">
                <app-modal-title>
                    <h3 class="font-bold">Create new Collection</h3>
                </app-modal-title>
                <app-modal-body>
                    <label for="name" class="form-label">Collection Name</label>
                    <input type="text" formControlName="name" id="name" class="form-input" />
                </app-modal-body>
                <app-modal-footer>
                    <div class="flex justify-end">
                        <app-button color="transparent" type="button" class="mr-2" (click)="modal.onDismiss()"
                            >Cancel
                        </app-button>
                        <app-button color="primary" type="submit">Save</app-button>
                    </div>
                </app-modal-footer>
            </app-modal>
        </form>
    `,
    styleUrl: './create-collection-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ReactiveFormsModule,
        ModalComponent,
        ModalTitleComponent,
        ModalBodyComponent,
        ModalFooterComponent,
        ButtonComponent,
    ],
})
export class CreateCollectionModalComponent {
    fb = inject(FormBuilder);
    form = this.fb.nonNullable.group({
        name: ['', Validators.required],
    });
    store = inject(Store);
    modal = inject(ModalContentContainerComponent);

    submit() {
        if (this.form.valid) {
            this.store.dispatch(
                UploadCollectionActions.createUploadCollection({
                    name: this.form.getRawValue().name,
                }),
            );

            this.modal.onClose();
        }
    }
}
