import { ChangeDetectionStrategy, Component, forwardRef, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { v4 } from 'uuid';

@Component({
    selector: 'app-round-checkbox',
    template: `
        <input class="hidden peer" [id]="id" type="checkbox" [checked]="checked()" (change)="onChange($event)" />
        <label
            [for]="id"
            class="border border-slate-200 hover:border-sky-200 rounded-full aspect-square w-[25px] flex justify-center items-center group hover:bg-sky-200 cursor-pointer overflow-hidden peer-checked:bg-sky-500 peer-checked:border-sky-500">
            <fa-icon
                class="mt-0.5 text-transparent group-hover:text-white peer-checked:group-[]:text-white"
                [icon]="['fas', 'check']"
                [fixedWidth]="true"
                size="sm"></fa-icon>
        </label>
    `,
    styleUrl: './round-checkbox.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RoundCheckboxComponent),
            multi: true,
        },
    ],
    imports: [FaIconComponent],
})
export class RoundCheckboxComponent implements ControlValueAccessor {
    id = v4();
    isDisabled = signal(false);
    checked = signal(false);

    changeFn?: (v: boolean) => void;
    touchFn?: () => void;

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled.set(isDisabled);
    }

    writeValue(checked: boolean): void {
        this.checked.set(checked);
    }

    onChange($event: Event) {
        const target: HTMLInputElement = $event.target as HTMLInputElement;
        if (this.changeFn) {
            this.changeFn(target.checked);
        }
    }
}
