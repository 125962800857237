import { inject } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of } from 'rxjs';
import { exhaustMap, map, switchMap } from 'rxjs/operators';
import { PromptLibraryService } from '../prompt-library.service';
import { PromptLibraryActions } from './prompt-library.actions';

export class PromptLibraryEffect {
    actions$ = inject(Actions);
    promptLibraryService = inject(PromptLibraryService);
    toast = inject(HotToastService);

    loadPromptLibrary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromptLibraryActions.loadPromptLibrary),
            switchMap(() =>
                this.promptLibraryService
                    .loadPromptLibrary()
                    .pipe(map(promptTemplates => PromptLibraryActions.loadPromptLibrarySuccess({ promptTemplates }))),
            ),
        ),
    );

    createPromptTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromptLibraryActions.createPromptTemplate),
            exhaustMap(action =>
                this.promptLibraryService.createPromptTemplate(action.promptTemplate).pipe(
                    map(promptTemplate => PromptLibraryActions.createPromptTemplateSuccess({ promptTemplate })),
                    this.toast.observe({
                        success: 'Prompt Template created.',
                        error: 'Prompt Template could not be created.',
                    }),
                    catchError(error => {
                        return of(PromptLibraryActions.createPromptTemplateFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    updatePromptTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromptLibraryActions.updatePromptTemplate),
            exhaustMap(action =>
                this.promptLibraryService.updatePromptTemplate(action.promptTemplate).pipe(
                    map(promptTemplate => PromptLibraryActions.updatePromptTemplateSuccess({ promptTemplate })),
                    this.toast.observe({
                        success: 'Prompt Template updated.',
                        error: 'Prompt Template could not be updated.',
                    }),
                    catchError(error => of(PromptLibraryActions.updatePromptTemplateFailure({ error }))),
                ),
            ),
        ),
    );

    deletePromptTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromptLibraryActions.deletePromptTemplate),
            exhaustMap(action =>
                this.promptLibraryService.deletePromptTemplate(action.id).pipe(
                    map(promptTemplate => PromptLibraryActions.deletePromptTemplateSuccess({ id: action.id })),
                    this.toast.observe({
                        success: 'Prompt Template deleted.',
                        error: 'Prompt Template could not be deleted.',
                    }),
                    catchError(error => of(PromptLibraryActions.deletePromptTemplateFailure({ error }))),
                ),
            ),
        ),
    );
}
