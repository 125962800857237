import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectAuthLoggedIn } from './auth/+store/auth.selectors';
import { AppState } from './reducers';
import { NavigationComponent } from './shared/components/navigation/navigation.component';

@Component({
    selector: 'app-root',
    template: `
        @if (loggedIn()) {
            <div class="flex h-svh">
                <app-navigation></app-navigation>
                <div class="flex flex-col flex-grow">
                    <!--                    <app-top-nav></app-top-nav>-->
                    <router-outlet></router-outlet>
                </div>
            </div>
        } @else {
            <router-outlet></router-outlet>
        }
    `,
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet, NavigationComponent],
})
export class AppComponent {
    store = inject<Store<AppState>>(Store);

    loggedIn = this.store.selectSignal(selectAuthLoggedIn);

    ngOnInit() {}
}
