import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChangePasswordDto } from '../../../auth/interfaces/change-password.interface';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ControlsOf } from '../../../shared/types/controls-of.type';
import { UserActions } from '../../../user/+store/user.actions';

@Component({
    selector: 'app-password-change',
    template: `
        <form [formGroup]="form" (ngSubmit)="submit()">
            <label class="form-label" for="oldPassword">Old Password</label>
            <input formControlName="oldPassword" type="password" class="form-input border" id="oldPassword" />

            <label class="form-label mt-6" for="newPassword">New Password</label>
            <input formControlName="newPassword" type="password" class="form-input border" id="newPassword" />

            <label class="form-label mt-3" for="newPasswordRepeat">New Password Repeat</label>
            <input
                formControlName="newPasswordRepeat"
                type="password"
                class="form-input border"
                id="newPasswordRepeat" />

            <div class="flex items-center mt-6">
                <div class="grow-0">
                    <app-button type="submit">Change</app-button>
                </div>
                @if (form.errors) {
                    <div class="ml-3 text-red-700 font-medium">Passwords are not the same!</div>
                }
            </div>
        </form>
    `,
    styleUrls: ['./password-change.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, ButtonComponent],
})
export class PasswordChangeComponent implements OnInit {
    private fb = inject(FormBuilder);
    private store = inject(Store);
    private actions = inject(Actions);
    private cdr = inject(ChangeDetectorRef);
    destroyRef = inject(DestroyRef);

    form!: FormGroup<ControlsOf<ChangePasswordDto>>;

    ngOnInit() {
        this.actions
            .pipe(ofType(UserActions.updatePasswordSuccess), takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.form.reset();
                this.cdr.markForCheck();
            });

        this.form = this.fb.nonNullable.group(
            {
                oldPassword: ['', Validators.required],
                newPassword: ['', Validators.required],
                newPasswordRepeat: ['', Validators.required],
            },
            {
                updateOn: 'submit',
                validators: [
                    control => {
                        const values = control.getRawValue();
                        if (
                            values.newPassword &&
                            values.newPasswordRepeat &&
                            values.newPassword !== values.newPasswordRepeat
                        ) {
                            return { repeatPassword: true };
                        }

                        return null;
                    },
                ],
            },
        );
    }

    submit() {
        if (this.form.valid) {
            const values = this.form.getRawValue();
            this.store.dispatch(UserActions.updatePassword(values));
        }
    }
}
