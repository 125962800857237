import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PasswordChangeComponent } from '../components/password-change/password-change.component';

@Component({
    selector: 'app-setting-profile',
    template: `
        <div class="w-full px-12 py-6">
            <div class="flex w-full max-w-[700px] bg-slate-50 rounded p-6 shadow">
                <div class="mr-12 w-[300px]">
                    <div class="text-slate-800 font-semibold">Change Password</div>
                    <div class="text-slate-400 text-sm font-medium">Please use at least 8 characters with letters,
                        numbers
                        and special chars.
                    </div>
                </div>
                <div class="grow">
                    <app-password-change></app-password-change>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./setting-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PasswordChangeComponent],
})
export class SettingProfileComponent {

}
