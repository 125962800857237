import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MemberActions } from '../../member/+store/member.actions';
import { selectMemberAll } from '../../member/+store/member.selectors';
import { InviteMemberModalComponent } from '../../member/components/invite-member-modal/invite-member-modal.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { UserAvatarComponent } from '../../shared/components/user-avatar/user-avatar.component';
import { ModalService } from '../../shared/modal/modal.service';

@Component({
    selector: 'app-setting-members',
    template: `
        <div class="w-full max-w-[700px] px-12 py-6">
            <div class="flex items-center mb-5">
                <h3 class="font-medium text-slate-700 text-lg">Member List</h3>
                <div class="ml-auto">
                    <app-button [outline]="true" (click)="invite()">Invite User</app-button>
                </div>
            </div>
            <div class="flex flex-col w-full shadow rounded text-slate-800">
                @for (member of members(); track member) {
                    <div
                        class="flex px-5 py-4 items-center [&:not(:last)]:border-b border-slate-100 odd:bg-slate-50 hover:bg-blue-50"
                    >
                        <div class="w-[40px]">
                            <app-user-avatar [user]="member" [roundedFull]="true"></app-user-avatar>
                        </div>
                        <div class="leading-5 ml-5 text-sm">
                            <div class="font-medium">{{ member.firstName }} {{ member.lastName }}</div>
                            <div class="text-slate-500">{{ member.email }}</div>
                        </div>
                        <div class="ml-auto flex items-center">
                            @if (member.invitationPending) {
                                <div class="rounded px-2 py-1 bg-blue-400 text-xs font-semibold text-white mr-3"
                                >
                                    Invitation pending
                                </div>
                            }
                            <!--                    <app-button color="transparent" tp="Delete User" (confirmedClick)="delete(member.id)">-->
                            <!--                        <fa-icon class="text-slate-500" [icon]="['fas', 'trash']"></fa-icon>-->
                            <!--                    </app-button>-->
                        </div>
                    </div>
                }
            </div>
        </div>
    `,
    styleUrls: ['./setting-members.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ButtonComponent,
        UserAvatarComponent,
    ],
})
export class SettingMembersComponent implements OnInit {
    modalService = inject(ModalService);
    store = inject(Store);
    members = this.store.selectSignal(selectMemberAll);

    ngOnInit() {
        this.store.dispatch(MemberActions.loadMembers());
    }

    invite() {
        this.modalService.open(InviteMemberModalComponent);
    }

    delete(id: string) {
        console.log('delete');
    }
}
