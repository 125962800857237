import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Tenant } from '../tenant.interface';

import { TenantActions } from './tenant.actions';

export const tenantsFeatureKey = 'tenants';

export interface TenantState extends EntityState<Tenant> {
    // additional entities state properties
    loading: boolean,
}

export const adapter: EntityAdapter<Tenant> = createEntityAdapter<Tenant>();

export const initialState: TenantState = adapter.getInitialState({
    loading: false,
});

export const tenantReducer = createReducer(
    initialState,
    on(TenantActions.loadUserTenants,
        (state) => ({ ...state, loading: true }),
    ),
    on(TenantActions.loadUserTenantsSuccess,
        (state, { tenants }) =>
            adapter.upsertMany(tenants, {
                ...state,
                isLoading: false,
            })),
);

