import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, take } from 'rxjs';
import { UserActions } from '../../+store/user.actions';
import { selectUserId } from '../../+store/user.selectors';
import { AppState } from '../../../reducers';
import { ModalBodyComponent } from '../../../shared/modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../shared/modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../shared/modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';

@Component({
    selector: 'app-delete-modal',
    template: `
        <app-modal>
            <app-modal-title>
                <h3>Delete account?</h3>
            </app-modal-title>
            <app-modal-body class="px-3">
                Do you really want to delete your account? <br />
                You will not have access to your chats after that.
            </app-modal-body>
            <app-modal-footer>
                <div class="flex justify-between">
                    <button (click)="modalContainer.onDismiss()" type="button" class="btn-light">Cancel</button>
                    <button (click)="delete()" type="button" class="btn-danger">Delete</button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrls: ['./delete-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ModalComponent, ModalTitleComponent, ModalBodyComponent, ModalFooterComponent],
})
export class DeleteModalComponent {
    modalContainer = inject(ModalContentContainerComponent);
    private store = inject<Store<AppState>>(Store);

    async delete() {
        const id = await firstValueFrom(this.store.pipe(select(selectUserId), take(1), filterNullish()));
        this.store.dispatch(UserActions.deleteUser({ id }));
        this.modalContainer.onDismiss();
    }
}
