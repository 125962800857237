import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private http = inject(HttpClient);


    login(email: string, password: string,): Observable<{
        accessToken: string,
        refreshToken: string
    }> {
        return this.http.post<{
            accessToken: string,
            refreshToken: string,
        }>(`${environment.apiUrl}/auth/login`, {email, password}, {withCredentials: true});
    }

    signup(firstname: string, lastname: string, email: string): Observable<boolean> {
        return this.http.post<boolean>(`${environment.apiUrl}/auth/signup`, { firstname, lastname, email });
    }

    getNewAccessToken(): Observable<{
        accessToken: string,
        refreshToken: string
    }> {
        return this.http.post<{
            accessToken: string,
            refreshToken: string
        }>(`${environment.apiUrl}/auth/access-token`, {}, {withCredentials: true});
    }

    logout(): Observable<boolean> {
        return this.http.post<boolean>(`${environment.apiUrl}/auth/logout`, {}, {withCredentials: true});
    }

    acceptInvitation(action: {password: string, token: string}) {
        return this.http.post<boolean>(`${environment.apiUrl}/auth/accept-invitation`, {
            token: action.token,
            password: action.password,
        });
    }

    forgotPassword(action: {email: any}) {
        return this.http.post<boolean>(`${environment.apiUrl}/auth/forgot-password`, {
            email: action.email,
        });
    }
}
