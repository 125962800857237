import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { PaymentModule } from '../payment/payment.module';
import { SharedModule } from '../shared/shared.module';
import { TenantModule } from '../tenant/tenant.module';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { SettingIndexComponent } from './setting-index/setting-index.component';
import { SettingMembersComponent } from './setting-members/setting-members.component';
import { SettingPaymentComponent } from './setting-payment/setting-payment.component';
import { SettingProfileComponent } from './setting-profile/setting-profile.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        ReactiveFormsModule,
        TippyDirective,
        PaymentModule,
        TenantModule,
        SettingIndexComponent,
        SettingPaymentComponent,
        SettingProfileComponent,
        PasswordChangeComponent,
        SettingMembersComponent,
    ],
})
export class SettingModule {
}
