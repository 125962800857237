import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { StripeInvoice } from '../interfaces/stripe-invoice.interface';
import { StripePrice } from '../interfaces/stripe-price.interface';
import { StripeProduct } from '../interfaces/stripe-product.interface';
import { StripeSubscription } from '../interfaces/stripe-subscription.interface';

export const PaymentActions = createActionGroup({
    source: 'Payments/API',
    events: {
        'Load Invoices of Tenant': emptyProps,
        'Load Invoices Of Subscription': props<{subscriptionId: string}>(),
        'Load Invoices Success': props<{invoices: StripeInvoice[]}>(),

        'Load Subscriptions by Tenant': emptyProps,
        'Load Subscription by Id': props<{subscription: string}>(),
        'Load Subscriptions Success': props<{subscriptions: StripeSubscription[]}>(),

        'Cancel Subscription': props<{subscriptionId: string}>(),
        'Cancel Subscription Success': props<{subscription: StripeSubscription}>(),

        'Load Prices': emptyProps,
        'Load Prices Success': props<{prices: StripePrice[]}>(),

        'Load Products': emptyProps,
        'Load Products Success': props<{products: StripeProduct[]}>(),
    },
});
