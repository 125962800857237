import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-navigation-category-header',
    template: `
        <div class="mt-6 mb-2 text-slate-950/40 text-xs uppercase tracking-wider font-bold">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./navigation-category-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class NavigationCategoryHeaderComponent {

}
