import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { KnowledgeSourceActions } from './knowledge-source.actions';
import { KnowledgeSource } from './knowledge-source.model';

export const knowledgeSourcesFeatureKey = 'knowledgeSources';

export interface KnowledgeSourceState extends EntityState<KnowledgeSource> {
    isLoading: boolean;
}

export const adapter: EntityAdapter<KnowledgeSource> = createEntityAdapter<KnowledgeSource>();

export const initialState: KnowledgeSourceState = adapter.getInitialState({
    isLoading: false,
});

export const knowledgeSourceReducer = createReducer(
    initialState,
    on(KnowledgeSourceActions.loadKnowledgeSources, KnowledgeSourceActions.addWebsite, state => ({
        ...state,
        isLoading: true,
    })),
    on(KnowledgeSourceActions.loadKnowledgeSourcesSuccess, (state, action) =>
        adapter.upsertMany(action.knowledgeSources, { ...state, isLoading: false }),
    ),
    on(KnowledgeSourceActions.deleteKnowledgeSourceSuccess, (state, action) =>
        adapter.removeOne(action.id, { ...state }),
    ),
    on(KnowledgeSourceActions.addWebsiteSuccess, (state, action) =>
        adapter.upsertOne(action.knowledgeSource, { ...state, isLoading: false }),
    ),
);

export const knowledgeSourcesFeature = createFeature({
    name: knowledgeSourcesFeatureKey,
    reducer: knowledgeSourceReducer,
    extraSelectors: ({ selectKnowledgeSourcesState }) => ({
        ...adapter.getSelectors(selectKnowledgeSourcesState),
    }),
});
