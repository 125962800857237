import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../../user/+store/user.model';

@Component({
    selector: 'app-user-avatar',
    template: `
        <div class="flex justify-center items-center bg-amber-400 text-white aspect-square w-full"
             [class.rounded-full]="roundedFull" [class.rounded]="!roundedFull">
            {{ user?.firstName?.slice(0, 1) }}{{ user?.lastName?.slice(0, 1) }}
        </div>
    `,
    styleUrls: ['./user-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class UserAvatarComponent {
    @Input() roundedFull = false;

    @Input() user?: Pick<User, 'firstName' | 'lastName'>;
}
