import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Document } from './document.model';


export const DocumentActions = createActionGroup({
    source: 'Document/API',
    events: {
        'Load Documents By Chat Id': props<{ chatId: string }>(),
        'Load Documents By Chat Id Success': props<{ documents: Document[] }>(),
    },
});
