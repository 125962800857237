import { inject, Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { EmbeddingService } from '../embedding.service';
import { EmbeddingActions } from './embedding.actions';

@Injectable()
export class EmbeddingEffects {
    private actions$ = inject(Actions);
    private embeddingService = inject(EmbeddingService);
    private toast = inject(HotToastService);


    loadEmbeddings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EmbeddingActions.loadEmbeddingsForMessage),
            exhaustMap((action) => this.embeddingService.getEmbeddingsForMessage(action.ids).pipe(
                map((embeddings) => {
                    return EmbeddingActions.loadEmbeddingsSuccess({embeddings})
                }),
                catchError(err => {
                    return of(EmbeddingActions.loadEmbeddingsError({err}))
                })
            )),
        );
    });
}
