import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TippyDirective } from '@ngneat/helipopper';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AuthActions } from '../../../auth/+store/auth.actions';
import { selectAuthEmail } from '../../../auth/+store/auth.selectors';
import { AppState } from '../../../reducers';
import { PerfectScrollbarDirective } from '../../directives/perfect-scrollbar/perfect-scrollbar.directive';

@Component({
    selector: 'app-navigation',
    template: `
        <nav
            class="relative bg-stone-100 border-r border-stone-200 flex flex-col shrink-0 overflow-auto items-center h-full"
            [class.condensed]="condensed()"
            scrollbar>
            <div class="flex justify-center mt-5">
                <a [routerLink]="['/']">
                    @if (condensed()) {
                        <img ngSrc="/logo/logo-small.svg" height="38" width="25" alt="" />
                    } @else {
                        <img ngSrc="/logo/logo.svg" height="38" width="158" alt="" />
                    }
                </a>
            </div>

            <div class="mt-10 flex flex-col items-center space-y-3 px-3 w-full">
                @for (item of navigation; track item.routerLink) {
                    <a
                        [routerLink]="item.routerLink"
                        #rla="routerLinkActive"
                        routerLinkActive="active"
                        [tp]="item.tooltip"
                        tpPlacement="right"
                        class="nav-item">
                        <fa-icon
                            [icon]="rla.isActive ? item.iconActive : item.icon"
                            [fixedWidth]="true"
                            [size]="rla.isActive || !condensed() ? undefined : 'lg'"></fa-icon>
                        @if (!condensed()) {
                            <span class="ml-2.5">{{ item.label }}</span>
                        }
                    </a>
                }
            </div>

            <div class="mt-auto mb-3 flex flex-col space-y-3">
                <a
                    [routerLink]="['/settings']"
                    #rla="routerLinkActive"
                    routerLinkActive="active"
                    tp="Settings"
                    tpPlacement="right"
                    class="nav-item">
                    <fa-icon
                        [icon]="rla.isActive ? ['fas', 'gear'] : ['fal', 'gear']"
                        size="lg"
                        [fixedWidth]="true"></fa-icon>
                </a>

                <button (click)="logout()" class="nav-item">
                    <fa-icon [icon]="['fal', 'person-to-portal']" size="lg" [fixedWidth]="true"></fa-icon>
                </button>
            </div>

            <button
                type="button"
                (click)="toggleCondensed()"
                class="h-[64px] flex items-center px-5 w-full border-t border-stone-200"
                [class.justify-center]="condensed()">
                <fa-icon
                    [flip]="condensed() ? undefined : 'horizontal'"
                    [icon]="['far', 'arrow-right-long-to-line']"></fa-icon>
                @if (!condensed()) {
                    <span class="ml-2">Collapse</span>
                }
            </button>
        </nav>
    `,
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        PerfectScrollbarDirective,
        RouterLink,
        TippyDirective,
        FaIconComponent,
        RouterLinkActive,
        NgOptimizedImage,
    ],
})
export class NavigationComponent implements OnInit {
    store = inject<Store<AppState>>(Store);

    condensed = signal(true);

    navigation: { routerLink: string[]; icon: IconProp; iconActive: IconProp; tooltip: string; label: string }[] = [
        {
            routerLink: ['/chat'],
            icon: ['fal', 'comment-lines'],
            iconActive: ['fas', 'comment-lines'],
            label: 'Chat',
            tooltip: 'Chat',
        },
        {
            routerLink: ['/prompt-library'],
            icon: ['fal', 'books'],
            iconActive: ['fas', 'books'],
            label: 'Prompt Library',
            tooltip: 'Prompt Library',
        },
        {
            routerLink: ['/knowledge-base'],
            icon: ['fal', 'graduation-cap'],
            iconActive: ['fas', 'graduation-cap'],
            label: 'Knowledge Base',
            tooltip: 'Knowledge Base',
        },
        {
            routerLink: ['/brand-voice'],
            icon: ['fal', 'heart'],
            iconActive: ['fas', 'heart'],
            label: 'Brand Voice',
            tooltip: 'Brand Voice',
        },
        {
            routerLink: ['/workflow'],
            icon: ['fal', 'arrow-progress'],
            iconActive: ['fas', 'arrow-progress'],
            label: 'Workflows',
            tooltip: 'Workflows',
        },
    ];

    email$: Observable<string | null> = of(null);

    ngOnInit() {
        this.email$ = this.store.select(selectAuthEmail);
    }

    logout() {
        this.store.dispatch(AuthActions.logout());
    }

    toggleCondensed() {
        this.condensed.update(condensed => !condensed);
    }
}
