import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { StripeInvoice } from '../interfaces/stripe-invoice.interface';
import { StripePrice } from '../interfaces/stripe-price.interface';
import { StripeProduct } from '../interfaces/stripe-product.interface';
import { StripeSubscription } from '../interfaces/stripe-subscription.interface';
import { PaymentActions } from './payment.actions';

export const paymentFeatureKey = 'payment';

export const subscriptionAdapter: EntityAdapter<StripeSubscription> = createEntityAdapter<StripeSubscription>();
export const invoiceAdapter: EntityAdapter<StripeInvoice> = createEntityAdapter<StripeInvoice>();
export const priceAdapter: EntityAdapter<StripePrice> = createEntityAdapter<StripePrice>();
export const productAdapter: EntityAdapter<StripeProduct> = createEntityAdapter<StripeProduct>();


export interface PaymentState {
    subscriptions: EntityState<StripeSubscription>;
    subscriptionLoading: boolean;
    invoices: EntityState<StripeInvoice>;
    invoicesLoading: boolean;
    prices: EntityState<StripePrice>;
    products: EntityState<StripeProduct>;
}

export const initialState: PaymentState = {
    subscriptions: subscriptionAdapter.getInitialState(),
    subscriptionLoading: false,
    invoices: invoiceAdapter.getInitialState(),
    invoicesLoading: false,
    prices: priceAdapter.getInitialState(),
    products: productAdapter.getInitialState(),
};

export const paymentReducer = createReducer(
    initialState,
    // SUBSCRIPTIONS
    on(PaymentActions.cancelSubscription,
        PaymentActions.loadSubscriptionsByTenant,
        (state) => ({
            ...state,
            subscriptionLoading: true,
        })),

    on(PaymentActions.cancelSubscriptionSuccess, (state, { subscription }) => ({
        ...state,
        subscriptions: subscriptionAdapter.upsertOne(subscription, state.subscriptions),
        subscriptionLoading: false,
    })),

    on(PaymentActions.loadSubscriptionsSuccess, (state, { subscriptions }) => ({
        ...state,
        subscriptions: subscriptionAdapter.upsertMany(subscriptions, state.subscriptions),
        subscriptionLoading: false,
    })),


    // INVOICES
    on(PaymentActions.loadInvoicesOfSubscription, (state) => ({
        ...state,
        invoicesLoading: true,
    })),

    on(PaymentActions.loadInvoicesSuccess, (state, { invoices }) => ({
        ...state,
        invoices: invoiceAdapter.upsertMany(invoices, state.invoices),
        invoicesLoading: false,
    })),

    // PRICES
    on(PaymentActions.loadPricesSuccess, (state, { prices }) => ({
        ...state,
        prices: priceAdapter.upsertMany(prices, state.prices),
    })),

    // PRODUCTS
    on(PaymentActions.loadProductsSuccess, (state, { products }) => ({
        ...state,
        products: productAdapter.upsertMany(products, state.products),
    })),
);
