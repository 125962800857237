import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadService } from '../../upload.service';
import { UploadActions } from './upload.actions';


@Injectable()
export class UploadEffects {
    private actions$ = inject(Actions);
    private uploadService = inject(UploadService);

    loadAllUploads$ = createEffect(() => this.actions$.pipe(
        ofType(UploadActions.loadAllUploads),
        exhaustMap(() => this.uploadService.loadAll().pipe(
            map(uploads => UploadActions.loadAllUploadsSuccess({ uploads })),
            catchError(error => of(UploadActions.loadAllUploadsFailure({ error }))),
        )),
    ));

    loadByUploadCollectionId$ = createEffect(() => this.actions$.pipe(
        ofType(UploadActions.loadUploadsByCollectionId),
        switchMap(action => this.uploadService.loadByCollectionId(action.uploadCollectionId).pipe(
            map(uploads => UploadActions.loadUploadsByCollectionIdSuccess({ uploads })),
        )),
    ));

    deleteUpload$ = createEffect(() => this.actions$.pipe(
        ofType(UploadActions.deleteUpload),
        switchMap(action => this.uploadService.deleteUpload(action.id).pipe(
            map(upload => UploadActions.deleteUploadSuccess({id: action.id})),
            catchError(error => of(UploadActions.deleteUploadFailure({error}))),
        ))
    ));
}
