
import {createActionGroup, emptyProps, props} from '@ngrx/store';
import { Update } from '../../shared/utilities/update.type';
import {Project} from './project.model';
import {Create} from "../../shared/utilities/create.type";


export const ProjectActions = createActionGroup({
    source: 'Project/API',
    events: {
        'Load Projects': emptyProps(),
        'Load Projects Success': props<{ projects: Project[] }>(),

        'Add Project': props<{ project: Partial<Project> }>(),
        'Add Project Success': props<{ project: Project }>(),
        'Add Project Failure': props<{error: Error}>(),
        'Upsert Project': props<{ project: Project }>(),
        'Add Projects': props<{ projects: Project[] }>(),
        'Upsert Projects': props<{ projects: Project[] }>(),
        'Update Project': props<{ project: Update<Project> }>(),
        'Update Project Success': props<{ project: Project }>(),
        'Update Project Failure': props<{error: Error}>(),
        'Update Projects': props<{ projects: Update<Project>[] }>(),
        'Delete Project': props<{ id: string }>(),
        'Delete Project Success': props<{ id: string }>(),
        'Delete Project Failure': props<{error: any}>(),
        'Delete Projects': props<{ ids: string[] }>(),
        'Clear Projects': emptyProps(),
    },
});
