import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    inject,
    OnInit,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { HotToastService } from '@ngneat/hot-toast';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs';
import { KnowledgeSourceActions } from '../../+store/knowledge-source.actions';
import { KnowledgeSource } from '../../+store/knowledge-source.model';
import {
    selectKnowledgeSourceIsLoading,
    selectKnowledgeSourceSortedByCreatedAt,
} from '../../+store/knowledge-source.selectors';
import { environment } from '../../../../environments/environment';
import { DocumentService } from '../../../document/document.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { PerfectScrollbarDirective } from '../../../shared/directives/perfect-scrollbar/perfect-scrollbar.directive';
import { ModalService } from '../../../shared/modal/modal.service';
import { PopoverDirective } from '../../../shared/popover/directives/popover.directive';
import { AddWebsiteModalComponent } from '../../components/add-website-modal/add-website-modal.component';
import { KnowledgeSourceTableComponent } from '../../components/knowledge-source-table/knowledge-source-table.component';

@Component({
    selector: 'app-knowledge-source-index',
    imports: [
        MainWrapperComponent,
        ButtonComponent,
        KnowledgeSourceTableComponent,
        PerfectScrollbarDirective,
        FaIconComponent,
        PopoverDirective,
    ],
    template: `
        <app-main-wrapper>
            <div class="px-24 py-12 w-full max-w-[1200px] flex flex-col">
                <div class="flex grow-0">
                    <div class="text-2xl font-bold text-stone-900">Knowledge Base</div>
                    <div class="ml-auto">
                        <app-button color="primary" [appPopover]="tpl" [offsetY]="0">Add source</app-button>

                        <ng-template #tpl>
                            <div class="bg-white py-2 flex flex-col items-start">
                                <button
                                    (click)="triggerFileInput()"
                                    class="hover:bg-stone-50 w-full py-2 pl-6 pr-20 text-sm text-stone-700 whitespace-nowrap flex">
                                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'file-download']"></fa-icon>
                                    <span class="ml-3">File upload</span>
                                </button>

                                <button
                                    (click)="openWebsiteModal()"
                                    class="hover:bg-stone-50 w-full py-2 pl-6 pr-20 text-sm whitespace-nowrap flex">
                                    <fa-icon [fixedWidth]="true" [icon]="['far', 'globe']"></fa-icon>
                                    <span class="ml-3">Add web page</span>
                                </button>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <div
                    class="mt-12 max-h-full overflow-auto relative h-full"
                    [class.overflow-hidden]="isDragging()"
                    scrollbar>
                    <app-knowledge-source-table
                        (delete)="delete($event)"
                        (download)="download($event)"
                        (addSource)="triggerFileInput()"
                        [isLoading]="isLoading()"
                        [uploadingFiles]="uploadingFiles()"
                        [knowledgeSources]="knowledgeSources()"></app-knowledge-source-table>

                    @if (isDragging()) {
                        <div
                            class="absolute z-[1000] inset-0 drop-area rounded-xl border-2 border-dashed border-primary">
                            <div
                                class="flex flex-col items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <fa-icon class="text-primary" size="3x" [icon]="['fas', 'cloud-arrow-up']"></fa-icon>
                                <div class="text-stone-700 text-sm font-medium text-center mt-2">
                                    Drop files to upload them<br />to Knowledge Base
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </app-main-wrapper>
        <input (change)="onFileInputChange($event)" type="file" class="hidden" #fileInput accept="application/pdf" />
    `,
    styleUrl: './knowledge-source-index.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeSourceIndexComponent implements OnInit {
    store = inject(Store);
    http = inject(HttpClient);
    toast = inject(HotToastService);
    documentService = inject(DocumentService);
    modal = inject(ModalService);

    isDragging = signal(false);
    uploadingFiles = signal<
        WritableSignal<{
            file: File;
            progress: number;
        }>[]
    >([]);

    knowledgeSources = this.store.selectSignal(selectKnowledgeSourceSortedByCreatedAt);
    isLoading = this.store.selectSignal(selectKnowledgeSourceIsLoading);
    fileInput = viewChild.required<ElementRef<HTMLInputElement>>('fileInput');

    ngOnInit(): void {
        this.store.dispatch(KnowledgeSourceActions.loadKnowledgeSources());
    }

    @HostListener('window:dragover', ['$event'])
    onDragOver(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.isDragging()) {
            this.isDragging.set(true);
        }
    }

    @HostListener('window:dragleave', ['$event'])
    onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (
            event.clientX <= 0 ||
            event.clientY <= 0 ||
            event.clientX >= window.innerWidth ||
            event.clientY >= window.innerHeight
        ) {
            this.isDragging.set(false);
        }
    }

    @HostListener('window:drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();
        this.isDragging.set(false);

        const forbiddenType = Array.from(event.dataTransfer?.items || []).some(
            item => item.type !== 'application/pdf' || item.getAsFile()!.size > 1024 * 1024 * 10,
        );

        if (forbiddenType) {
            this.toast.error('Currently only PDFs with maximum 10 MB are allowed.');
            return;
        }

        const files = Array.from(event.dataTransfer?.items || [])
            .filter(item => item.kind === 'file' && item.type === 'application/pdf')
            .map(item => item.getAsFile()!);

        this.upload(files);
    }

    upload(files: File[]) {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));

        this.uploadingFiles.set(
            files.map(file =>
                signal({
                    file,
                    progress: 0,
                }),
            ),
        );

        this.http
            .post<KnowledgeSource[]>(`${environment.apiUrl}/upload/multiple`, formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = error.error.message !== '' ? error.error.message : error.statusText;
                    errorMessage = errorMessage !== '' ? errorMessage : 'A unknown error occurred.';
                    this.toast.error(errorMessage, {
                        duration: 5000,
                    });
                    throw error;
                }),
            )
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    if (event.total) {
                        this.updateProgress(event.loaded, event.total);
                    }
                } else if (event.type === HttpEventType.Response) {
                    if (event.body) {
                        this.store.dispatch(
                            KnowledgeSourceActions.loadKnowledgeSourcesSuccess({ knowledgeSources: event.body }),
                        );
                    }
                    this.uploadingFiles.set([]);
                    this.toast.success('Upload successful.');
                }
            });
    }

    updateProgress(loaded: number, total: number): void {
        // const totalProgress = Math.round((loaded / total) * 100);

        // const totalSize = this.uploadingFiles().reduce((sum, file) => sum + file().file.size, 0);
        let accumulated = 0;

        this.uploadingFiles().forEach((file, index) => {
            file.update(f => ({
                ...f,
                progress: Math.min(100, Math.round(((loaded - accumulated) / file().file.size) * 100)),
            }));
            accumulated += file().file.size;

            if (accumulated >= loaded) {
                return;
            }
        });
    }

    delete(id: string) {
        this.store.dispatch(KnowledgeSourceActions.deleteKnowledgeSource({ id }));
    }

    triggerFileInput() {
        this.fileInput().nativeElement.click();
    }

    onFileInputChange(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files;

        if (files) {
            this.upload(Array.from(files));
        }
    }

    download(uploadId: string) {
        this.documentService.download(uploadId);
    }

    openWebsiteModal() {
        this.modal.open(AddWebsiteModalComponent);
    }
}
