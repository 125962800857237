import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { selectProductById } from '../../+store/payment.selectors';
import { AppState } from '../../../reducers';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalService } from '../../../shared/modal/modal.service';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { StripeProduct } from '../../interfaces/stripe-product.interface';
import { StripeSubscription } from '../../interfaces/stripe-subscription.interface';
import { ListInvoicesModalComponent } from '../../modals/list-invoices-modal/list-invoices-modal.component';

@Component({
    selector: 'app-stripe-subscription-history-item',
    template: `
        <div class="bg-white shadow rounded p-3 mt-4">
            <div class="text-sm text-gray-400 mt-1 font-bold">{{ (product$ | async)?.name }}</div>
            <div class="flex flex-row">
                <div>
                    <div class="text-sm text-gray-400 mt-1">Status: {{ subscription.status }}</div>
                    <div class="text-sm text-gray-400 mt-1">Debit interval: {{ subscription.plan.interval }}</div>
                    <div class="text-sm text-gray-400 mt-1">
                        Amount:
                        {{ subscription.plan.amount / 100 | currency: subscription.plan.currency.toUpperCase() }}
                    </div>
                    <div class="text-sm text-gray-400 mt-1">
                        Start date: {{ subscription.created * 1000 | date: 'dd.MM.yyyy' }}
                    </div>
                    <div class="text-sm text-gray-400 mt-1">
                        Cancellation date: {{ subscription.canceled_at * 1000 | date: 'dd.MM.yyyy' }}
                    </div>
                    @if (subscription.status === 'canceled') {
                        <div class="text-sm text-gray-400 mt-1">
                            End date: {{ subscription.current_period_end * 1000 | date: 'dd.MM.yyyy' }}
                        </div>
                    }
                </div>
                <app-button color="white" (click)="displayInvoices()"> Display invoices </app-button>
            </div>
        </div>
    `,
    styleUrls: ['./stripe-subscription-history-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ButtonComponent, AsyncPipe, CurrencyPipe, DatePipe],
})
export class StripeSubscriptionHistoryItemComponent implements OnInit {
    private store = inject<Store<AppState>>(Store);
    private modal = inject(ModalService);

    @Input() subscription!: StripeSubscription;
    product$!: Observable<StripeProduct>;

    ngOnInit() {
        this.product$ = this.store.select(selectProductById(this.subscription.plan.product)).pipe(filterNullish());
    }

    async displayInvoices() {
        const product = await firstValueFrom(this.product$);
        const modal = this.modal.open(ListInvoicesModalComponent, { centered: true, size: 'auto' });
        modal.contentInstance.subscriptionId = this.subscription.id;
        modal.contentInstance.productName = product.name;
    }
}
