import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleSwitchComponent } from '../../../shared/components/toggle-switch/toggle-switch.component';
import { ChatModelSettingsComponent } from '../chat-model-settings/chat-model-settings.component';
import { ChatModelSwitcherComponent } from '../chat-model-switcher/chat-model-switcher.component';

@Component({
    selector: 'app-chat-top-bar',
    standalone: true,
    imports: [
        ChatModelSettingsComponent,
        ChatModelSwitcherComponent,
        FormsModule,
        ReactiveFormsModule,
        ToggleSwitchComponent,
    ],
    template: `
        <div [formGroup]="form" class="grid grid-cols-[minmax(0,1fr)_auto_1fr] items-center mt-5 mb-3 w-full">
            <div class="min-w-0"></div>
            <div class="flex justify-center">
                <app-chat-model-switcher formControlName="model"></app-chat-model-switcher>
                <app-chat-model-settings formControlName="settings" class="ml-2"></app-chat-model-settings>
            </div>
            <div class="ml-auto flex items-center px-3">
                <span class="font-medium text-slate-400 text-sm mr-2">Private</span>
                <app-toggle-switch formControlName="private" size="sm"></app-toggle-switch>
            </div>
        </div>
    `,
    styleUrl: './chat-top-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'w-full',
    },
})
export class ChatTopBarComponent implements OnInit {
    formGroupDirective = inject(FormGroupDirective);
    form!: FormGroup;

    ngOnInit() {
        this.form = this.formGroupDirective.form;
    }
}
