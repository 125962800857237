import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { exhaustMap, map } from 'rxjs/operators';
import { TenantService } from '../tenant.service';
import { TenantActions } from './tenant.actions';

@Injectable()
export class TenantEffects {
    private actions$ = inject(Actions);
    private tenantService = inject(TenantService);


    loadTenants$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TenantActions.loadUserTenants),
            exhaustMap(() => this.tenantService.getUserTenants().pipe(
                map(tenants => TenantActions.loadUserTenantsSuccess({ tenants })),
            )),
        );
    });
}
