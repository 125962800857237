import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';
import { chatsFeature } from './chat.reducer';

export const {
    selectIds: selectChatIds,
    selectEntities: selectChatEntities,
    selectAll: selectAllChats,
    selectTotal: selectTotalChats,
    selectLoading,
} = chatsFeature;

export const selectChatById = (id: string) => createSelector(selectChatEntities, entities => entities[id]);
export const selectChatByProjectId = (projectId: string) => createSelector(selectAllChats, chats => chats.filter(chat => chat.projectId === projectId));
export const selectRecentChats = () => createSelector(selectAllChats, chats => chats.sort((a, b) => {
    const t1 = DateTime.fromISO(a.createdAt);
    const t2 = DateTime.fromISO(b.createdAt);
    return t1 < t2 ? 1 : t1 > t2 ? -1 : 0;
}).slice(0, 10));
