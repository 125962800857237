import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../+store/auth.actions';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { AuthWrapperComponent } from '../../components/auth-wrapper/auth-wrapper.component';

@Component({
    selector: 'app-forgot-password',
    template: `
        <app-auth-wrapper>
            <div class="w-full">
                <img src="assets/logo/logo.svg" width="150" alt="Logo" class="mb-8">
                <form [formGroup]="form" (ngSubmit)="reset()">
                    <div class="form-group mb-2">
                        <label class="block text-slate-600 mb-1" for="username">
                            E-Mail
                        </label>
                        <input
                            class="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email" type="text" placeholder="E-Mail Address" formControlName="email">
                    </div>


                    <div class="mt-8">
                        <app-button type="submit" [fullWidth]="true">
                            <div class="py-1">Send Reset Link</div>
                        </app-button>
                    </div>

                    <div class="mt-4 text-sm">
                        Suddenly remember? <a class="font-semibold underline cursor-pointer" [routerLink]="['/login']">Back
                        to Login</a>
                    </div>
                </form>
            </div>
        </app-auth-wrapper>
    `,
    styleUrl: './forgot-password.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AuthWrapperComponent,
        ReactiveFormsModule,
        ButtonComponent,
        RouterLink,
    ],
})
export class ForgotPasswordComponent {
    store = inject(Store);
    fb = inject(FormBuilder);
    form = this.fb.nonNullable.group({
        email: ['', [Validators.required, Validators.email]],
    });

    reset() {
        if (this.form.valid) {
            this.store.dispatch(AuthActions.forgotPassword({ email: this.form.getRawValue().email }));
        }
    }
}
