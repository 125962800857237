import { createFeatureSelector, createSelector } from '@ngrx/store';
import { invoiceAdapter, productAdapter, subscriptionAdapter } from './payment.reducer';
import * as fromPayment from './payment.reducer';

export const selectPaymentState = createFeatureSelector<fromPayment.PaymentState>(
    fromPayment.paymentFeatureKey,
);

// INVOICE
export const selectInvoiceState = createSelector(selectPaymentState, state => state.invoices);
export const {
    selectAll: selectAllInvoices,
} = invoiceAdapter.getSelectors(selectInvoiceState);

export const selectInvoicesOfSubscription = (subscriptionId: string) => createSelector(selectAllInvoices,
    entries => entries.filter(entry => entry.subscription === subscriptionId));


// SUBSCRIPTIONS
export const selectSubscriptionState = createSelector(selectPaymentState, state => state.subscriptions);
export const {
    selectAll: selectAllSubscription,
} = subscriptionAdapter.getSelectors(selectSubscriptionState);

export const selectActiveSubscription = createSelector(selectAllSubscription,
    entries => entries.find(entry => entry.status === 'active'));


// PRODUCTS
export const selectProductState = createSelector(selectPaymentState, state => state.products);
export const {
    selectAll: selectAllProducts,
} = productAdapter.getSelectors(selectProductState);

export const selectProductById = (id: string) => createSelector(selectAllProducts,
    entries => entries.find(product => product.id === id));


//LOADING
export const isInvoiceLoading = createSelector(selectPaymentState, state => state.invoicesLoading);
export const isSubscriptionLoading = createSelector(selectPaymentState, state => state.subscriptionLoading);
