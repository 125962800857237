import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from './+store/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private http = inject(HttpClient);


    updatePassword(payload: {oldPassword: string, newPassword: string, newPasswordRepeat: string}): Observable<null> {
        return this.http.patch<null>(`${environment.apiUrl}/users/change-password`, {
            oldPassword: payload.oldPassword,
            newPassword: payload.newPassword,
            newPasswordRepeat: payload.newPasswordRepeat,
        });
    }

    getMe(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/users/me`);
    }

    createUser(email: string, firstname: string, lastname: string) {
        return this.http.post(`${environment.apiUrl}/users/create`, {
            email: email,
            firstname : firstname,
            lastname: lastname,
        });
    }

    deleteUser(id: string): Observable<boolean> {
        return this.http.delete<boolean>(`${environment.apiUrl}/users/${id}`);
    }
}
