import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Template } from './template.interface';
import { TemplateActions } from './template.actions';

export const templateFeatureKey = 'templates';

export interface TemplateState extends EntityState<Template> {
    loading: boolean;
    error: any;
    currentTemplateId: string;
}

export const adapter: EntityAdapter<Template> = createEntityAdapter<Template>();

export const initialState: TemplateState = adapter.getInitialState({
    loading: false,
    error: null,
    currentTemplateId: '',
});

export const templateReducer = createReducer(
    initialState,

    on(TemplateActions.loadTemplates,
        TemplateActions.updateTemplate,
        TemplateActions.deleteTemplate,
        (state) => ({...state, loading: true}),
    ),

    on(TemplateActions.loadTemplatesSuccess,
        (state, {templates}) => adapter.upsertMany(templates, {...state, loading: false}),
    ),

    on(TemplateActions.deleteTemplateSuccess,
        (state, action) => adapter.removeOne(action.id, {...state, loading: false}),
    ),

    on(TemplateActions.setCurrentTemplateId,
        (state, {id}) => ({...state, currentTemplateId: id})),

    on(TemplateActions.clearTemplates, () => initialState),
);
