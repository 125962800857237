import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { faSquareCode } from '@fortawesome/pro-duotone-svg-icons';
import { faBookOpen, faFolder } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { faCog, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TemplateCreateUpdateModalComponent } from '../../shared/components/template-create-update-modal/template-create-update-modal.component';
import { ModalService } from '../../shared/modal/modal.service';
import { filterNullish } from '../../shared/utilities/filter-nullish.operator';
import { CreateProjectModalComponent } from '../components/create-project-modal/create-project-modal.component';

@Component({
    selector: 'app-project-index',
    template: `
        <!--        <div class="h-screen flex flex-col">-->
        <!--            <app-nav></app-nav>-->
        <!--            <main class="grow p-7 overflow-auto">-->
        <!--                <div class="flex gap-6 h-full">-->
        <!--                    <aside-->
        <!--                            class="h-full rounded-xl bg-slate-900 min-w-[300px] max-w-[350px] shadow-lg overflow-hidden flex flex-col">-->
        <!--                        <div class="flex items-center text-slate-300">-->
        <!--                            <div-->
        <!--                                    tp="Projects"-->
        <!--                                    (click)="leftTab = 'projects'"-->
        <!--                                    [class.bg-slate-950]="leftTab !== 'projects'"-->
        <!--                                    class="grow h-[50px] flex items-center justify-center cursor-pointer">-->
        <!--                                <fa-icon [icon]="['fad', 'rectangle-history']" [fixedWidth]="true"></fa-icon>-->
        <!--                            </div>-->
        <!--                            <div-->
        <!--                                    tp="Chats"-->
        <!--                                    (click)="leftTab = 'chats'"-->
        <!--                                    [class.bg-slate-950]="leftTab !== 'chats'"-->
        <!--                                    class="grow hover:bg-slate-800/50 h-[50px] flex items-center justify-center cursor-pointer">-->
        <!--                                <fa-icon [icon]="['fad', 'comments']" [fixedWidth]="true"></fa-icon>-->
        <!--                            </div>-->
        <!--                            <div-->
        <!--                                    tp="Template Prompts"-->
        <!--                                    (click)="leftTab = 'templates'"-->
        <!--                                    [class.bg-slate-950]="leftTab !== 'templates'"-->
        <!--                                    class="grow hover:bg-slate-800/50 h-[50px] flex items-center justify-center cursor-pointer">-->
        <!--                                <fa-icon [icon]="faSquareCode" [fixedWidth]="true"></fa-icon>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <ng-container *ngIf="leftTab === 'projects'">-->
        <!--                            <div class="px-5 mt-5 flex items-center">-->
        <!--                                <h3 class="text-slate-200 font-medium">Projects</h3>-->
        <!--                                <div class="ml-auto">-->
        <!--                                    <button-->
        <!--                                            tp="Create new Project"-->
        <!--                                            (click)="openNewProjectModal()"-->
        <!--                                            class="bg-transparent text-slate-400 ml-auto group-[:not(:hover)]:hidden px-1 py-1 hover:bg-blue-300/10 hover:text-white rounded shadow-sm">-->
        <!--                                        <fa-icon [icon]="['fas', 'plus']" size="lg" [fixedWidth]="true"></fa-icon>-->
        <!--                                    </button>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <div class="px-3 mt-3">-->
        <!--                                <input-->
        <!--                                        class="bg-slate-950 w-full px-4 py-2 rounded-full text-xs text-slate-200 outline-0"-->
        <!--                                        type="text" placeholder="Search..."/>-->
        <!--                            </div>-->
        <!--                            <div class="group mt-1 pb-5 overflow-hidden">-->
        <!--                                <div class="h-full overflow-auto" scrollbar>-->
        <!--                                    <app-project-list></app-project-list>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </ng-container>-->

        <!--                        <ng-container *ngIf="leftTab === 'chats'">-->
        <!--                            <div class="px-5 mt-5 flex items-center">-->
        <!--                                <h3 class="text-slate-200 font-medium">Chats</h3>-->
        <!--                            </div>-->
        <!--                            <div class="px-3 mt-3">-->
        <!--                                <input-->
        <!--                                        class="bg-slate-950 w-full px-4 py-2 rounded-full text-xs text-slate-200 outline-0"-->
        <!--                                        type="text" placeholder="Search..."/>-->
        <!--                            </div>-->
        <!--                            <div class="group mt-1 pb-5 overflow-hidden">-->
        <!--                                <div class="h-full overflow-auto" scrollbar>-->
        <!--                                    <app-chat-list></app-chat-list>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </ng-container>-->

        <!--                        <ng-container *ngIf="leftTab === 'templates'">-->
        <!--                            <div class="px-5 mt-5 mb-2 flex items-center">-->
        <!--                                <h3 class="text-slate-200 font-medium">Template Prompts</h3>-->
        <!--                                <div class="ml-auto">-->
        <!--                                    <button-->
        <!--                                        tp="Create new template prompt"-->
        <!--                                        (click)="openNewPrompt()"-->
        <!--                                        class="bg-transparent text-slate-400 ml-auto group-[:not(:hover)]:hidden px-1 py-1 hover:bg-blue-300/10 hover:text-white rounded shadow-sm">-->
        <!--                                        <fa-icon [icon]="['fas', 'plus']" size="lg" [fixedWidth]="true"></fa-icon>-->
        <!--                                    </button>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <div class="text-gray-400 text-xs px-5 font-light break-after-auto">-->
        <!--                                You can use the templates in every project. When you hover over the name of the template-->
        <!--                                you can see the prompt.-->
        <!--                            </div>-->
        <!--                            <div class="group mt-1 pb-5 overflow-hidden">-->
        <!--                                <div class="h-full overflow-auto" scrollbar>-->
        <!--                                    <app-template-list-global></app-template-list-global>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </ng-container>-->
        <!--                    </aside>-->
        <!--                    <div class="h-full rounded-xl bg-slate-900 grow overflow-hidden shadow-lg">-->
        <!--                        <app-chat></app-chat>-->
        <!--                    </div>-->
        <!--                    <aside-->
        <!--                            class="h-full rounded-xl bg-slate-900 min-w-[400px] max-w-[450px] overflow-hidden flex flex-col shadow-lg">-->
        <!--                        <div [hidden]="!(projectId$ | async)">-->
        <!--                            <div class="border-b border-b-slate-700 flex items-center text-slate-300">-->
        <!--                                <div tp="Files"-->
        <!--                                     class="grow bg-slate-950 h-[50px] flex items-center justify-center cursor-pointer">-->
        <!--                                    <fa-icon [icon]="['fad', 'files']" [fixedWidth]></fa-icon>-->
        <!--                                </div>-->
        <!--                                <div tp="Settings"-->
        <!--                                     class="grow hover:bg-slate-800/50 h-[50px] flex items-center justify-center cursor-pointer">-->
        <!--                                    <fa-icon [icon]="['far', 'gear']" [fixedWidth]></fa-icon>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <div class="p-5">-->
        <!--                                <app-drag-drop-upload></app-drag-drop-upload>-->
        <!--                            </div>-->
        <!--                            <div class="group pb-5 overflow-hidden">-->
        <!--                                <div-->
        <!--                                        class="mt-3 h-full px-5 overflow-auto" scrollbar>-->
        <!--                                    <app-upload-list [projectId]="projectId$ | async"></app-upload-list>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <div [hidden]="!!(projectId$ | async)">-->
        <!--                            <div class="text-gray-400 p-5 mt-3 ">-->
        <!--                                Select a project from your project overview to upload new files or delete files.-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </aside>-->
        <!--                </div>-->
        <!--            </main>-->
        <!--        </div>-->
    `,
    styleUrls: ['./project-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ProjectIndexComponent implements OnInit {
    private route = inject(ActivatedRoute);
    private store = inject(Store);
    private modal = inject(ModalService);

    faCog = faCog;
    faUsers = faUsers;
    faBookOpen = faBookOpen;
    faFolder = faFolder;

    protected readonly faTrash = faTrash;
    leftTab = 'projects';
    projectId$!: Observable<string>;
    faSquareCode = faSquareCode;
    destroyRef = inject(DestroyRef);

    ngOnInit() {
        this.projectId$ = this.route.paramMap.pipe(
            map(params => params.get('projectId')),
            filterNullish(),
            takeUntilDestroyed(this.destroyRef),
        );
    }

    openNewProjectModal() {
        this.modal.open(CreateProjectModalComponent, { centered: true, size: 'auto' });
    }

    openNewPrompt() {
        this.modal.open(TemplateCreateUpdateModalComponent, { centered: true, size: 'auto' });
    }
}
