import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent } from 'rxjs';
import { skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-popover-wrapper',
    template: `
        <div class="rounded-xl overflow-hidden shadow-lg">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./popover-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class PopoverWrapperComponent implements AfterViewInit {
    private elementRef = inject(ElementRef);

    @Output() close = new EventEmitter();

    ngAfterViewInit(): void {
        fromEvent(document, 'click')
            .pipe(
                skip(1),
                filter(event => !this.elementRef.nativeElement.contains(event.target)),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.close.emit();
            });
    }

}
