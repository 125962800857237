import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-modal-title',
    template: `
        <div class="p-4 flex">
            <ng-content></ng-content>
            <button type="button" class="ml-auto" (click)="closeModal()">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </button>
        </div>
    `,
    styleUrls: ['./modal-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FaIconComponent],
})
export class ModalTitleComponent {
    private modal = inject(ModalComponent);

    closeModal() {
        this.modal.close();
    }
}
