import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { ChatActions } from './chat.actions';
import { Chat } from './chat.model';

export const chatsFeatureKey = 'chats';

export interface ChatState extends EntityState<Chat> {
    // additional entities state properties
    loading: boolean;
}

export const adapter: EntityAdapter<Chat> = createEntityAdapter<Chat>();

export const initialState: ChatState = adapter.getInitialState({
    // additional entity state properties
    loading: false,
});

export const chatReducer = createReducer(
    initialState,
    on(
        ChatActions.loadChatByIdSuccess,
        (state, action) => adapter.upsertOne(action.chat, { ...state, loading: false }),
    ),
    on(
        ChatActions.upsertChats,
        ChatActions.loadChatsByProjectIdSuccess,
        ChatActions.loadAllChatsSuccess,
        (state, action) => adapter.upsertMany(action.chats, { ...state, loading: false }),
    ),
    on(ChatActions.updateChat,
        (state, action) => adapter.updateOne({id: action.chat.id, changes: action.chat}, state),
    ),
    on(ChatActions.deleteChat,
        (state, action) => adapter.removeOne(action.id, state),
    ),
    on(ChatActions.deleteChats,
        (state, action) => adapter.removeMany(action.ids, state),
    ),
    on(ChatActions.setChatLoading,
        (state, action) => {
        return {...state, loading: action.loading}
        },
    ),
    on(ChatActions.clearChats,
        state => adapter.removeAll(state),
    ),
    on(ChatActions.updateChatSuccess,
        (state, action) => adapter.updateOne({id: action.chat.id, changes: action.chat}, state),
    )
);

export const chatsFeature = createFeature({
    name: chatsFeatureKey,
    reducer: chatReducer,
    extraSelectors: ({selectChatsState}) => ({
        ...adapter.getSelectors(selectChatsState),
    }),
});
