import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Create } from '../shared/utilities/create.type';
import { Update } from '../shared/utilities/update.type';
import { PromptLibrary } from './+store/prompt-library.model';

@Injectable({
    providedIn: 'root',
})
export class PromptLibraryService {
    http = inject(HttpClient);

    loadPromptLibrary() {
        return this.http.get<PromptLibrary[]>(`${environment.apiUrl}/prompt-library`);
    }

    createPromptTemplate(promptTemplate: Create<PromptLibrary>) {
        return this.http.post<PromptLibrary>(`${environment.apiUrl}/prompt-library`, {
            description: promptTemplate.description,
            name: promptTemplate.name,
            prompt: promptTemplate.prompt,
        });
    }

    updatePromptTemplate(promptTemplate: Update<PromptLibrary>) {
        return this.http.patch<PromptLibrary>(`${environment.apiUrl}/prompt-library/${promptTemplate.id}`, {
            description: promptTemplate.description,
            name: promptTemplate.name,
            prompt: promptTemplate.prompt,
        });
    }

    deletePromptTemplate(id: string) {
        return this.http.delete<boolean>(`${environment.apiUrl}/prompt-library/${id}`);
    }
}
