import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { firstValueFrom } from 'rxjs';
import { ChatActions } from '../../+store/chat.actions';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../reducers';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';

@Component({
    selector: 'app-new-chat-modal',
    template: `
        <div class="modal w-[400px]">
            <div class="modal-title p-4 flex">
                <h3>Start new chat?</h3>
            </div>
            <div class="modal-body p-4">
                <p>
                    We recognized that your question has a different topic than your previous question. Would you rather
                    start a new chat with the question?
                </p>
                <br />
                We also tried to improve your input "<span class="font-bold">{{ message }}</span
                >" to get a better search result. But of course you can edit this suggestion. <br />
                Please note that this input will not be compared again thematically with your previous query.

                <div class="mt-3 mb-3" [formGroup]="form">
                    <label class="block text-white text-sm font-bold mb-2" for="message"> Input message </label>
                    <textarea
                        rows="3"
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="message"
                        type="text"
                        formControlName="message"></textarea>
                </div>
            </div>
            <div class="modal-footer p-4 flex justify-between">
                <button (click)="stayInChat()" type="button" class="rounded bg-gray-700 text-black p-3">
                    Stay in chat
                </button>
                <button (click)="newChat()" type="button" class="rounded bg-green-600 text-black p-3">New Chat</button>
            </div>
        </div>
    `,
    styleUrls: ['./new-chat-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule],
})
export class NewChatModalComponent implements OnInit {
    private socket = inject(Socket);
    private modalContainer = inject(ModalContentContainerComponent);
    private http = inject(HttpClient);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private store = inject<Store<AppState>>(Store);

    @Input() message!: string;
    @Input() chatId!: string;
    @Input() updatedMessage!: string;
    @Input() updatedContext!: string;

    form!: FormGroup;

    ngOnInit() {
        this.form = new FormGroup({
            message: new FormControl('', Validators.required),
        });

        this.form.patchValue({
            message: this.updatedMessage,
        });
    }

    stayInChat() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.socket.emit('chatMessage', {
            text: this.form.value.message,
            chatId: this.chatId,
            contextConfirmed: true,
        });
        this.store.dispatch(ChatActions.setChatLoading({ id: this.chatId, loading: true }));
        this.modalContainer.onDismiss();
    }

    async newChat() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const primary = this.route.snapshot.root; // Get the primary route
        const lastChild = this.getLastChild(primary); // Get the last child (from which you want the params).
        const projectId = lastChild.paramMap.get('projectId');

        const response = await firstValueFrom(
            this.http.post<{ id: string }>(`${environment.apiUrl}/chat`, {
                projectId,
                name: 'New Topic Chat',
            }),
        );
        const chatId = response.id;

        this.socket.emit('chatMessage', {
            text: this.form.value.message,
            chatId: chatId,
        });
        this.store.dispatch(ChatActions.setChatLoading({ id: this.chatId, loading: true }));
        await this.router.navigate(['/project', projectId, 'chat', chatId], { relativeTo: this.route });

        this.modalContainer.onDismiss();
    }

    getLastChild(route: any) {
        let child = route;
        while (child.firstChild) {
            child = child.firstChild;
        }
        return child;
    }
}
