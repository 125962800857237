import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StripeInvoice } from './interfaces/stripe-invoice.interface';
import { StripePrice } from './interfaces/stripe-price.interface';
import { StripeProduct } from './interfaces/stripe-product.interface';
import { StripeSubscription } from './interfaces/stripe-subscription.interface';

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    private http = inject(HttpClient);


    createCheckoutSession(priceId: string): Observable<{sessionUrl: string}> {
        return this.http.post<{sessionUrl: string}>(environment.apiUrl + '/payments/create-checkout-session' , {
            priceId
        })
    }

    loadSubscriptionList(): Observable<StripeSubscription[]> {
        return this.http.get<StripeSubscription[]>(environment.apiUrl + '/payments/tenant/subscriptions')
    }

    loadInvoiceList(): Observable<StripeInvoice[]> {
        return this.http.get<StripeInvoice[]>(environment.apiUrl + '/payments/tenant/invoices')
    }

    getSubscriptionDetails(subscriptionId: string): Observable<StripeSubscription> {
        return this.http.get<StripeSubscription>(environment.apiUrl + '/payments/' + subscriptionId);
    }

    getSubscriptionInvoices(subscriptionId: string): Observable<StripeInvoice[]> {
        return this.http.get<StripeInvoice[]>(environment.apiUrl + '/payments/' + subscriptionId + '/invoice');
    }

    getBillingPortalUrl(): Observable<{sessionUrl: string}> {
        return this.http.get<{sessionUrl: string}>(environment.apiUrl + '/payments/tenant/billing-portal');
    }

    cancelSubscription(subscriptionId: string): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + '/payments/' + subscriptionId);
    }

    loadPrices(): Observable<StripePrice[]> {
        return this.http.get<StripePrice[]>(environment.apiUrl + '/payments/prices')
    }

    loadProducts(): Observable<StripeProduct[]> {
        return this.http.get<StripeProduct[]>(environment.apiUrl + '/payments/products')
    }
}
