import * as i1 from '@angular/common';
import { CommonModule, isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Injector, Output, ViewChild, ViewChildren, PLATFORM_ID, Injectable, Inject, Optional, makeEnvironmentProviders } from '@angular/core';
import * as i1$1 from '@ngneat/overview';
import { isTemplateRef, isComponent, DynamicViewDirective } from '@ngneat/overview';
import { Subject, race, defer } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
const _c0 = (a0, a1) => ({
  "border-color": a0,
  "border-right-color": a1
});
function IndicatorComponent_Conditional_0_Conditional_5_Case_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n      ");
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵtext(2, "\n        ");
    i0.ɵɵelement(3, "hot-toast-error", 1);
    i0.ɵɵtext(4, "\n      ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, "\n      ");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_Conditional_0_Conditional_5_Case_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n      ");
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵtext(2, "\n        ");
    i0.ɵɵelement(3, "hot-toast-checkmark", 1);
    i0.ɵɵtext(4, "\n      ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, "\n      ");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_Conditional_0_Conditional_5_Case_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n      ");
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵtext(2, "\n        ");
    i0.ɵɵelement(3, "hot-toast-warning", 1);
    i0.ɵɵtext(4, "\n      ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, "\n      ");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_Conditional_0_Conditional_5_Case_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n      ");
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵtext(2, "\n        ");
    i0.ɵɵelement(3, "hot-toast-info", 1);
    i0.ɵɵtext(4, "\n      ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, "\n      ");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_Conditional_0_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n  ");
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵtext(2, "\n    ");
    i0.ɵɵelementStart(3, "div");
    i0.ɵɵtext(4, "\n      ");
    i0.ɵɵtemplate(5, IndicatorComponent_Conditional_0_Conditional_5_Case_5_Template, 6, 1)(6, IndicatorComponent_Conditional_0_Conditional_5_Case_6_Template, 6, 1)(7, IndicatorComponent_Conditional_0_Conditional_5_Case_7_Template, 6, 1)(8, IndicatorComponent_Conditional_0_Conditional_5_Case_8_Template, 6, 1);
    i0.ɵɵtext(9, "\n    ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(10, "\n  ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(11, "\n  ");
  }
  if (rf & 2) {
    let tmp_2_0;
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(5);
    i0.ɵɵconditional((tmp_2_0 = ctx_r0.type) === "error" ? 5 : tmp_2_0 === "success" ? 6 : tmp_2_0 === "warning" ? 7 : tmp_2_0 === "info" ? 8 : -1);
  }
}
function IndicatorComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n");
    i0.ɵɵelementStart(1, "div", 0);
    i0.ɵɵtext(2, "\n  ");
    i0.ɵɵelement(3, "hot-toast-loader", 1);
    i0.ɵɵtext(4, "\n  ");
    i0.ɵɵtemplate(5, IndicatorComponent_Conditional_0_Conditional_5_Template, 12, 1);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(6, "\n");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("theme", ctx_r0.theme);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(ctx_r0.type !== "loading" ? 5 : -1);
  }
}
const _c1 = ["*"];
const _c2 = ["hotToastBarBase"];
function HotToastComponent_Conditional_9_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n        ");
    i0.ɵɵelementStart(1, "hot-toast-animated-icon", 7);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3, "\n        ");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconTheme", ctx_r1.toast.iconTheme);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.toast.icon);
  }
}
function HotToastComponent_Conditional_9_Conditional_2_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function HotToastComponent_Conditional_9_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n        ");
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵtext(2, "\n          ");
    i0.ɵɵtemplate(3, HotToastComponent_Conditional_9_Conditional_2_ng_container_3_Template, 1, 0, "ng-container", 8);
    i0.ɵɵtext(4, "\n        ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, "\n        ");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dynamicView", ctx_r1.toast.icon);
  }
}
function HotToastComponent_Conditional_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, " ");
    i0.ɵɵtemplate(1, HotToastComponent_Conditional_9_Conditional_1_Template, 4, 2)(2, HotToastComponent_Conditional_9_Conditional_2_Template, 6, 1);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.isIconString ? 1 : 2);
  }
}
function HotToastComponent_Conditional_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n        ");
    i0.ɵɵelement(1, "hot-toast-indicator", 9);
    i0.ɵɵtext(2, "\n        ");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("theme", ctx_r1.toast.iconTheme)("type", ctx_r1.toast.type);
  }
}
function HotToastComponent_ng_container_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function HotToastComponent_Conditional_20_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵtext(0, "\n      ");
    i0.ɵɵelementStart(1, "button", 10);
    i0.ɵɵlistener("click", function HotToastComponent_Conditional_20_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.close());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtext(2, "\n      ");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngStyle", ctx_r1.toast.closeStyle);
  }
}
function HotToastContainerComponent_For_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵtext(0, "\n      ");
    i0.ɵɵelementStart(1, "hot-toast", 2);
    i0.ɵɵlistener("showAllToasts", function HotToastContainerComponent_For_7_Template_hot_toast_showAllToasts_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.showAllToasts($event));
    })("height", function HotToastContainerComponent_For_7_Template_hot_toast_height_1_listener($event) {
      const toast_r3 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.updateHeight($event, toast_r3));
    })("beforeClosed", function HotToastContainerComponent_For_7_Template_hot_toast_beforeClosed_1_listener() {
      const toast_r3 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.beforeClosed(toast_r3));
    })("afterClosed", function HotToastContainerComponent_For_7_Template_hot_toast_afterClosed_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.afterClosed($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtext(2, "\n      ");
  }
  if (rf & 2) {
    const toast_r3 = ctx.$implicit;
    const ɵ$index_10_r4 = ctx.$index;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("toast", toast_r3)("offset", ctx_r1.calculateOffset(toast_r3.id, toast_r3.position))("toastRef", ctx_r1.toastRefs[ɵ$index_10_r4])("toastsAfter", (toast_r3.autoClose ? ctx_r1.toasts.length : ctx_r1.getVisibleToasts(toast_r3.position).length) - 1 - ɵ$index_10_r4)("defaultConfig", ctx_r1.defaultConfig)("isShowingAllToasts", ctx_r1.isShowingAllToasts);
  }
}
const HOT_TOAST_DEFAULT_TIMEOUTS = {
  blank: 4000,
  error: 4000,
  success: 4000,
  loading: 30000,
  warning: 4000,
  info: 4000
};
const EXIT_ANIMATION_DURATION = 800;
const ENTER_ANIMATION_DURATION = 350;
const HOT_TOAST_MARGIN = 8;
const HOT_TOAST_DEPTH_SCALE = 0.05;
const HOT_TOAST_DEPTH_SCALE_ADD = 1;
class HotToastRef {
  constructor(toast) {
    this.toast = toast;
    /** Subject for notifying the user that the toast has been closed. */
    this._onClosed = new Subject();
  }
  set data(data) {
    this.toast.data = data;
  }
  get data() {
    return this.toast.data;
  }
  set dispose(value) {
    this._dispose = value;
  }
  getToast() {
    return this.toast;
  }
  /**Used for internal purpose
   * Attach ToastRef to container
   */
  appendTo(container) {
    const {
      dispose,
      updateMessage,
      updateToast,
      afterClosed
    } = container.addToast(this);
    this.dispose = dispose;
    this.updateMessage = updateMessage;
    this.updateToast = updateToast;
    this.afterClosed = race(this._onClosed.asObservable(), afterClosed);
    return this;
  }
  /**
   * Closes the toast
   *
   * @param [closeData={ dismissedByAction: false }] -
   * Make sure to pass { dismissedByAction: true } when closing from template
   * @memberof HotToastRef
   */
  close(closeData = {
    dismissedByAction: false
  }) {
    this._dispose();
    this._onClosed.next({
      dismissedByAction: closeData.dismissedByAction,
      id: this.toast.id
    });
    this._onClosed.complete();
  }
}
const animate = (element, value) => {
  element.style.animation = value;
};
class LoaderComponent {
  static {
    this.ɵfac = function LoaderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LoaderComponent,
      selectors: [["hot-toast-loader"]],
      inputs: {
        theme: "theme"
      },
      decls: 2,
      vars: 4,
      consts: [[1, "hot-toast-loader-icon", 3, "ngStyle"]],
      template: function LoaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵtext(1, "\n");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(1, _c0, ctx.theme == null ? null : ctx.theme.primary, ctx.theme == null ? null : ctx.theme.secondary));
        }
      },
      dependencies: [CommonModule, i1.NgStyle],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoaderComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-loader',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule],
      template: "<div\n  class=\"hot-toast-loader-icon\"\n  [ngStyle]=\"{ 'border-color': theme?.primary, 'border-right-color': theme?.secondary }\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class ErrorComponent {
  static {
    this.ɵfac = function ErrorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ErrorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ErrorComponent,
      selectors: [["hot-toast-error"]],
      inputs: {
        theme: "theme"
      },
      decls: 2,
      vars: 4,
      consts: [[1, "hot-toast-error-icon"]],
      template: function ErrorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵtext(1, "\n");
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("--error-primary", ctx.theme == null ? null : ctx.theme.primary)("--error-secondary", ctx.theme == null ? null : ctx.theme.secondary);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ErrorComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-error',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      template: "<div\n  class=\"hot-toast-error-icon\"\n  [style.--error-primary]=\"theme?.primary\"\n  [style.--error-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class CheckMarkComponent {
  static {
    this.ɵfac = function CheckMarkComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CheckMarkComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CheckMarkComponent,
      selectors: [["hot-toast-checkmark"]],
      inputs: {
        theme: "theme"
      },
      decls: 2,
      vars: 4,
      consts: [[1, "hot-toast-checkmark-icon"]],
      template: function CheckMarkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵtext(1, "\n");
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("--check-primary", ctx.theme == null ? null : ctx.theme.primary)("--check-secondary", ctx.theme == null ? null : ctx.theme.secondary);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckMarkComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-checkmark',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      template: "<div\n  class=\"hot-toast-checkmark-icon\"\n  [style.--check-primary]=\"theme?.primary\"\n  [style.--check-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class WarningComponent {
  static {
    this.ɵfac = function WarningComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WarningComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WarningComponent,
      selectors: [["hot-toast-warning"]],
      inputs: {
        theme: "theme"
      },
      decls: 2,
      vars: 4,
      consts: [[1, "hot-toast-warning-icon"]],
      template: function WarningComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵtext(1, "\n");
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("--warn-primary", ctx.theme == null ? null : ctx.theme.primary)("--warn-secondary", ctx.theme == null ? null : ctx.theme.secondary);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WarningComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-warning',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      template: "<div\n  class=\"hot-toast-warning-icon\"\n  [style.--warn-primary]=\"theme?.primary\"\n  [style.--warn-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class InfoComponent {
  static {
    this.ɵfac = function InfoComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || InfoComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: InfoComponent,
      selectors: [["hot-toast-info"]],
      inputs: {
        theme: "theme"
      },
      decls: 2,
      vars: 4,
      consts: [[1, "hot-toast-info-icon"]],
      template: function InfoComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵtext(1, "\n");
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("--warn-primary", ctx.theme == null ? null : ctx.theme.primary)("--warn-secondary", ctx.theme == null ? null : ctx.theme.secondary);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InfoComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      template: "<div\n  class=\"hot-toast-info-icon\"\n  [style.--warn-primary]=\"theme?.primary\"\n  [style.--warn-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class IndicatorComponent {
  static {
    this.ɵfac = function IndicatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IndicatorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: IndicatorComponent,
      selectors: [["hot-toast-indicator"]],
      inputs: {
        theme: "theme",
        type: "type"
      },
      decls: 1,
      vars: 1,
      consts: [[1, "hot-toast-indicator-wrapper"], [3, "theme"], [1, "hot-toast-status-wrapper"]],
      template: function IndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, IndicatorComponent_Conditional_0_Template, 7, 2);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.type !== "blank" ? 0 : -1);
        }
      },
      dependencies: [LoaderComponent, ErrorComponent, CheckMarkComponent, WarningComponent, InfoComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [LoaderComponent, ErrorComponent, CheckMarkComponent, WarningComponent, InfoComponent],
      template: "@if (type !== 'blank') {\n<div class=\"hot-toast-indicator-wrapper\">\n  <hot-toast-loader [theme]=\"theme\"></hot-toast-loader>\n  @if (type !== 'loading') {\n  <div class=\"hot-toast-status-wrapper\">\n    <div>\n      @switch (type) { @case ('error') {\n      <div>\n        <hot-toast-error [theme]=\"theme\"></hot-toast-error>\n      </div>\n      } @case ('success') {\n      <div>\n        <hot-toast-checkmark [theme]=\"theme\"></hot-toast-checkmark>\n      </div>\n      } @case ('warning') {\n      <div>\n        <hot-toast-warning [theme]=\"theme\"></hot-toast-warning>\n      </div>\n      } @case ('info') {\n      <div>\n        <hot-toast-info [theme]=\"theme\"></hot-toast-info>\n      </div>\n      } }\n    </div>\n  </div>\n  }\n</div>\n}\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }],
    type: [{
      type: Input
    }]
  });
})();
class AnimatedIconComponent {
  static {
    this.ɵfac = function AnimatedIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AnimatedIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AnimatedIconComponent,
      selectors: [["hot-toast-animated-icon"]],
      inputs: {
        iconTheme: "iconTheme"
      },
      ngContentSelectors: _c1,
      decls: 5,
      vars: 2,
      consts: [[1, "hot-toast-animated-icon"]],
      template: function AnimatedIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1, "\n  ");
          i0.ɵɵprojection(2);
          i0.ɵɵtext(3, "\n");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(4, "\n");
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("color", ctx.iconTheme == null ? null : ctx.iconTheme.primary);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimatedIconComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-animated-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      template: "<div class=\"hot-toast-animated-icon\" [style.color]=\"iconTheme?.primary\">\n  <ng-content></ng-content>\n</div>\n"
    }]
  }], null, {
    iconTheme: [{
      type: Input
    }]
  });
})();
class HotToastComponent {
  get toastsAfter() {
    return this._toastsAfter;
  }
  set toastsAfter(value) {
    this._toastsAfter = value;
    if (this.defaultConfig?.visibleToasts > 0) {
      if (this.toast.autoClose) {
        // if (value >= this.defaultConfig?.visibleToasts) {
        //   this.close();
        // }
      } else {
        if (value >= this.defaultConfig?.visibleToasts) {
          this.softClose();
        } else if (this.softClosed) {
          this.softOpen();
        }
      }
    }
  }
  constructor(injector, renderer, ngZone) {
    this.injector = injector;
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.offset = 0;
    this._toastsAfter = 0;
    this.isShowingAllToasts = false;
    this.height = new EventEmitter();
    this.beforeClosed = new EventEmitter();
    this.afterClosed = new EventEmitter();
    this.showAllToasts = new EventEmitter();
    this.isManualClose = false;
    this.unlisteners = [];
    this.softClosed = false;
  }
  get toastBarBaseHeight() {
    return this.toastBarBase.nativeElement.offsetHeight;
  }
  get scale() {
    return this.defaultConfig.stacking !== 'vertical' && !this.isShowingAllToasts ? this.toastsAfter * -HOT_TOAST_DEPTH_SCALE + 1 : 1;
  }
  get translateY() {
    return this.offset * (this.top ? 1 : -1) + 'px';
  }
  get exitAnimationDelay() {
    return this.toast.duration + 'ms';
  }
  get top() {
    return this.toast.position.includes('top');
  }
  get containerPositionStyle() {
    const verticalStyle = this.top ? {
      top: 0
    } : {
      bottom: 0
    };
    const transform = `translateY(var(--hot-toast-translate-y)) scale(var(--hot-toast-scale))`;
    const horizontalStyle = this.toast.position.includes('left') ? {
      left: 0
    } : this.toast.position.includes('right') ? {
      right: 0
    } : {
      left: 0,
      right: 0,
      justifyContent: 'center'
    };
    return {
      transform,
      ...verticalStyle,
      ...horizontalStyle
    };
  }
  get toastBarBaseStyles() {
    const enterAnimation = `hotToastEnterAnimation${this.top ? 'Negative' : 'Positive'} ${ENTER_ANIMATION_DURATION}ms cubic-bezier(0.21, 1.02, 0.73, 1) forwards`;
    const exitAnimation = `hotToastExitAnimation${this.top ? 'Negative' : 'Positive'} ${EXIT_ANIMATION_DURATION}ms forwards cubic-bezier(0.06, 0.71, 0.55, 1) var(--hot-toast-exit-animation-delay) var(--hot-toast-exit-animation-state)`;
    const animation = this.toast.autoClose ? `${enterAnimation}, ${exitAnimation}` : enterAnimation;
    return {
      ...this.toast.style,
      animation
    };
  }
  get isIconString() {
    return typeof this.toast.icon === 'string';
  }
  ngOnChanges(changes) {
    if (changes.toast && !changes.toast.firstChange && changes.toast.currentValue?.message) {
      requestAnimationFrame(() => {
        this.height.emit(this.toastBarBase.nativeElement.offsetHeight);
      });
    }
  }
  ngOnInit() {
    if (isTemplateRef(this.toast.message)) {
      this.context = {
        $implicit: this.toastRef
      };
    }
    if (isComponent(this.toast.message)) {
      this.toastComponentInjector = Injector.create({
        providers: [{
          provide: HotToastRef,
          useValue: this.toastRef
        }],
        parent: this.toast.injector || this.injector
      });
    }
  }
  ngAfterViewInit() {
    const nativeElement = this.toastBarBase.nativeElement;
    // Caretaker note: accessing `offsetHeight` triggers the whole layout update.
    // Macro tasks (like `setTimeout`) might be executed within the current rendering frame and cause a frame drop.
    requestAnimationFrame(() => {
      this.height.emit(nativeElement.offsetHeight);
    });
    // Caretaker note: `animationstart` and `animationend` events are event tasks that trigger change detection.
    // We'd want to trigger the change detection only if it's an exit animation.
    this.ngZone.runOutsideAngular(() => {
      this.unlisteners.push(
      // Caretaker note: we have to remove these event listeners at the end (even if the element is removed from DOM).
      // zone.js stores its `ZoneTask`s within the `nativeElement[Zone.__symbol__('animationstart') + 'false']` property
      // with callback that capture `this`.
      this.renderer.listen(nativeElement, 'animationstart', event => {
        if (this.isExitAnimation(event)) {
          this.ngZone.run(() => this.beforeClosed.emit());
        }
      }), this.renderer.listen(nativeElement, 'animationend', event => {
        if (this.isExitAnimation(event)) {
          this.ngZone.run(() => this.afterClosed.emit({
            dismissedByAction: this.isManualClose,
            id: this.toast.id
          }));
        }
      }));
    });
    this.setToastAttributes();
  }
  softClose() {
    const exitAnimation = `hotToastExitSoftAnimation${this.top ? 'Negative' : 'Positive'} ${EXIT_ANIMATION_DURATION}ms forwards cubic-bezier(0.06, 0.71, 0.55, 1)`;
    const nativeElement = this.toastBarBase.nativeElement;
    animate(nativeElement, exitAnimation);
    this.softClosed = true;
  }
  softOpen() {
    const softEnterAnimation = `hotToastEnterSoftAnimation${top ? 'Negative' : 'Positive'} ${ENTER_ANIMATION_DURATION}ms cubic-bezier(0.21, 1.02, 0.73, 1) forwards`;
    const nativeElement = this.toastBarBase.nativeElement;
    animate(nativeElement, softEnterAnimation);
    this.softClosed = false;
  }
  close() {
    this.isManualClose = true;
    const exitAnimation = `hotToastExitAnimation${this.top ? 'Negative' : 'Positive'} ${EXIT_ANIMATION_DURATION}ms forwards cubic-bezier(0.06, 0.71, 0.55, 1)`;
    const nativeElement = this.toastBarBase.nativeElement;
    animate(nativeElement, exitAnimation);
  }
  handleMouseEnter() {
    this.showAllToasts.emit(true);
  }
  handleMouseLeave() {
    this.showAllToasts.emit(false);
  }
  ngOnDestroy() {
    this.close();
    while (this.unlisteners.length) {
      this.unlisteners.pop()();
    }
  }
  isExitAnimation(ev) {
    return ev.animationName.includes('hotToastExitAnimation');
  }
  setToastAttributes() {
    const toastAttributes = this.toast.attributes;
    for (const [key, value] of Object.entries(toastAttributes)) {
      this.renderer.setAttribute(this.toastBarBase.nativeElement, key, value);
    }
  }
  static {
    this.ɵfac = function HotToastComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HotToastComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HotToastComponent,
      selectors: [["hot-toast"]],
      viewQuery: function HotToastComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c2, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.toastBarBase = _t.first);
        }
      },
      inputs: {
        toast: "toast",
        offset: "offset",
        defaultConfig: "defaultConfig",
        toastRef: "toastRef",
        toastsAfter: "toastsAfter",
        isShowingAllToasts: "isShowingAllToasts"
      },
      outputs: {
        height: "height",
        beforeClosed: "beforeClosed",
        afterClosed: "afterClosed",
        showAllToasts: "showAllToasts"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 24,
      vars: 21,
      consts: [["hotToastBarBase", ""], [1, "hot-toast-bar-base-container", 3, "ngStyle", "ngClass"], [1, "hot-toast-bar-base-wrapper", 3, "mouseenter", "mouseleave"], [1, "hot-toast-bar-base", 3, "ngStyle", "ngClass"], ["aria-hidden", "true", 1, "hot-toast-icon"], [1, "hot-toast-message"], [4, "dynamicView", "dynamicViewContext", "dynamicViewInjector"], [3, "iconTheme"], [4, "dynamicView"], [3, "theme", "type"], ["type", "button", "aria-label", "Close", 1, "hot-toast-close-btn", 3, "click", "ngStyle"]],
      template: function HotToastComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵtext(1, "\n  ");
          i0.ɵɵelementStart(2, "div", 2);
          i0.ɵɵlistener("mouseenter", function HotToastComponent_Template_div_mouseenter_2_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.handleMouseEnter());
          })("mouseleave", function HotToastComponent_Template_div_mouseleave_2_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.handleMouseLeave());
          });
          i0.ɵɵtext(3, "\n    ");
          i0.ɵɵelementStart(4, "div", 3, 0);
          i0.ɵɵtext(6, "\n      ");
          i0.ɵɵelementStart(7, "div", 4);
          i0.ɵɵtext(8, "\n        ");
          i0.ɵɵtemplate(9, HotToastComponent_Conditional_9_Template, 3, 1)(10, HotToastComponent_Conditional_10_Template, 3, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtext(11, "\n\n      ");
          i0.ɵɵelementStart(12, "div", 5);
          i0.ɵɵtext(13, "\n        ");
          i0.ɵɵelementStart(14, "div");
          i0.ɵɵtext(15, "\n          ");
          i0.ɵɵtemplate(16, HotToastComponent_ng_container_16_Template, 1, 0, "ng-container", 6);
          i0.ɵɵtext(17, "\n        ");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(18, "\n      ");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(19, "\n\n      ");
          i0.ɵɵtemplate(20, HotToastComponent_Conditional_20_Template, 3, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵtext(21, "\n  ");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(22, "\n");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(23, "\n");
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("--hot-toast-scale", ctx.scale)("--hot-toast-translate-y", ctx.translateY);
          i0.ɵɵproperty("ngStyle", ctx.containerPositionStyle)("ngClass", "hot-toast-theme-" + ctx.toast.theme);
          i0.ɵɵadvance(4);
          i0.ɵɵstyleProp("--hot-toast-animation-state", ctx.isManualClose ? "running" : "paused")("--hot-toast-exit-animation-state", ctx.isShowingAllToasts ? "paused" : "running")("--hot-toast-exit-animation-delay", ctx.exitAnimationDelay);
          i0.ɵɵproperty("ngStyle", ctx.toastBarBaseStyles)("ngClass", ctx.toast.className);
          i0.ɵɵattribute("aria-live", ctx.toast.ariaLive)("role", ctx.toast.role);
          i0.ɵɵadvance(5);
          i0.ɵɵconditional(ctx.toast.icon !== undefined ? 9 : 10);
          i0.ɵɵadvance(7);
          i0.ɵɵproperty("dynamicView", ctx.toast.message)("dynamicViewContext", ctx.context)("dynamicViewInjector", ctx.toastComponentInjector);
          i0.ɵɵadvance(4);
          i0.ɵɵconditional(ctx.toast.dismissible ? 20 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgStyle, DynamicViewDirective, IndicatorComponent, AnimatedIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HotToastComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule, DynamicViewDirective, IndicatorComponent, AnimatedIconComponent],
      template: "<div\n  class=\"hot-toast-bar-base-container\"\n  [ngStyle]=\"containerPositionStyle\"\n  [ngClass]=\"'hot-toast-theme-' + toast.theme\"\n  [style.--hot-toast-scale]=\"scale\"\n  [style.--hot-toast-translate-y]=\"translateY\"\n>\n  <div class=\"hot-toast-bar-base-wrapper\" (mouseenter)=\"handleMouseEnter()\" (mouseleave)=\"handleMouseLeave()\">\n    <div\n      class=\"hot-toast-bar-base\"\n      #hotToastBarBase\n      [ngStyle]=\"toastBarBaseStyles\"\n      [ngClass]=\"toast.className\"\n      [style.--hot-toast-animation-state]=\"isManualClose ? 'running' : 'paused'\"\n      [style.--hot-toast-exit-animation-state]=\"isShowingAllToasts ? 'paused' : 'running'\"\n      [style.--hot-toast-exit-animation-delay]=\"exitAnimationDelay\"\n      [attr.aria-live]=\"toast.ariaLive\"\n      [attr.role]=\"toast.role\"\n    >\n      <div class=\"hot-toast-icon\" aria-hidden=\"true\">\n        @if (toast.icon !== undefined) { @if (isIconString) {\n        <hot-toast-animated-icon [iconTheme]=\"toast.iconTheme\">{{ toast.icon }}</hot-toast-animated-icon>\n        } @else {\n        <div>\n          <ng-container *dynamicView=\"toast.icon\"></ng-container>\n        </div>\n        } } @else {\n        <hot-toast-indicator [theme]=\"toast.iconTheme\" [type]=\"toast.type\"></hot-toast-indicator>\n        }\n      </div>\n\n      <div class=\"hot-toast-message\">\n        <div>\n          <ng-container *dynamicView=\"toast.message; context: context; injector: toastComponentInjector\"></ng-container>\n        </div>\n      </div>\n\n      @if (toast.dismissible) {\n      <button\n        (click)=\"close()\"\n        type=\"button\"\n        class=\"hot-toast-close-btn\"\n        aria-label=\"Close\"\n        [ngStyle]=\"toast.closeStyle\"\n      ></button>\n      }\n    </div>\n  </div>\n</div>\n"
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i0.Renderer2
  }, {
    type: i0.NgZone
  }], {
    toast: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    defaultConfig: [{
      type: Input
    }],
    toastRef: [{
      type: Input
    }],
    toastsAfter: [{
      type: Input
    }],
    isShowingAllToasts: [{
      type: Input
    }],
    height: [{
      type: Output
    }],
    beforeClosed: [{
      type: Output
    }],
    afterClosed: [{
      type: Output
    }],
    showAllToasts: [{
      type: Output
    }],
    toastBarBase: [{
      type: ViewChild,
      args: ['hotToastBarBase']
    }]
  });
})();
class HotToastContainerComponent {
  constructor(cdr) {
    this.cdr = cdr;
    this.toasts = [];
    this.toastRefs = [];
    this.isShowingAllToasts = false;
    /** Subject for notifying the user that the toast has been closed. */
    this._onClosed = new Subject();
    this.onClosed$ = this._onClosed.asObservable();
  }
  trackById(index, toast) {
    return toast.id;
  }
  getVisibleToasts(position) {
    return this.toasts.filter(t => t.visible && t.position === position);
  }
  calculateOffset(toastId, position) {
    const visibleToasts = this.getVisibleToasts(position);
    const index = visibleToasts.findIndex(toast => toast.id === toastId);
    const offset = index !== -1 ? visibleToasts.slice(...(this.defaultConfig.reverseOrder ? [index + 1] : [0, index])).reduce((acc, t, i) => {
      const toastsAfter = visibleToasts.length - 1 - i;
      return this.defaultConfig.visibleToasts !== 0 && i < visibleToasts.length - this.defaultConfig.visibleToasts ? 0 : acc + (this.defaultConfig.stacking === 'vertical' || this.isShowingAllToasts ? t.height || 0 : toastsAfter * HOT_TOAST_DEPTH_SCALE + HOT_TOAST_DEPTH_SCALE_ADD) + HOT_TOAST_MARGIN;
    }, 0) : 0;
    return offset;
  }
  updateHeight(height, toast) {
    toast.height = height;
    this.cdr.detectChanges();
  }
  addToast(ref) {
    this.toastRefs.push(ref);
    const toast = ref.getToast();
    this.toasts.push(ref.getToast());
    if (this.defaultConfig.visibleToasts !== 0 && this.toasts.length > this.defaultConfig.visibleToasts) {
      const closeToasts = this.toasts.slice(0, this.toasts.length - this.defaultConfig.visibleToasts);
      closeToasts.forEach(t => {
        if (t.autoClose) {
          this.closeToast(t.id);
        }
      });
    }
    this.cdr.detectChanges();
    return {
      dispose: () => {
        this.closeToast(toast.id);
      },
      updateMessage: message => {
        toast.message = message;
        this.updateToasts(toast);
        this.cdr.detectChanges();
      },
      updateToast: options => {
        this.updateToasts(toast, options);
        this.cdr.detectChanges();
      },
      afterClosed: this.getAfterClosed(toast)
    };
  }
  closeToast(id) {
    if (id) {
      const comp = this.hotToastComponentList.find(item => item.toast.id === id);
      if (comp) {
        comp.close();
      }
    } else {
      this.hotToastComponentList.forEach(comp => comp.close());
    }
  }
  beforeClosed(toast) {
    toast.visible = false;
  }
  afterClosed(closeToast) {
    const toastIndex = this.toasts.findIndex(t => t.id === closeToast.id);
    if (toastIndex > -1) {
      this._onClosed.next(closeToast);
      this.toasts = this.toasts.filter(t => t.id !== closeToast.id);
      this.toastRefs = this.toastRefs.filter(t => t.getToast().id !== closeToast.id);
      this.cdr.detectChanges();
    }
  }
  hasToast(id) {
    return this.toasts.findIndex(t => t.id === id) > -1;
  }
  showAllToasts(show) {
    this.isShowingAllToasts = show;
  }
  getAfterClosed(toast) {
    return this.onClosed$.pipe(filter(v => v.id === toast.id));
  }
  updateToasts(toast, options) {
    this.toasts = this.toasts.map(t => ({
      ...t,
      ...(t.id === toast.id && {
        ...toast,
        ...options
      })
    }));
  }
  static {
    this.ɵfac = function HotToastContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HotToastContainerComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HotToastContainerComponent,
      selectors: [["hot-toast-container"]],
      viewQuery: function HotToastContainerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(HotToastComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hotToastComponentList = _t);
        }
      },
      inputs: {
        defaultConfig: "defaultConfig"
      },
      decls: 11,
      vars: 0,
      consts: [[2, "position", "fixed", "z-index", "9999", "top", "0", "right", "0", "bottom", "0", "left", "0", "pointer-events", "none"], [2, "position", "relative", "height", "100%"], [3, "showAllToasts", "height", "beforeClosed", "afterClosed", "toast", "offset", "toastRef", "toastsAfter", "defaultConfig", "isShowingAllToasts"]],
      template: function HotToastContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1, "\n  ");
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵtext(3, "\n    ");
          i0.ɵɵelementStart(4, "div");
          i0.ɵɵtext(5, "\n      ");
          i0.ɵɵrepeaterCreate(6, HotToastContainerComponent_For_7_Template, 3, 6, null, null, ctx.trackById, true);
          i0.ɵɵelementEnd();
          i0.ɵɵtext(8, "\n  ");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(9, "\n");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(10, "\n");
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵrepeater(ctx.toasts);
        }
      },
      dependencies: [HotToastComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HotToastContainerComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [HotToastComponent],
      template: "<div style=\"position: fixed; z-index: 9999; top: 0; right: 0; bottom: 0; left: 0; pointer-events: none\">\n  <div style=\"position: relative; height: 100%\">\n    <div>\n      @for (toast of toasts; track trackById(i, toast); let i = $index) {\n      <hot-toast\n        [toast]=\"toast\"\n        [offset]=\"calculateOffset(toast.id, toast.position)\"\n        [toastRef]=\"toastRefs[i]\"\n        [toastsAfter]=\"(toast.autoClose ? toasts.length : getVisibleToasts(toast.position).length) - 1 - i\"\n        [defaultConfig]=\"defaultConfig\"\n        [isShowingAllToasts]=\"isShowingAllToasts\"\n        (showAllToasts)=\"showAllToasts($event)\"\n        (height)=\"updateHeight($event, toast)\"\n        (beforeClosed)=\"beforeClosed(toast)\"\n        (afterClosed)=\"afterClosed($event)\"\n      ></hot-toast>\n      }\n    </div>\n  </div>\n</div>\n"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    defaultConfig: [{
      type: Input
    }],
    hotToastComponentList: [{
      type: ViewChildren,
      args: [HotToastComponent]
    }]
  });
})();
class ToastConfig {
  constructor() {
    /**
     * Sets the reverse order for hot-toast stacking
     *
     * @default false
     */
    this.reverseOrder = false;
    /**
     * Sets the number of toasts visible.
     * 0 will set no limit.
     * @default 5
     * @since 6.1.0
     */
    this.visibleToasts = 5;
    /**
     * Sets the type of stacking
     * @default "vertical"
     * @since 6.1.0
     */
    this.stacking = 'vertical';
    this.ariaLive = 'polite';
    this.role = 'status';
    this.position = 'top-center';
    this.autoClose = true;
    this.theme = 'toast';
    this.attributes = {};
    // key in ToastType
    this.info = {
      content: ''
    };
    this.success = {
      content: ''
    };
    this.error = {
      content: ''
    };
    this.loading = {
      content: ''
    };
    this.blank = {
      content: ''
    };
    this.warning = {
      content: ''
    };
  }
}
const isFunction = valOrFunction => typeof valOrFunction === 'function';
const isAngularComponent = arg => {
  return typeof arg === 'function' && arg.decorators && arg.decorators.some(decorator => decorator.type === Component);
};
const resolveValueOrFunction = (valOrFunction, arg) => isAngularComponent(valOrFunction) ? valOrFunction : isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction;
class ToastPersistConfig {
  constructor() {
    /**
     *In which storage id vs. counts should be stored
     *
     * @type {('local' | 'session')}
     * @memberof ToastPersistConfig
     * @default 'local'
     */
    this.storage = 'local';
    /**
     *The key pattern to store object in storage. `${id}` in pattern is replaced with actual toast id.
     *
     * @type {('local' | 'session')}
     * @memberof ToastPersistConfig
     * @default 'ngneat/hottoast-${id}'
     */
    this.key = 'ngneat/hototast-${id}';
    /**
     *The number of toasts allowed to show.
     *
     * @memberof ToastPersistConfig
     * @default 1
     */
    this.count = 1;
    this.enabled = false;
  }
}
class HotToastService {
  constructor(_viewService, platformId, config) {
    this._viewService = _viewService;
    this.platformId = platformId;
    this._isInitialized = false;
    this._defaultConfig = new ToastConfig();
    this._defaultPersistConfig = new ToastPersistConfig();
    if (config) {
      this._defaultConfig = {
        ...this._defaultConfig,
        ...config
      };
    }
  }
  get defaultConfig() {
    return this._defaultConfig;
  }
  set defaultConfig(config) {
    this._defaultConfig = {
      ...this._defaultConfig,
      ...config
    };
    if (this._componentRef) {
      this._componentRef.setInput('defaultConfig', this._defaultConfig);
    }
  }
  /**
   * Opens up an hot-toast without any pre-configurations
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  show(message, options) {
    const toast = this.createToast(message || this._defaultConfig.blank.content, 'blank', {
      ...this._defaultConfig,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for error state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  error(message, options) {
    const toast = this.createToast(message || this._defaultConfig.error.content, 'error', {
      ...this._defaultConfig,
      ...this._defaultConfig?.error,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for success state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  success(message, options) {
    const toast = this.createToast(message || this._defaultConfig.success.content, 'success', {
      ...this._defaultConfig,
      ...this._defaultConfig?.success,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for loading state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  loading(message, options) {
    const toast = this.createToast(message || this._defaultConfig.loading.content, 'loading', {
      ...this._defaultConfig,
      ...this._defaultConfig?.loading,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for warning state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  warning(message, options) {
    const toast = this.createToast(message || this._defaultConfig.warning.content, 'warning', {
      ...this._defaultConfig,
      ...this._defaultConfig?.warning,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for info state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   * @since 3.3.0
   */
  info(message, options) {
    const toast = this.createToast(message || this._defaultConfig.info.content, 'info', {
      ...this._defaultConfig,
      ...this._defaultConfig?.info,
      ...options
    });
    return toast;
  }
  /**
   *
   *  Opens up an hot-toast with pre-configurations for loading initially and then changes state based on messages
   *
   * @template T Type of observable
   * @param messages Messages for each state i.e. loading, success and error
   * @returns
   * @memberof HotToastService
   */
  observe(messages) {
    return source => {
      let toastRef;
      let start = 0;
      const loadingContent = messages.loading ?? this._defaultConfig.loading?.content;
      const successContent = messages.success ?? this._defaultConfig.success?.content;
      const errorContent = messages.error ?? this._defaultConfig.error?.content;
      return defer(() => {
        if (loadingContent) {
          toastRef = this.createLoadingToast(loadingContent);
          start = Date.now();
        }
        return source.pipe(tap({
          ...(successContent && {
            next: val => {
              toastRef = this.createOrUpdateToast(messages, val, toastRef, 'success', start === 0 ? start : Date.now() - start);
            }
          }),
          ...(errorContent && {
            error: e => {
              toastRef = this.createOrUpdateToast(messages, e, toastRef, 'error', start === 0 ? start : Date.now() - start);
            }
          })
        }));
      });
    };
  }
  /**
   * Closes the hot-toast
   *
   * @param [id] - ID of the toast
   * @since 3.0.1 - If ID is not provided, all toasts will be closed
   */
  close(id) {
    if (this._componentRef) {
      this._componentRef.ref.instance.closeToast(id);
    }
  }
  /**
   * Used for internal purpose only.
   * Creates a container component and attaches it to document.body.
   */
  init() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this._componentRef = this._viewService.createComponent(HotToastContainerComponent).setInput('defaultConfig', this._defaultConfig).appendTo(document.body);
  }
  createOrUpdateToast(messages, val, toastRef, type, diff) {
    try {
      let content = null;
      let options = {};
      ({
        content,
        options
      } = this.getContentAndOptions(type, messages[type] || (this._defaultConfig[type] ? this._defaultConfig[type].content : '')));
      content = resolveValueOrFunction(content, val);
      if (toastRef) {
        if (options.data) {
          toastRef.data = options.data;
        }
        toastRef.updateMessage(content);
        const updatedOptions = {
          type,
          duration: diff + HOT_TOAST_DEFAULT_TIMEOUTS[type],
          ...options,
          ...(options.duration && {
            duration: diff + options.duration
          })
        };
        toastRef.updateToast(updatedOptions);
      } else {
        this.createToast(content, type, options);
      }
      return toastRef;
    } catch (error) {
      console.error(error);
    }
  }
  createToast(message, type, options, observableMessages) {
    if (!this._isInitialized) {
      this._isInitialized = true;
      this.init();
    }
    const now = Date.now();
    const id = options?.id ?? now.toString();
    if (!this.isDuplicate(id) && (!options.persist?.enabled || options.persist?.enabled && this.handleStorageValue(id, options))) {
      const toast = {
        ariaLive: options?.ariaLive ?? 'polite',
        createdAt: now,
        duration: options?.duration ?? HOT_TOAST_DEFAULT_TIMEOUTS[type],
        id,
        message,
        role: options?.role ?? 'status',
        type,
        visible: true,
        observableMessages: observableMessages ?? undefined,
        ...options
      };
      return new HotToastRef(toast).appendTo(this._componentRef.ref.instance);
    }
  }
  /**
   * Checks whether any toast with same id is present.
   *
   * @private
   * @param id - Toast ID
   */
  isDuplicate(id) {
    return this._componentRef.ref.instance.hasToast(id);
  }
  /**
   * Creates an entry in local or session storage with count ${defaultConfig.persist.count}, if not present.
   * If present in storage, reduces the count
   * and returns the count.
   * Count can not be less than 0.
   */
  handleStorageValue(id, options) {
    let count = 1;
    const persist = {
      ...this._defaultPersistConfig,
      ...options.persist
    };
    const storage = persist.storage === 'local' ? localStorage : sessionStorage;
    const key = persist.key.replace(/\${id}/g, id);
    let item = storage.getItem(key);
    if (item) {
      item = parseInt(item, 10);
      if (item > 0) {
        count = item - 1;
      } else {
        count = item;
      }
    } else {
      count = persist.count;
    }
    storage.setItem(key, count.toString());
    return count;
  }
  getContentAndOptions(toastType, message) {
    let content;
    let options = {
      ...this._defaultConfig,
      ...this._defaultConfig[toastType]
    };
    // typeof message === 'object' won't work, cz TemplateRef's type is object
    if (typeof message === 'string' || isTemplateRef(message) || isComponent(message)) {
      content = message;
    } else {
      let restOptions;
      ({
        content,
        ...restOptions
      } = message);
      options = {
        ...options,
        ...restOptions
      };
    }
    return {
      content,
      options
    };
  }
  createLoadingToast(messages) {
    let content = null;
    let options = {};
    ({
      content,
      options
    } = this.getContentAndOptions('loading', messages));
    return this.loading(content, options);
  }
  static {
    this.ɵfac = function HotToastService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HotToastService)(i0.ɵɵinject(i1$1.ViewService), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(ToastConfig, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HotToastService,
      factory: HotToastService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HotToastService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.ViewService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: ToastConfig,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
function provideHotToastConfig(config) {
  return makeEnvironmentProviders([{
    provide: ToastConfig,
    useValue: config
  }]);
}

/*
 * Public API Surface of hot-toast
 */

/**
 * Generated bundle index. Do not edit.
 */

export { HotToastRef, HotToastService, ToastConfig, ToastPersistConfig, provideHotToastConfig, resolveValueOrFunction };
