import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UploadCollection } from './upload-collection.model';

export const UploadCollectionActions = createActionGroup({
    source: 'Folder/API',
    events: {
        'Load Upload Collections': emptyProps(),
        'Load Upload Collections Success': props<{uploadCollections: UploadCollection[]}>(),
        'Load Upload Collections Failure': props<{error: any}>(),

        'Create Upload Collection': props<{name: string}>(),
        'Create Upload Collection Success': props<{uploadCollection: UploadCollection}>(),
        'Create Upload Collection Failure': props<{error: any}>(),

        'Update Upload Collection': props<{id: string, name: string}>(),
        'Update Upload Collection Success': props<{uploadCollection: UploadCollection}>(),
        'Update Upload Collection Failure': props<{error: any}>(),

        'Delete Upload Collection': props<{id: string}>(),
        'Delete Upload Collection Success': props<{id: string}>(),
        'Delete Upload Collection Failure': props<{error: any}>(),
    },
});
