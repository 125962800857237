import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, inject, signal, viewChild } from '@angular/core';
import { FormArray, FormArrayName, FormControl } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TippyDirective } from '@ngneat/helipopper';
import { ButtonComponent } from '../../../shared/components/button/button.component';

@Component({
    selector: 'app-chat-input-file-upload',
    imports: [ButtonComponent, FaIconComponent, TippyDirective],
    template: `
        <app-button color="transparent" size="sm" tp="Add document" type="button" (click)="onClick()">
            <fa-icon
                class="text-slate-400 group-hover/button:text-yellow-500"
                [icon]="['far', 'paperclip-vertical']"></fa-icon>
        </app-button>

        <input (change)="onChange($event)" type="file" class="hidden" #fileInput accept="application/pdf" />
    `,
    styleUrl: './chat-input-file-upload.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputFileUploadComponent {
    disabled = signal(false);
    inputRef = viewChild('fileInput', { read: ElementRef<HTMLInputElement> });
    controlContainer = inject(FormArrayName);
    get control(): FormArray<FormControl<File>> {
        return this.controlContainer.control;
    }

    http = inject(HttpClient);

    onChange($event: Event) {
        const target = $event.target as HTMLInputElement;
        const file = target.files!.item(0)!;

        this.control.push(new FormControl(file, { nonNullable: true }));
    }

    onClick() {
        this.inputRef()?.nativeElement.click();
    }
}
