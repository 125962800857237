import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-modal-footer',
    template: `
        <div class="modal-footer p-4">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./modal-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ModalFooterComponent {}
