import { createSelector } from '@ngrx/store';
import { prop, sortBy } from 'remeda';
import { knowledgeSourcesFeature } from './knowledge-source.reducer';

export const {
    selectIds: selectKnowledgeSourceIds,
    selectEntities: selectKnowledgeSourceEntities,
    selectAll: selectKnowledgeSourceAll,
    selectTotal: selectKnowledgeSourceTotal,
    selectIsLoading: selectKnowledgeSourceIsLoading,
} = knowledgeSourcesFeature;

export const selectKnowledgeSourceSortedByCreatedAt = createSelector(selectKnowledgeSourceAll, knowledgeSources =>
    sortBy(knowledgeSources, [prop('createdAt'), 'desc']),
);
