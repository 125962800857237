import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Create } from '../../shared/utilities/create.type';
import { BrandVoice } from './brand-voice.model';
import { Update } from '../../shared/utilities/update.type';

export const BrandVoiceActions = createActionGroup({
    source: 'BrandVoice/API',
    events: {
        'Load BrandVoices': emptyProps(),
        'Load BrandVoices Success': props<{brandVoices: BrandVoice[]}>(),
        'Load BrandVoices Failure': props<{error: any}>(),

        'Load BrandVoice By Id': props<{id: string}>(),
        'Load BrandVoice By Id Success': props<{brandVoice: BrandVoice}>(),
        'Load BrandVoice By Id Failure': props<{error: any}>(),

        'Create BrandVoice': props<{brandVoice: Create<BrandVoice>}>(),
        'Create BrandVoice Success': props<{brandVoice: BrandVoice}>(),
        'Create BrandVoice Failure': props<{error: any}>(),

        'Update BrandVoice': props<{brandVoice: Update<BrandVoice>}>(),
        'Update BrandVoice Success': props<{brandVoice: BrandVoice}>(),
        'Update BrandVoice Failure': props<{error: any}>(),

        'Delete BrandVoice By Id': props<{id: string}>(),
        'Delete BrandVoice By Id Success': props<{id: string}>(),
        'Delete BrandVoice By Id Failure': props<{error: any}>(),
    },
});
