import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProjectActions } from '../../+store/project.actions';
import { ModalBodyComponent } from '../../../shared/modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../shared/modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../shared/modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';

@Component({
    selector: 'app-create-project-modal',
    template: `
        <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
            <app-modal>
                <app-modal-title>
                    <h3>Create project</h3>
                </app-modal-title>
                <app-modal-body>
                    <label for="name" class="form-label"> Name </label>
                    <input class="form-input px-2" type="text" id="name" formControlName="name" />
                </app-modal-body>
                <app-modal-footer>
                    <button type="submit" class="btn-light" [disabled]="!projectForm.valid">Submit</button>
                </app-modal-footer>
            </app-modal>
        </form>
    `,
    styleUrls: ['./create-project-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, ModalComponent, ModalTitleComponent, ModalBodyComponent, ModalFooterComponent],
})
export class CreateProjectModalComponent {
    private modalContainer = inject(ModalContentContainerComponent);
    private store = inject(Store);

    projectForm = new FormGroup({
        name: new FormControl('', { nonNullable: true }),
    });

    close() {
        this.modalContainer.onDismiss();
    }

    onSubmit() {
        this.store.dispatch(ProjectActions.addProject({ project: this.projectForm.getRawValue() }));
        this.modalContainer.onDismiss();
    }
}
