import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { TenantEffects } from './+store/tenant.effects';


@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterOutlet,
        TippyDirective,
        EffectsModule.forFeature([TenantEffects]),
    ],
})
export class TenantModule {
}
