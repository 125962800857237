import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplateRoutingModule } from './template-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { TemplateEffects } from './store/template.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TemplateRoutingModule,
        EffectsModule.forFeature([TemplateEffects]),
    ]
})
export class TemplateModule {
}
