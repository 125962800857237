import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs';
import { selectRouteParam } from '../../../reducers';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { UploadCollectionActions } from '../../../upload/+store/folder/upload-collection.actions';
import { selectUploadCollectionAll } from '../../../upload/+store/folder/upload-collection.selectors';
import { CreateCollectionButtonComponent } from '../../components/create-collection-button/create-collection-button.component';
import { UploadCollectionListComponent } from '../../components/upload-collection-list/upload-collection-list.component';

@Component({
    selector: 'app-document-index',
    template: `
        <app-main-wrapper>
            <div class="w-full flex h-full">
                <div class="w-[350px] shadow-lg p-5">
                    <div class="flex items-center">
                        <div class="text-slate-950/40 text-xs uppercase tracking-wide font-bold">Collections</div>
                        <app-create-collection-button class="ml-auto"></app-create-collection-button>
                    </div>

                    <div class="mt-3 text-sm">
                        <app-upload-collection-list></app-upload-collection-list>
                    </div>
                </div>

                <div class="w-full">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </app-main-wrapper>
    `,
    styleUrls: ['./document-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MainWrapperComponent, CreateCollectionButtonComponent, UploadCollectionListComponent, RouterOutlet],
})
export class DocumentIndexComponent {
    route = inject(ActivatedRoute);
    cdr = inject(ChangeDetectorRef);
    store = inject(Store);
    router = inject(Router);

    constructor() {
        this.store.dispatch(UploadCollectionActions.loadUploadCollections());

        this.store
            .select(selectRouteParam('id'))
            .pipe(
                take(1),
                filter(id => id === undefined),
                switchMap(() => this.store.select(selectUploadCollectionAll)),
                filter(uploadCollections => uploadCollections.length > 0),
                takeUntilDestroyed(),
            )
            .subscribe(([uploadCollection]) => {
                this.router.navigate([uploadCollection.id], { relativeTo: this.route });
            });
    }
}
