import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { UploadCollectionActions } from './upload-collection.actions';
import { UploadCollection } from './upload-collection.model';

export const uploadCollectionsFeatureKey = 'uploadCollections';

export interface UploadCollectionState extends EntityState<UploadCollection> {
    loading: boolean;
}

export const adapter: EntityAdapter<UploadCollection> = createEntityAdapter<UploadCollection>(
);

export const initialState: UploadCollectionState = adapter.getInitialState({
    loading: false,
});

export const reducer = createReducer(
    initialState,
    on(
        UploadCollectionActions.loadUploadCollections,
        UploadCollectionActions.createUploadCollection, state => ({
            ...state,
            loading: true,
        })),
    on(UploadCollectionActions.loadUploadCollectionsSuccess,
        (state, action) => adapter.setAll(action.uploadCollections, { ...state, loading: false })),
    on(UploadCollectionActions.createUploadCollectionSuccess,
        (state, action) => adapter.upsertOne(action.uploadCollection, { ...state, loading: false })),
    on(UploadCollectionActions.updateUploadCollectionSuccess,
        (state, action) => adapter.upsertOne(action.uploadCollection, { ...state, loading: false})),
    on(UploadCollectionActions.deleteUploadCollection,
        (state, action) => adapter.removeOne(action.id, state),
    ),
);

export const uploadCollectionsFeature = createFeature({
    name: uploadCollectionsFeatureKey,
    reducer,
    extraSelectors: ({ selectUploadCollectionsState }) => ({
        ...adapter.getSelectors(selectUploadCollectionsState),
    }),
});
