import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import hljs from 'highlight.js';
import { Marked } from 'marked';
import { markedHighlight } from 'marked-highlight';
import { Chat } from '../../+store/chat.model';
import { UserAvatarComponent } from '../../../shared/components/user-avatar/user-avatar.component';

@Component({
    selector: 'app-chat-message',
    template: `
        <div class="relative text-stone-900 pr-6 flex" [class.justify-end]="isHuman">
            @if (isAI) {
                <div class="flex flex-col mt-6 w-full">
                    <div class="mb-2">
                        <fa-icon [icon]="['far', 'feather']" size="lg"></fa-icon>
                    </div>

                    <div class="" [innerHTML]="html"></div>

                    @if (message.knowledgeSources.length > 0) {
                        <div class="text-stone-900 font-bold text-sm mt-3">Sources</div>
                        <div class="flex gap-4 mt-3">
                            @for (source of message.knowledgeSources; track source.id) {
                                <div class="rounded-xl bg-stone-100 p-5 text-sm text-stone font-medium">
                                    {{ source.name }}
                                </div>
                            }
                        </div>
                    }
                </div>
            }

            @if (isHuman) {
                <div class="flex space-x-3 mt-10">
                    <div
                        class="rounded-xl bg-stone-100 p-3 max-w-[700px] w-3/4  grow"
                        [class.whitespace-pre-wrap]="isHuman"
                        [innerHTML]="html"></div>
                    <div class="w-[35px] grow-0">
                        <app-user-avatar [roundedFull]="true" [user]="message.user"></app-user-avatar>
                    </div>
                </div>
            }
        </div>
    `,
    styleUrls: ['./chat-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UserAvatarComponent, FaIconComponent],
})
export class ChatMessageComponent {
    @Input() message!: Chat['messages'][0];
    @Input() chatId!: string | any;

    get isAI() {
        return this.message.type === 'ai';
    }

    get isHuman() {
        return this.message.type === 'human';
    }

    get html() {
        if (this.isHuman) {
            return this.escape(this.message.text);
        }

        const marked = new Marked(
            {
                walkTokens: token => {
                    // if (token.type === 'codespan') {
                    //     token.text = hljs.highlightAuto(token.raw.replace(/^`|`$|$/g, ""), ['plaintext', 'html', 'markdown', 'python', 'javascript', 'typescript', 'php', 'mongodb', 'sql', 'java']).value
                    // }
                },
                renderer: {
                    codespan(text: string) {
                        text = text.replace(/\n$/, '').replace(/^`|`$|$/g, '');
                        return `<code class="hljs language-html text-sm rounded whitespace-normal">${text}</code>`;
                    },
                },
            },
            markedHighlight({
                langPrefix: 'hljs language-',
                highlight(code, lang) {
                    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
                    return hljs.highlight(code, { language, ignoreIllegals: true }).value;
                },
            }),
        );
        return marked.parse(this.message.text);
    }

    escape(s: string) {
        const lookup: { [k: string]: string } = {
            '&': '&amp;',
            '"': '&quot;',
            "'": '&apos;',
            '<': '&lt;',
            '>': '&gt;',
        };
        return s.replace(/[&"'<>]/g, c => lookup[c]);
    }
}
