import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../+store/auth.actions';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { AuthWrapperComponent } from '../../components/auth-wrapper/auth-wrapper.component';

@Component({
    selector: 'app-accept-invitation',
    template: `
        <app-auth-wrapper>
            <div class="w-full">
                <img src="/logo/logo.svg" width="150" alt="Logo" class="mb-8" />

                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="mb-3">
                        <label class="form-label" for="password"> Password </label>
                        <input
                            class="form-input"
                            id="password"
                            type="password"
                            placeholder="Password"
                            formControlName="password" />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="passwordRepeat"> Repeat Password </label>
                        <input
                            class="form-input"
                            id="passwordRepeat"
                            type="password"
                            placeholder="Repeat Password"
                            formControlName="passwordRepeat" />
                    </div>

                    @if (form.errors?.['repeatPasswords']) {
                        <div class="text-red-500 mt-3 text-xs italic">Your passwords are not equal.</div>
                    }

                    @if (
                        form.get('password')?.invalid && (form.get('password')?.dirty || form.get('password')?.touched)
                    ) {
                        <div>
                            @if (this.form.controls.password.errors?.['errors']?.['passwordTooWeak']) {
                                <div class="text-red-500 mt-3 text-xs italic">
                                    {{ this.form.controls.password.errors?.['errors']?.['passwordTooWeak'] }}
                                </div>
                            }
                            @if (this.form.controls.password.errors?.['errors']?.['passwordWeak']) {
                                <div class="text-red-500 mt-3 text-xs italic">
                                    {{ this.form.controls.password.errors?.['errors']?.['passwordWeak'] }}
                                </div>
                            }
                        </div>
                    }

                    <div class="mt-5">
                        <app-button type="submit" [fullWidth]="true"> Set Password </app-button>
                    </div>
                </form>
            </div>
        </app-auth-wrapper>
    `,
    styleUrls: ['./password-reset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AuthWrapperComponent, ReactiveFormsModule, ButtonComponent],
})
export class PasswordResetComponent {
    fb = inject(FormBuilder);
    form = this.fb.nonNullable.group(
        {
            password: ['', [Validators.required, validatePassword()]],
            passwordRepeat: ['', [Validators.required]],
        },
        {
            updateOn: 'submit',
            validators: [
                controls => {
                    const values = controls.getRawValue();
                    if (values.password && values.passwordRepeat && values.password !== values.passwordRepeat) {
                        return { repeatPasswords: true };
                    }

                    return null;
                },
            ],
        },
    );
    route = inject(ActivatedRoute);
    store = inject(Store);

    submit() {
        if (this.form.valid) {
            const token = this.route.snapshot.paramMap.get('token');
            if (this.form.valid && token) {
                const values = this.form.getRawValue();
                this.store.dispatch(AuthActions.acceptInvitation({ password: values.password, token }));
            }
        }
    }
}

export function validatePassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const passwordStrength = measurePasswordStrength(control.value);

        if (passwordStrength <= 1) {
            return {
                errors: {
                    passwordTooWeak:
                        'Your password is below the recommended strength. Ensure it consists of a minimum of 12 characters, encompassing lowercase letters, uppercase letters, digits, and special characters for enhanced security.',
                },
            };
        } else if (passwordStrength <= 2) {
            return {
                errors: {
                    passwordWeak:
                        "Your password is okay, but let's make it even safer! To enhance its security, try adding a mix of at least 12 characters, including lowercase letters, uppercase letters, digits, and special characters. This way, you'll have an extra layer of protection for your account!",
                },
            };
        } else {
            return null;
        }
    };
}

export function measurePasswordStrength(pass: string) {
    let score = 0;
    const variations: { [k: string]: boolean } = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    };

    const length = {
        length8: /.{8,}/.test(pass),
        length10: /.{10,}/.test(pass),
    };

    // check if length is min. 8 => middle (else "weak")
    if (length.length8) {
        score = 1;
    }

    if (length.length10) {
        let variationsCounter = 0;
        for (const check in variations) {
            variationsCounter += variations[check] ? 1 : 0;
        }

        // the PW contains at least 12 characters from at least three
        // of the categories: lower case letters, upper case letters, digits and special characters => strong
        if (variationsCounter === 3) {
            score = 2;
        }

        // the password contains at least 12 characters from all 4
        // categories: Lower case letters, upper case letters, digits and special characters.  => very strong
        if (variationsCounter === 4) {
            score = 3;
        }
    }

    return score;
}
