import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { selectAccessToken } from '../../../auth/+store/auth.selectors';
import { filterNullish } from '../../utilities/filter-nullish.operator';

@Injectable({
    providedIn: 'root',
})
export class SocketService extends Socket {
    store = inject(Store);

    constructor() {
        super({
            url: environment.apiUrl.startsWith('http') ? environment.apiUrl : '',
            options: {
                autoConnect: false,
                auth: cb => {
                    this.store.select(selectAccessToken).pipe(
                        take(1),
                    ).subscribe(token => cb({ token }));
                },
                path: environment.apiUrl.startsWith('http') ? `${new URL(environment.apiUrl).pathname}/socket.io`.replace('//', '/') : environment.apiUrl + '/socket.io',
                transports: ['websocket'],
            },
        });

        this.store.select(selectAccessToken).pipe(
            filterNullish(),
        ).subscribe(() => this.connect());
    }
}
