import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UploadCollectionEffects } from './+store/folder/upload-collection.effects';
import * as fromFolder from './+store/folder/upload-collection.reducer';
import { UploadEffects } from './+store/upload/upload.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([UploadEffects, UploadCollectionEffects]),
        StoreModule.forFeature(fromFolder.uploadCollectionsFeatureKey, fromFolder.reducer),
    ],
})
export class UploadModule {
}
