import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectWorkflowAll } from 'src/app/workflow/+store/workflow/workflow.selectors';
import { ChatActions } from '../../+store/chat.actions';
import { PromptLibrary } from '../../../prompt-library/+store/prompt-library.model';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { ChatInputFieldComponent } from '../../components/chat-input-field/chat-input-field.component';
import { ChatTopBarComponent } from '../../components/chat-top-bar/chat-top-bar.component';

@Component({
    selector: 'app-chat-create',
    imports: [ChatInputFieldComponent, FormsModule, MainWrapperComponent, ReactiveFormsModule, ChatTopBarComponent],
    template: `
        <app-main-wrapper>
            <div class="flex flex-col items-center justify-center h-full w-full">
                <form [formGroup]="form" class="p-5 w-full grow flex flex-col items-center">
                    <app-chat-top-bar class="max-w-[800px]"></app-chat-top-bar>

                    <div class="w-full max-w-[800px] my-auto -translate-y-12">
                        <app-chat-input-field
                            [initialText]="routerState?.promptTemplate"
                            (onSubmit)="submit($event)"></app-chat-input-field>
                    </div>
                </form>
            </div>
        </app-main-wrapper>
    `,
    styleUrl: './chat-create.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatCreateComponent {
    store = inject(Store);
    route = inject(ActivatedRoute);
    router = inject(Router);
    fb = inject(FormBuilder);
    state = signal<string>('');

    form!: FormGroup;

    workflows = this.store.selectSignal(selectWorkflowAll);
    routerState = this.router.lastSuccessfulNavigation?.extras.state as { promptTemplate: PromptLibrary } | undefined;

    ngOnInit() {
        this.form = this.fb.nonNullable.group({
            model: ['gpt-4o', Validators.required],
            settings: [{ temperature: 0.8, documentSearch: false }],
            private: [false],
            text: ['', Validators.required],
        });
    }

    submit(message: { text: string; knowledgeBase: boolean; uploadedFiles: File[] }) {
        const formValue = this.form.getRawValue();
        this.store.dispatch(
            ChatActions.createChat({
                text: message.text,
                model: formValue.model,
                settings: formValue.settings,
                knowledgeBase: message.knowledgeBase,
                private: formValue.private,
                uploadedFiles: message.uploadedFiles,
            }),
        );
    }
}
