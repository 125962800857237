import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { KnowledgeSource } from './knowledge-source.model';

export const KnowledgeSourceActions = createActionGroup({
    source: 'KnowledgeSource/API',
    events: {
        'Load KnowledgeSources': emptyProps(),
        'Load KnowledgeSources Success': props<{ knowledgeSources: KnowledgeSource[] }>(),
        'Load KnowledgeSources Failure': props<{ error: any }>(),

        'Delete KnowledgeSources': props<{ ids: string[] }>(),
        'Delete KnowledgeSource': props<{ id: string }>(),
        'Delete KnowledgeSource Success': props<{ id: string }>(),
        'Delete KnowledgeSource Failure': props<{ error: any }>(),

        'Add Website': props<{ url: string }>(),
        'Add Website Success': props<{ knowledgeSource: KnowledgeSource }>(),
        'Add Website Failure': props<{ error: any }>(),
    },
});
