import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Update } from '../shared/utilities/update.type';
import { Project } from './+store/project.model';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private http = inject(HttpClient);


    loadAll() {
        return this.http.get<Project[]>(`${environment.apiUrl}/project`);
    }

    addProject(value: Partial<{ name: string; }>) {
        return this.http.post<Project>(`${environment.apiUrl}/project`, value);
    }

    updateProject(value: Update<Project>) {
        return this.http.patch<Project>(`${environment.apiUrl}/project/${value.id}`, value);
    }

    deleteProject(id: string) {
        return this.http.delete<Project>(`${environment.apiUrl}/project/${id}`);
    }
}
