import { ChangeDetectionStrategy, Component, forwardRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { debounceTime, map } from 'rxjs/operators';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { PopoverDirective } from '../../../shared/popover/directives/popover.directive';
import { ControlsOf } from '../../../shared/types/controls-of.type';

interface ChatSettings {
    temperature: number;
    documentSearch: boolean;
}

type ChatSettingsForm = ControlsOf<ChatSettings>;

@Component({
    selector: 'app-chat-model-settings',
    template: `
        <app-button color="transparent" [appPopover]="tpl">
            <fa-icon [icon]="faSliders"></fa-icon>
        </app-button>

        <ng-template #tpl let-hide>
            <form [formGroup]="form" class="bg-white p-3">
                <div class="flex items-center">
                    <div class="mr-10">
                        <div class="font-semibold text-sm text-slate-800">Temperature</div>
                        <div class="text-sm text-slate-400">Must be between 0.1 and 1.4</div>
                    </div>
                    <div class="ml-auto">
                        <input
                            formControlName="temperature"
                            type="text"
                            class="form-input !py-1 !w-[40px] text-right" />
                    </div>
                </div>
            </form>
        </ng-template>
    `,
    styleUrls: ['./chat-model-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChatModelSettingsComponent),
            multi: true,
        },
    ],
    imports: [ButtonComponent, PopoverDirective, FaIconComponent, ReactiveFormsModule],
})
export class ChatModelSettingsComponent implements ControlValueAccessor {
    private fb = inject(FormBuilder);

    changeFn?: (value: ChatSettings) => void;
    faSliders = faSliders;
    form!: FormGroup<ChatSettingsForm>;
    touchFn?: () => void;

    constructor() {
        this.form = this.fb.nonNullable.group(
            {
                temperature: [0.8, { validators: [Validators.required, Validators.min(0.1), Validators.max(1.4)] }],
                documentSearch: [false],
            },
            { updateOn: 'change' },
        );

        this.form.valueChanges
            .pipe(
                debounceTime(1500),
                takeUntilDestroyed(),
                map(() => this.form.getRawValue()),
            )
            .subscribe(values => {
                if (this.changeFn && this.form.valid) {
                    this.changeFn({
                        temperature: +values.temperature,
                        documentSearch: values.documentSearch,
                    });
                }
            });
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {}

    writeValue(settings: ChatSettings): void {
        this.form.patchValue(settings, { emitEvent: false });
    }
}
