import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Create } from '../../shared/utilities/create.type';
import { Update } from '../../shared/utilities/update.type';

import { PromptLibrary } from './prompt-library.model';

export const PromptLibraryActions = createActionGroup({
    source: 'PromptLibrary/API',
    events: {
        'Load Prompt Library': emptyProps(),
        'Load Prompt Library Success': props<{ promptTemplates: PromptLibrary[] }>(),
        'Load Prompt Library Failure': props<{ error: any }>(),

        'Create Prompt Template': props<{ promptTemplate: Create<PromptLibrary> }>(),
        'Create Prompt Template Success': props<{ promptTemplate: PromptLibrary }>(),
        'Create Prompt Template Failure': props<{ error: any }>(),

        'Update Prompt Template': props<{ promptTemplate: Update<PromptLibrary> }>(),
        'Update Prompt Template Success': props<{ promptTemplate: PromptLibrary }>(),
        'Update Prompt Template Failure': props<{ error: any }>(),

        'Delete Prompt Template': props<{ id: string }>(),
        'Delete Prompt Template Success': props<{ id: string }>(),
        'Delete Prompt Template Failure': props<{ error: any }>(),

        'Clear PromptLibrarys': emptyProps(),
    },
});
