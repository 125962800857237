import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-auth-wrapper',
    template: `
        <div
            class="flex md:h-screen bg-gradient-to-b from-slate-50 to-slate-100 md:items-center justify-center p-5 overflow-auto">
            <div
                class="grid md:grid-cols-2 w-full max-w-[900px] lg:h-[550px] bg-white shadow-xl rounded-lg overflow-hidden">
                <div class="lg:overflow-auto">
                    <div class="py-12 px-16 flex justify-center lg:items-center h-full">
                        <ng-content></ng-content>
                    </div>
                </div>
                <div
                    class="bg-sky-50 bg-blend-multiply from-cyan-500 to-cyan-600 flex justify-center items-center bg-no-repeat bg-cover bg-center bg-[url('/images/login-bg.jpeg')] py-10">
                    <div
                        class="relative w-3/4 backdrop-blur-sm bg-sky-950/70 shadow-xl rounded-xl pb-10 pr-10 pl-10 md:-translate-y-6">
                        <div class="text-slate-50 font-bold text-2xl relative translate-y-10 drop-shadow">
                            <span class="font-bold">Whooo's</span> Ready to Work&nbsp;Smarter?
                        </div>
                        <div class="relative translate-x-16 translate-y-16">
                            <img class="rounded-lg" src="/images/login-owl.png" alt="" />
                        </div>

                        <div class="absolute w-px bg-sky-50/20 h-[75px] left-10 top-[130px]"></div>
                        <div class="absolute w-[20px] bg-sky-50/20 h-px left-11 bottom-10"></div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./auth-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class AuthWrapperComponent {}
