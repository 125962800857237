import { Directive, HostListener, input, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, throttle, timer } from 'rxjs';

@Directive({
    selector: '[appScrollEnd]',
    standalone: true,
})
export class ScrollEndDirective {
    throttleTime = input(1000);
    direction = input<'up' | 'down'>('down');
    threshold = input(5);

    lastScrollTop = signal(0);
    $scroll = new Subject<void>();

    appScrollEnd = output();

    constructor() {
        this.$scroll
            .pipe(
                throttle(() => timer(this.throttleTime())),
                takeUntilDestroyed(),
            )
            .subscribe(() => this.appScrollEnd.emit());
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: Event) {
        const target = event.target as HTMLElement;

        if (this.direction() === 'down' && this.lastScrollTop() < target.scrollTop) {
            if (target.scrollHeight - target.scrollTop <= target.clientHeight + this.threshold()) {
                this.$scroll.next();
            }
        }

        if (this.direction() === 'up' && this.lastScrollTop() > target.scrollTop) {
            if (target.scrollTop <= this.threshold()) {
                this.$scroll.next();
            }
        }

        this.lastScrollTop.set(target.scrollTop);
    }
}
